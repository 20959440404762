<template>
    <div class="md-layout">
        <div class="debtBlock" v-if="getCurrentRoundConfig.debts && getCurrentRoundConfig.debts.length > 0 && currentDebtsBorrowedElections">
            <div class="wrapper">
                <md-card v-for="(debtConfig, debtIndex) in getCurrentRoundConfig.debts" v-bind:key="debtConfig.id" :class="{ 'selected-border' : isDebtLocallyBorrowed(debtConfig.id) }">
                    <div class="mini-id">
                        Loan ID: #{{debtConfig.miniId}}
                    </div>
                    <md-card-header>
                        <div class="md-title">Loan Choice {{debtIndex + 1}}</div>
                    </md-card-header>
                    <md-card-content>
                        <div class="inputWrapper md-gutter" v-if="!debtsBorrowed.includes(debtConfig.id)">
                            <md-field>
                                <label>Borrowing Amount</label>
                                <currency-input :currency-to-format.sync="debtAmounts[debtIndex]">
                                </currency-input>
                            </md-field>
                        </div>
                        <div v-if="inputError && !debtsBorrowed.includes(debtConfig.id)" class="error">
                            {{inputError}}
                        </div>
                        <div v-if="debtsBorrowed.includes(debtConfig.id)" class="left-text">
                            <div class="election-config-label">Borrowing Amounta</div>
                            <div class="election-config-value">{{debtAmounts[debtIndex] | toCurrency}}</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Interest rate per round</div>
                            <div class="election-config-value">{{ (debtConfig.borrowingRate * 100).toFixed(2)}}%</div>
                        </div>
                        <div class="left-text">
                            <div class="election-config-label">Term</div>
                            <div class="election-config-value">{{debtConfig.borrowingTerm}} rounds</div>
                        </div>
                        <div>
                            <md-button v-if="!isDebtLocallyBorrowed(debtConfig.id) && parseFloat(debtAmounts[debtIndex]) > 0" @click="borrowDebt(debtConfig.id, debtIndex)" class="md-raised btn-primary">Borrow</md-button>
                            <md-button v-if="isDebtLocallyBorrowed(debtConfig.id)" @click="cancelDebtBorrow(debtConfig.id)" class="md-raised btn-warning">Cancel Borrowing This Debt</md-button>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {
    MdCard,
    MdField,
    MdIcon
} from 'vue-material/dist/components'
import { mapGetters } from 'vuex';
import CurrencyInput from './CurrencyInput';
export default {
    name: 'Debt',
    components: {
        'currency-input': CurrencyInput
    },
    data() {
        return {
            attrs: {
                currentDebtsBorrowedElections: this.debtsBorrowedElections,
                debtsBorrowed: [],
            },
            debtAmounts: [],
            inputError: ""
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentRoundConfig',
            'getCurrentRoundElections'
        ]),
        currentDebtsBorrowedElections: {
            get() {
                return this.attrs.currentDebtsBorrowedElections;
            },
            set(value) {
                this.attrs.currentDebtsBorrowedElections = value;
                this.$emit(`update:debts-borrowed-elections`, value);
            },
        },
        debtsBorrowed: {
            get() {
                return this.attrs.debtsBorrowed;
            },
            set(value) {
                this.attrs.debtsBorrowed = value;
            },
        },
    },
    watch: {
        debtsBorrowed(newVal, oldVal) {
            this.currentDebtsBorrowedElections = newVal.map((debtConfigId) => {
                const roundConfig = this.getCurrentRoundConfig;
                const debtIndex = roundConfig.debts.map((debtConfig) => debtConfig.id)
                    .indexOf(debtConfigId);
                const debtConfig = roundConfig.debts.find((_debtConfig) => _debtConfig.id === debtConfigId);
                return {
                    debtConfigId,
                    borrowingAmount: this.debtAmounts[debtIndex],
                    rate: debtConfig.borrowingRate,
                    term: debtConfig.borrowingTerm,
                    friendlyId: debtConfig.miniId
                };
            });
        },
    },
    methods: {
        isDebtLocallyBorrowed(debtConfigId) {
            return this.debtsBorrowed.indexOf(debtConfigId) >= 0;
        },
        borrowDebt(debtConfigId, index) {
            const totalLoanAmount = this.currentDebtsBorrowedElections.reduce((result, debt) => {
                return result += debt.borrowingAmount;
            }, 0); 
            
            const newTotalLoanAmount = totalLoanAmount + this.debtAmounts[index];

            if(newTotalLoanAmount + this.priorLoanAmount > this.totalBorrowCap) {
                this.inputError = "Your selection exceeds the maximum borrowing cap";
                return;
            }

            if(newTotalLoanAmount > this.loanCap) {
                this.inputError = "Your selection exceeds the borrowing cap for this round";
                return;
            }
            
            if(this.debtsBorrowed.indexOf(debtConfigId) === -1) {
                this.debtsBorrowed.push(debtConfigId);
                this.inputError = "";
            }
        },
        cancelDebtBorrow(debtConfigId) {
            const debtIndex = this.debtsBorrowed.indexOf(debtConfigId);

            if(debtIndex >= 0) {
                this.debtsBorrowed.splice(debtIndex, 1);
            }
            this.inputError = "";
        },
        initDebtsBorrowed(debts) {
            this.currentDebtsBorrowedElections = debts;
            if(!this.currentDebtsBorrowedElections) {
                this.currentDebtsBorrowedElections = [];
            }

            const roundConfig = this.getCurrentRoundConfig;
            for(let debtBorrowedElection of this.currentDebtsBorrowedElections){
                this.debtsBorrowed.push(debtBorrowedElection.debtConfigId);
                const debtIndex = roundConfig.debts.map((debtConfig) => debtConfig.id)
                    .indexOf(debtBorrowedElection.debtConfigId);
                this.debtAmounts[debtIndex] = debtBorrowedElection.borrowingAmount;
            }
        }
    },
    props: {
        debtsBorrowedElections: {
            type: Array,
        },
        loanCap: {
            type: Number
        },
        totalBorrowCap: {
            type: Number
        },
        priorLoanAmount: {
            type: Number
        }
    },
    created() {
        const roundConfig = this.getCurrentRoundConfig;
        this.debtAmounts = [];
        for(let debtConfig of roundConfig.debts) {
            this.debtAmounts.push(0);
        }

        this.initDebtsBorrowed(this.debtsBorrowedElections);
    }
}

Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdIcon);
</script>

<style scoped>
    .md-layout-item {
        padding: 10px;
    }

    .wrapper {
        background-color: var(--main-box-color);
    }

    .md-layout-item {
        padding: 10px;
    }

    .debtBlock {
        margin-left: auto;
        margin-right: auto;
        min-width: 98%;
        max-width: 98%;
        padding: 0;
    }

    .md-card {
        width: 300px;
        margin: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .md-button {
        margin: 1.5em;
    }

    .left-text {
        text-align: left;
    }

    .election-config-label {
        display: inline-block;
    }

    .election-config-value {
        display: inline-block;
        font-weight: bold;
        margin-left: 15px;
    }

    .error {
        color: var(--warning-text-color);
    }

</style>
