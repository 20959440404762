<template>
    <div>
        <div class="team-data-table">
            <md-table style="overflow-x: hidden;" v-model="teamData" md-card>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell class="team-data-table-cell" md-label="Rank">{{ item.rank }}</md-table-cell>
                    <md-table-cell class="team-data-table-cell" :class="{ 'total' : item.teamName === 'Total' }" md-label="Team Name">{{ item.teamName }}</md-table-cell>
                    <md-table-cell class="team-data-table-cell" md-label="Offer Price">{{ item.offerPrice | toCurrency }}</md-table-cell>
                    <md-table-cell class="team-data-table-cell" md-label="Units Offered">{{ item.unitsOffered }}</md-table-cell>
                    <md-table-cell class="team-data-table-cell" :md-label="getCustomLabelValue('unitsAdvertised')">{{ item.unitsAdvertised }}</md-table-cell>
                    <md-table-cell class="team-data-table-cell" :class="{ 'double-underline total' : item.teamName === 'Total' }" md-label="Units Sold">{{ item.unitsSold }}</md-table-cell>
                    <md-table-cell class="team-data-table-cell sales-cell" :class="{ 'double-underline total' : item.teamName === 'Total' }" md-label="Sales">{{ item.sales | toCurrency }}</md-table-cell>
                </md-table-row>
            </md-table>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { MdTable } from 'vue-material/dist/components'
import { MdRipple } from 'vue-material/dist/components'
import { mapGetters } from 'vuex'
Vue.use(MdTable);
Vue.use(MdRipple);

export default {
    name: 'TeamDataTable',
    props: {
        teamData: {
            type: Array
        }
    },
    computed: {
        ...mapGetters([
            'getGameLabels'
        ])
    },
    methods: {
        getCustomLabelValue(key) {
            if(this.getGameLabels) {
                const found = this.getGameLabels.find(label => label.key === key);
                return found ? found.value : "";
            }
            return "";
        }
    }
}
</script>

<style scoped>
.team-data-table-cell {
    text-align: left;
}
.sales-cell {
    font-weight: bold;
}
.double-underline {
  text-decoration-line: underline;
  text-decoration-style: double;
}
.total {
    font-weight: bold;
}
</style>