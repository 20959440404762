import { DarkModeTracker } from "@/types/types";
import { Chart } from "chart.js"

const state: DarkModeTracker = {
  isDarkMode: localStorage.getItem("isDarkMode") === "true" ? true : false,
};

const getters = {
  isDarkMode: (state: { isDarkMode: boolean; }) => state.isDarkMode,
}

const actions = {
  toggleDarkMode({ commit }: { commit: any }) {
    commit("toggleDarkMode");
    if(state.isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    updateDarkChart();
  }
};

const mutations = {
  toggleDarkMode(state: { isDarkMode: boolean; }) {
    state.isDarkMode = !state.isDarkMode;
    localStorage.setItem("isDarkMode", state.isDarkMode.toString());
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};

export function updateDarkChart() {
  const config = state.isDarkMode ? {
    backgroundColor: '#000',
    borderColor: '#fff',
    color: '#fff'
  } : {
    backgroundColor: '#181825',
    borderColor: '#181825',
    color: '#000'
  } 

  for (const [k, v] of Object.entries(config)) {
    // @ts-ignore
    Chart.defaults[k] = v
  }

  Object.values(Chart.instances).forEach((chart: any) => {
    chart.update();
  });
}
