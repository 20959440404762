<template>
    <div v-if="adminUserToken" class="md-layout admin-logout-container">
        <div class="md-layout-item">
            admin is using account <span @click="adminLeaveGame()"><md-icon class="fa fa-times-circle link"></md-icon></span>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { UserTypeEnum } from "temporary-api-types/apiTypes";
export default {
    name: 'AdminUserLogout',
    data() {
        return {
            adminUserToken: null
        }
    },
    computed: {
        ...mapGetters([
            'getUserType'
        ]),
    },
    methods: {
        ...mapActions([
			'setAdminUserToken',
            'resetVuexState',
            'configureUserType',
            'configureUserEmail'
		]),
        async adminLeaveGame()  {
            await this.setAdminUserToken(null);
            await this.configureUserType(UserTypeEnum.Admin);
            await this.configureUserEmail(null);
            if(this.getUserType === UserTypeEnum.Admin) {
                this.$router.push({
                    name: 'AdminHome'
                });
            } else {
                localStorage.clear();
                sessionStorage.clear();
                this.resetVuexState();
                this.$router.push({ 
                    name: 'Login'
                });
            }
        }
    },
    async created() {
        this.adminUserToken = sessionStorage.getItem('accountonomics.adminUserToken');
    }
}
</script>

<style scoped>
.admin-logout-container {
    padding-top: 10px;
    padding-right: 2em;
    text-align: right;
}
</style>