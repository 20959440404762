export default {
    "PE": "Price to earnings ratio, used to gauge the value and expectations of a stock",
    "EPS": "Earnings Per Share",
    "Assets": "Eeverything a company owns (cash, property, intellectual property, etc)",
    "Liabilities": "The legal debts a company owes to third-party creditors",
    "Owners' Equity": "The proportion of total value of a company's assets that can be claimed by owners / shareholders",
    "Revenue": "Amount of USD brought in by business operations",
    "Expense": "A cost incurred during course of business",
    "Financing Activity": "Transactions related to borrowing / lending, such as issuing/repurchasing stock and short/long term loans and repayments",
    "Investing Activity": "The purchases and sales of physical assets and securities",
    "Operating Activity": "The functions directly related to providing goods / services to the market",
    "Debit": "A bookkeeping entry that recordeds an increase in assets/expenses or a decrease in liabilities/equity/revenue",
    "Credit": "A bookkeeping entry that records a decrease in assets or an increase in liabilities",
    "Permanent": "An endeavor that either does not cease or lasts more than 6mo in a 12mo period",
    "Temporary": "An endeavor that is arranged for a limited period of time based on business needs",
    "IPO": "Initial Public Offering, the first time a company sells its stock to the public",
    "Net Income": "The difference between a company's total revenue and total expenses",
    "D/E": "Debt to Equity ratio, used to gauge the financial health of a company",
    "ROE": "Return on Equity, used to gauge the profitability of a company",
    "ROA": "Return on Assets, used to gauge the profitability of a company",
    "Bond": "Bonds are a type of security you can sell as a way of raising money from investors (borrowing money)"
}