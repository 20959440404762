<template>
    <div class="md-layout">
        <div class="md-layout-item">
            <div class="form-container">
                <div v-if="licenseData.firstName" class="md-layout">
                    <div class="md-layout-item"></div>
                    <div class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                        <h3 class="label">First Name</h3>
                        <div>{{licenseData.firstName}}</div>
                    </div>
                    <div class="md-layout-item"></div>
                </div>
                <div v-if="licenseData.lastName" class="md-layout">
                    <div class="md-layout-item"></div>
                    <div class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                        <h3 class="label">Last Name</h3>
                        <div>{{licenseData.lastName}}</div>
                    </div>
                    <div class="md-layout-item"></div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item"></div>
                    <div v-if="nonEditableFields && nonEditableFields.includes('email')" class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                        <h3 class="label">Email</h3>
                        <div>{{email}}</div>
                    </div>
                    <md-field v-else class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-75">
                        <label>Email</label>
                        <md-input v-model="email"></md-input>
                        <span v-if="inputError === 'email'" class="error form-error">Please enter a valid email</span>
                        <span v-if="inputError === 'emailDup'" class="error form-error">Email is already registered</span>
                    </md-field>
                    <div class="md-layout-item"></div>
                </div>
                <div v-if="licenseType === UserTypeEnum.Professor">
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <div v-if="nonEditableFields && nonEditableFields.includes('school')" class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                            <h3 class="label">School</h3>
                            <div>{{licenseData.school}}</div>
                        </div>
                        <md-field v-else class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-75">
                            <label>School</label>
                            <md-input v-model="licenseData.school"></md-input>
                        </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <div class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                            <h3 class="label">License Start</h3>
                            <div v-if="nonEditableFields && nonEditableFields.includes('licenseStart')">
                                {{formattedLicenseStart}}
                            </div>
                            <div v-else>
                                <md-datepicker v-model="licenseData.licenseStart" md-immediately/>
                            </div>
                        </div>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <div class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                            <h3 class="label">License End</h3>
                            <div v-if="nonEditableFields && nonEditableFields.includes('licenseEnd')">
                                {{formattedLicenseEnd}}
                            </div>
                            <div v-else>
                                <md-datepicker v-model="licenseData.licenseEnd" md-immediately/>
                            </div>
                        </div>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <div v-if="nonEditableFields && nonEditableFields.includes('licensePrice')" class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                            <h3 class="label">License Fee</h3>
                            <div>{{licenseData.licensePrice | toCurrency}}</div>
                        </div>
                        <md-field v-else class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-75">
                            <label>License Fee</label>
                            <CurrencyInput :allows-null-val=false :allows-negative=false :currency-to-format.sync="licenseData.licensePrice">
                            </CurrencyInput>
                        </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <div v-if="nonEditableFields && nonEditableFields.includes('numAllowedGames')" class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                            <h3 class="label">Number Allowed Games</h3>
                            <div>{{licenseData.numAllowedGames}}</div>
                        </div>
                        <md-field v-else class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-75">
                            <label>Number Allowed Games</label>
                            <md-input v-model="licenseData.numAllowedGames" type="number"></md-input>
                            <span v-if="inputError === 'numGames'" class="error form-error">Please enter the number of allowed games</span>
                        </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <div v-if="nonEditableFields && nonEditableFields.includes('studentPrice')" class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                            <h3 class="label">Student Fee</h3>
                            <div>{{licenseData.studentPrice | toCurrency}}</div>
                        </div>
                        <md-field v-else class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-75">
                            <label>Student Fee</label>
                            <CurrencyInput :allows-null-val=false :allows-negative=false :currency-to-format.sync="licenseData.studentPrice">
                            </CurrencyInput>
                        </md-field>
                        <div class="md-layout-item"></div>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item"></div>
                    <md-button :disabled="httpWait" @click="submitLicense()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
                        Submit
                        <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                    </md-button>
                    <div class="md-layout-item"></div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item"></div>
                    <div class="md-layout-item error server-error" v-if="serverError">{{serverError}}</div>
                    <div class="md-layout-item"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { MdField } from 'vue-material/dist/components'
import { MdButton } from 'vue-material/dist/components'
import { DateTime } from "luxon";
import CurrencyInput from './CurrencyInput';
import { UserTypeEnum } from "temporary-api-types/apiTypes";
Vue.use(MdField);
Vue.use(MdButton);
export default {
    name: 'CreateNewAdminUser',
    components: {
        CurrencyInput
	},
    data() {
        let startDate = DateTime.local().startOf('day');
        const now = DateTime.local();
        let endDate = startDate.plus({years: 1});
        return {
            disabledDates: date => {
                const day = date.getDay()
                return day !== 1 || DateTime.fromMillis(date.getTime()) < now;
            },
            email: "",
            licenseData: {
                id: null,
                firstName: "",
                lastName: "",
                school: "",
                licenseStart: startDate.toJSDate(),
                licenseEnd: endDate.toJSDate(),
                licensePrice: 0,
                numAllowedGames: "",
                studentPrice: 0
            },
            inputError: "",
            serverError: null,
            url: process.env.VUE_APP_LAMBDA_URL,
            UserTypeEnum,
            httpWait: false
        }
    },
    props: {
        licenseType: {
            type: String
        },
        license: {
            type: Object
        },
        nonEditableFields: {
            type: Array
        },
        formType: {
            type: String
        },
        isWarningLicense: {
            type: Boolean
        },
        successCb: {
            type: Function
        }
    },
    computed: {
        ...mapGetters([
            'getFormattedDate'
        ]),
        formattedLicensePrice() {
            return this.$options.filters.toCurrency(this.licenseData.licensePrice);
        },
        formattedStudentPrice() {
            return this.$options.filters.toCurrency(this.licenseData.studentPrice);
        },
        formattedLicenseStart() {
            if(this.licenseData.licenseStart && typeof this.licenseData.licenseStart === 'number') {
                return this.getFormattedDate(this.licenseData.licenseStart);
            } else {
                return 'n/a';
            }
        },
        formattedLicenseEnd() {
            if(this.licenseData.licenseEnd && typeof this.licenseData.licenseEnd === 'number') {
                return this.getFormattedDate(this.licenseData.licenseEnd);
            } else {
                return 'n/a';
            }
        }
    },
    methods: {
        ...mapActions([
            'adminCreateOrUpdateLicense',
            'fetchUserByEmail'
        ]),
        async createOrUpdateLicense() {
            let licenseStart;
            let licenseEnd;
            if(this.nonEditableFields && this.nonEditableFields.includes('licenseStart')) {
                licenseStart = this.license.licenseStart;
            } else {
                licenseStart = (this.licenseData.licenseStart.getTime() / 1000);
            }
            if(this.nonEditableFields && this.nonEditableFields.includes('licenseStart')) {
                licenseEnd = this.license.licenseEnd;
            } else {
                licenseEnd = (this.licenseData.licenseEnd.getTime() / 1000);
            }
            const licenseInput = {
                school: this.licenseData.school,
                licenseStart: licenseStart,
                licenseEnd: licenseEnd,
                price: this.licenseData.licensePrice,
                numAllowedGames: parseInt(this.licenseData.numAllowedGames),
                studentPrice: this.licenseData.studentPrice,
                isBanned: false,
                isWarningLicense: this.isWarningLicense
            }
            try {
                return await this.adminCreateOrUpdateLicense({
                    email: this.email,
                    licenseType: this.licenseType,
                    licenseInput
                });
            } catch(err) {
                this.httpWait = false;
                return err;
            }
        },
        async sendLicenseToExistingUser() {
            const data = {
                email: this.email,
                groupName: this.licenseType
            };
            try {
                const response = await fetch(`${this.url}/sendLicenseInfo`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Request-Method': 'POST'
                    },
                    body: JSON.stringify(data)
                });
                if(!response.ok) {
                    this.serverError = "Something went wrong. Please try again";
                    this.httpWait = false;
                    return Promise.reject(this.serverError);
                }
                if(this.successCb) {
                    await this.successCb();
                    this.httpWait = false;
                }
                this.httpWait = false;
                return;
            } catch(err) {
                this.serverError = "Something went wrong. Please try again";
                this.httpWait = false;
                return Promise.reject(err);
            }
        },
        async resendLicenseInvite() {
            const data = {
                email: this.email,
                groupName: this.licenseType
            };
            try {
                const response = await fetch(`${this.url}/sendLicenseInvite`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Request-Method': 'POST'
                    },
                    body: JSON.stringify(data)
                });
                if(!response.ok) {
                    this.serverError = "Something went wrong. Please try again";
                    this.httpWait = false;
                    return Promise.reject(this.serverError);
                }
                if(this.successCb) {
                    await this.successCb();
                    this.httpWait = false;
                }
                this.httpWait = false;
                return;
            } catch(err) {
                this.serverError = "Something went wrong. Please try again";
                this.httpWait = false;
                return Promise.reject(err);
            }
        },
        async submitLicense() {
            this.httpWait = true;
            if(this.validateForm()) {
                const licenseResponse = await this.createOrUpdateLicense();
                if(licenseResponse.id !== this.licenseData.id) {
                    const data = {
                        email: this.email,
                        groupName: this.licenseType
                    };
                    try {
                        const response = await fetch(`${this.url}/adminCreateUser`, {
                            method: 'POST',
                            mode: 'cors',
                            cache: 'no-cache',
                            headers: {
                                'Content-Type': 'application/json',
                                'Access-Control-Request-Method': 'POST'
                            },
                            body: JSON.stringify(data)
                        });
                        const responseJson = await response.json();
                        if(!response.ok) {
                            this.serverError = "Something went wrong. Please try again";
                            this.httpWait = false;
                            return this.serverError;
                        } else {
                            if(responseJson.message === "Cognito user already exists. Set password is not required") {
                                const user = await this.fetchUserByEmail({ email: this.email });
                                // check to see if the user exists in our db and call the appropriate lambda, which will then send the correct email
                                if(user) {
                                    return await this.sendLicenseToExistingUser();
                                } else {
                                    return await this.resendLicenseInvite();
                                }
                            }
                        }
                    } catch(err) {
                        this.serverError = "Something went wrong. Please try again";
                        this.httpWait = false;
                        return err;
                    }
                }
                if(this.successCb) {
                    await this.successCb();
                    this.httpWait = false;
                }
            }
            this.httpWait = false;
        },
        validateForm() {
            if(!this.validateEmail(this.email)) {
                return;
            } else if(this.licenseType === UserTypeEnum.Professor && !this.licenseData.numAllowedGames) {
                this.inputError = "numGames";
                return false;
            } else {
                this.inputError = "";
                return true;
            }
        },
        validateEmail(email) {
            let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(email && re.test(String(email).toLowerCase())) {
                return true;
            } else {
                this.inputError = "email";
                return false;
            }
        }
    },
    created() {
        if(this.license) {
            this.licenseData.id = this.license.id
            if(this.license.user) {
              this.licenseData.firstName = this.license.user.firstname;
              this.licenseData.lastName = this.license.user.lastname;
            }
            this.email = this.license.email;
            this.licenseData.licenseStart = DateTime.fromSeconds(this.license.licenseStart, {zone: "America/Denver"}).toJSDate();
            this.licenseData.licenseEnd = DateTime.fromSeconds(this.license.licenseEnd, {zone: "America/Denver"}).toJSDate();
            this.licenseData.school = this.license.school;
            this.licenseData.licensePrice = this.license.price;
            this.licenseData.numAllowedGames = this.license.numAllowedGames;
            this.licenseData.studentPrice = this.license.studentPrice;
        }
    }
}
</script>

<style scoped>
.error {
    color: #ff1744;
}
.server-error {
    text-align: center;
}
.form-error {
    display: block!important;
    left: 0;
    height: 20px;
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    transition: .3s cubic-bezier(.4,0,.2,1);
}
</style>
