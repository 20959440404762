<template>
    <div v-if="isPageReady && isCurrentRound" class="journal-entries-and-t-accounts-container">
        <div v-if="!answersSubmitted">
            <div class="page-title">
                <span style="color: var(--text-primary)" v-if="!isCurrentRound">End of </span><span style="color: var(--text-primary)">Round {{roundNum}} Journal Entries</span>
            </div>
            <div>
                <span style="font-style: italic;">Each field represents one point out of {{ totalPointsAvailable }} available points for this round.</span>
            </div>
            <div>
                <div class="journal-entries-container journal-entry-selected" v-for="journalEntry in filteredJournalEntries" :key="journalEntry.id">
                    <div class="journal-entry-table link md-layout-item journal-entry-table-selected">
                        <div class="md-layout journal-entry-header journal-entry-selected-header">
                            <div class="md-layout-item text-left"> {{journalEntry.friendlyName}}</div>
                            <!-- note #{{journalEntry.miniId}} was previously included above-->
                            <img src="../assets/qmark.svg" style="height: 16px; cursor: pointer;" @click="emitAssetHelpContent(journalEntry.friendlyName)">
                            <div class="md-layout-item journal-entry-amount-column" style="text-align: end;">Debits</div>
                            <div class="md-layout-item journal-entry-amount-column" style="text-align: end;">Credits</div>
                        </div>
                        <div class="md-layout journal-entry-content" v-for="entry in journalEntry.entries" :key="entry.id">
                            <select v-model="answeredEntries[entry.id].entryAccount" class="md-layout-item text-left">
                                <option disabled value="">Select</option>
                                <option v-for="account in getCurrentJournalEntriesAndTAccounts.tAccounts" :key="account.account" :value="account.account">{{account.friendlyName}}</option>
                            </select>
                            <div class="md-layout-item journal-entry-amount-column">
                                <input class="je-entry" type="number" pattern="[0-9]*" name="debits"  min="0" v-model.number="answeredEntries[entry.id].entryAmount" v-if="entryIsDebit(entry)" />
                            </div>
                            <div class="md-layout-item journal-entry-amount-column">
                                <input class="je-entry" type="number" pattern="[0-9]*" name="credits" min="0" v-model.number="answeredEntries[entry.id].entryAmount" v-if="!entryIsDebit(entry)" />
                            </div>                    
                        </div>
                    </div>
                </div>
            </div>
            <button style="width: 100px; height: 100px;" @click="submitAnswers()">SUBMIT</button>
        </div>
        <div v-else>
            <h1>Good Luck! Your grade will appear here when the round moves forward.</h1>
        </div>
    </div>
    <div v-else style="padding-top: 100px;">
        <h2 v-if="isCurrentRound">Good Luck! Your grade will appear here when the round moves forward.</h2>
        <h1 v-else>You got {{ displayableGrade }} points for round {{ roundNum }}</h1>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  UserTypeEnum
} from "temporary-api-types/apiTypes";
import { isRef } from 'vue';

export default {
    name: 'EditThisJournal',
    data() {
        return {
            attrs: {
                currentJournalEntryFilter: this.journalEntryFilter,
                currentAvailableJournalEntryFilters: this.availableJournalEntryFilters 
            },
            answeredEntries: null,
            correctEntries: null,
            gradeForThis: null,
            teamId: null,
            semesterId: null,
            selectedJournalEntryId: null,
            displayableTAccounts: null,
            isCurrentRound: false,
            roundNum: null,
            isPageReady: false,
            showFixedAssetsModal: false,
            answersSubmitted: false,
            displayableGrade: null,
            totalPointsAvailable: null,
            answerGradesArray: [],
            answerGradesPossiblePointsArray: [],
            roundIndex: null,
            testMessage: null,
            currentLands: null,
            currentBuildings: null,
            currentEquipments: null,
            currentFurnishings: null,
            currentDebts: null,
            currentBonds: null,
            showAssetHelp: false,
            assetHelpContent: null,
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentJournalEntriesAndTAccounts',
            'getCurrentCourse',
            'getCurrentGameState',
            'getUserType',
            'getCurrentAssets'
        ]),
        currentJournalEntryFilter: {
            get() {
                return this.attrs.currentJournalEntryFilter;
            },
            set(value) {
                this.attrs.currentJournalEntryFilter = value;
                this.$emit(`update:journal-entry-filter`, value);
            }
        },
        currentAvailableJournalEntryFilters: {
            get() {
                return this.attrs.currentAvailableJournalEntryFilters;
            },
            set(value) {
                this.attrs.currentAvailableJournalEntryFilters = value;
                this.$emit(`update:available-journal-entry-filters`, value);
            }
        }
    },
    props: {
        selectedTeam: {
            type: Object
        },
        activeRoundId: {
            type: String
        },
        journalEntryFilter: {
            type: String
        },
        availableJournalEntryFilters: {
            type: Array
        }
    },
    watch: {
        getCurrentAssets(val) {
            if(val) {
                this.initCurrentAssetsTable();
            }
        },
        async activeRoundId(newVal, oldVal) {
            if(newVal && newVal !== oldVal) {
                await this.init();
            }
        },
        selectedJournalEntryId(newVal, oldVal) {
            if(newVal && newVal !== oldVal) {
                this.filterTAccounts();
            }
        },
        journalEntryFilter(val) {
            if(val) {
                const filteredAccountEnums = this.getAccountEnumList(val);
                this.filteredJournalEntries = this.getCurrentJournalEntriesAndTAccounts.journalEntries.filter((journalEntry) => {
                    return !!journalEntry.entries.find((entry) => {
                        return filteredAccountEnums.validAccounts.includes(entry.account)
                    });
                });

                if(filteredAccountEnums.requiredAccounts && filteredAccountEnums.requiredAccounts.length) {
                    this.filteredJournalEntries = this.filteredJournalEntries.filter((journalEntry) => {
                        return !!journalEntry.entries.find((entry) => {
                            return filteredAccountEnums.requiredAccounts.includes(entry.account)
                        });
                    });
                }
            } else {
                this.filteredJournalEntries = this.getCurrentJournalEntriesAndTAccounts.journalEntries;
            }
            if(this.filteredJournalEntries.length) {
                this.selectedJournalEntryId = this.filteredJournalEntries[0].id;
            }
        }
    },
    methods: {
        ...mapActions([
            'fetchJournalEntriesAndTAccounts',
        ]),
        changeAssetHelp() {
            this.showAssetHelp = !this.showAssetHelp;
        },
        emitAssetHelpContent(je) {
            if(je.includes("Land")) {
                const allLandIds = () => {
                    let ids = [];
                    this.currentLands.forEach(land => {
                        ids.push(land.friendlyId);
                    });
                    return ids;
                }
                this.$emit('scrollToIt', 'Land Purchase(s)', allLandIds());
            } else if(je.includes("Building")) {
                const allBuildingIds = () => {
                    let ids = [];
                    this.currentBuildings.forEach(building => {
                        ids.push(building.friendlyId);
                    });
                    return ids;
                }
                this.$emit('scrollToIt', 'Building Purchase(s)', allBuildingIds());
            } else if(je.includes("Equipment")) {
                const allEquipmentIds = () => {
                    let ids = [];
                    this.currentEquipments.forEach(equipment => {
                        ids.push(equipment.friendlyId);
                    });
                    return ids;
                }
                this.$emit('scrollToIt', 'Equipment Purchase(s)', allEquipmentIds());
            }
        },
        initCurrentAssetsTable() {
            this.currentLands = this.getCurrentAssets.lands.filter(land => {
                return land.isSold === false;
            }).sort((a, b) => a.originalRoundPurchasedNum - b.originalRoundPurchasedNum);
            this.currentBuildings = this.getCurrentAssets.buildings.filter(building => {
                return building.isSold === false;
            }).sort((a, b) => a.originalRoundPurchasedNum - b.originalRoundPurchasedNum);

            this.currentEquipments = this.getCurrentAssets.equipments.filter(equipment => {
                return equipment.isSold === false;
            }).sort((a, b) => a.originalRoundPurchasedNum - b.originalRoundPurchasedNum);

            this.currentFurnishings = this.getCurrentAssets.storeFurnishings.filter(furnishing => {
                return furnishing.isSold === false;
            }).sort((a, b) => a.originalRoundPurchasedNum - b.originalRoundPurchasedNum);
            this.currentDebts = this.getCurrentAssets.debts.filter(debt => {
                return debt.outstandingPrincipal !== 0;
            }).sort((a, b) => a.originalRoundBorrowedNum - b.originalRoundBorrowedNum);
            this.currentBonds = JSON.parse(JSON.stringify(this.getCurrentAssets)).bonds.sort((a, b) => a.roundIssued - b.roundIssued);
        },
        entryIsDebit(entry) {
            return entry.amount > 0 || (entry.amount === 0 && entry.normalBalance !== 'CREDIT');
        },
        getAccountEnumList(accountName) {
            switch (accountName) {
                // Income Statement
                case "revenue":
                    return {
                        validAccounts: ["REVENUE"],
                        requiredAccounts: []
                    }
                case "returns":
                    return {
                        validAccounts: ["SALES_RETURNS_AND_ALLOWANCES"],
                        requiredAccounts: []
                    }
                case "net":
                    return {
                        validAccounts: ["REVENUE","SALES_RETURNS_AND_ALLOWANCES"],
                        requiredAccounts: []
                    }
                case "costOfGoodsSold":
                    return {
                        validAccounts: ["COGS"],
                        requiredAccounts: []
                    }
                case "grossProfit":
                    return {
                        validAccounts: ["REVENUE","SALES_RETURNS","COGS"],
                        requiredAccounts: []
                    }
                case "adExpense":
                    return {
                        validAccounts: ["ADVERTISING_EXPENSE"],
                        requiredAccounts: []
                    }
                case "depreciationExpense":
                    return {
                        validAccounts: ["DEPRECIATION_EXPENSE"],
                        requiredAccounts: []
                    }
                case "interest":
                    return {
                        validAccounts: ["INTEREST_EXPENSE"],
                        requiredAccounts: []
                    }
                case "badDebtExpense":
                    return {
                        validAccounts: ["BAD_DEBT"],
                        requiredAccounts: []
                    }
                case "gainOnAssetSales":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: []
                    }
                case "incomeTax":
                    return {
                        validAccounts: ["INCOME_SUMMARY"],
                        requiredAccounts: []
                    }
                // Balance Sheet
                case "cash":
                    return {
                        validAccounts: ["CASH"],
                        requiredAccounts: []
                    }
                case "accountsReceivable":
                    return {
                        validAccounts: ["RECEIVABLE"],
                        requiredAccounts: []
                    }
                case "inventory":
                    return {
                        validAccounts: ["INVENTORY"],
                        requiredAccounts: []
                    }
                case "equipment":
                    return {
                        validAccounts: ["EQUIPMENT"],
                        requiredAccounts: []
                    }
                case "equipmentDepreciation":
                    return {
                        validAccounts: ["A_D_EQUIPMENT"],
                        requiredAccounts: []
                    }
                case "furnishings":
                    return {
                        validAccounts: ["FURNISHING"],
                        requiredAccounts: []
                    }
                case "furnishingsDepreciation":
                    return {
                        validAccounts: ["A_D_FURNISHING"],
                        requiredAccounts: []
                    }
                case "buildings":
                    return {
                        validAccounts: ["BUILDING"],
                        requiredAccounts: []
                    }
                case "buildingsDepreciation":
                    return {
                        validAccounts: ["A_D_BUILDING"],
                        requiredAccounts: []
                    }
                case "land":
                    return {
                        validAccounts: ["LAND"],
                        requiredAccounts: []
                    }
                case "loanPayable":
                    return {
                        validAccounts: ["LOAN_PAYABLE"],
                        requiredAccounts: []
                    }
                case "commonStock":
                    return {
                        validAccounts: ["CASH"],
                        requiredAccounts: []
                    }
                case "retainedEarnings":
                    return {
                        validAccounts: ["RETAINED_EARNINGS"],
                        requiredAccounts: []
                    }
                case "bondsPayable":
                    return {
                        validAccounts: ["BONDS_PAYABLE"],
                        requiredAccounts: []
                    }
                case "bondsPremiumOrDiscount":
                    return {
                        validAccounts: ["PREMIUM_ON_BONDS","DISCOUNT_ON_BONDS"],
                        requiredAccounts: []
                    }
                // Cash Flow
                case "operatingActivitiesSales":
                    return {
                        validAccounts: ["REVENUE"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesPurchasedInventory":
                    return {
                        validAccounts: ["INVENTORY","COGS"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesPaidAdvertising":
                    return {
                        validAccounts: ["ADVERTISING_EXPENSE"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesSalesReturns":
                    return {
                        validAccounts: ["SALES_RETURNS_AND_ALLOWANCES"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesIncomeTaxesPaid":
                    return {
                        validAccounts: ["INCOME_TAX_EXPENSE"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesInterestPaid":
                    return {
                        validAccounts: ["INTEREST_EXPENSE"],
                        requiredAccounts: []
                    }
                case "investingActivitiesInflowEquipment":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: ["EQUIPMENT"]
                    }
                case "investingActivitiesInflowFurnishings":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: ["FURNISHING"]
                    }
                case "investingActivitiesInflowBuildings":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: ["BUILDING"]
                    }
                case "investingActivitiesInflowLand":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: ["LAND"]
                    }
                case "investingActivitiesOutflowEquipment":
                    return {
                        validAccounts: ["EQUIPMENT"],
                        requiredAccounts: []
                    }
                case "investingActivitiesOutflowFurnishings":
                    return {
                        validAccounts: ["FURNISHING"],
                        requiredAccounts: []
                    }
                case "investingActivitiesOutflowBuildings":
                    return {
                        validAccounts: ["BUILDING"],
                        requiredAccounts: []
                    }
                case "investingActivitiesOutflowLand":
                    return {
                        validAccounts: ["LAND"],
                        requiredAccounts: []
                    }
                case "financingActivitiesCapital":
                    return {
                        validAccounts: ["STOCK"],
                        requiredAccounts: []
                    }
                case "bondBorrowing":
                    return {
                        validAccounts: ["BONDS_PAYABLE"],
                        requiredAccounts: []
                    }
                case "financingActivitiesLoanBorrowing":
                    return {
                        validAccounts: ["LOAN_PAYABLE"],
                        requiredAccounts: []
                    }
                case "bondRepayment":
                    return {
                        validAccounts: ["BONDS_PAYABLE"],
                        requiredAccounts: []
                    }
                case "financingActivitiesLoanRepay":
                    return {
                        validAccounts: ["LOAN_PAYABLE"],
                        requiredAccounts: []
                    }
                default:
                    return {
                        validAccounts: [],
                        requiredAccounts: []
                    }
            }
        },
        getAccountPropsListFromEnum(enumList) {
            let propsObj = {};

            enumList.forEach((enumName) => {
                switch(enumName) {
                    case "GAIN_ON_SALE":
                        propsObj["gainOnAssetSales"] = true;
                        if(enumList.includes("EQUIPMENT")) {
                            propsObj["investingActivitiesInflowEquipment"] = true;
                        }
                        if(enumList.includes("FURNISHING")) {
                            propsObj["investingActivitiesInflowFurnishings"] = true;
                        }
                        if(enumList.includes("BUILDING")) {
                            propsObj["investingActivitiesInflowBuildings"] = true;
                        }
                        if(enumList.includes("LAND")) {
                            propsObj["investingActivitiesInflowLand"] = true;
                        }
                        break;
                    case "REVENUE":
                        propsObj["revenue"] = true;
                        propsObj["net"] = true;
                        propsObj["grossProfit"] = true;
                        propsObj["operatingActivitiesSales"] = true;
                        break;
                    case "SALES_RETURNS_AND_ALLOWANCES":
                        propsObj["returns"] = true;
                        propsObj["net"] = true;
                        propsObj["operatingActivitiesSalesReturns"] = true;
                        break;
                    case "COGS":
                        propsObj["costOfGoodsSold"] = true;
                        propsObj["grossProfit"] = true;
                        propsObj["operatingActivitiesPurchasedInventory"] = true;
                        break;
                    case "SALES_RETURNS":
                        propsObj["grossProfit"] = true;
                        break;
                    case "ADVERTISING_EXPENSE":
                        propsObj["adExpense"] = true;
                        propsObj["operatingActivitiesPaidAdvertising"] = true;
                        break;
                    case "DEPRECIATION_EXPENSE":
                        propsObj["depreciationExpense"] = true;
                        break;
                    case "INTEREST_EXPENSE":
                        propsObj["interest"] = true;
                        propsObj["operatingActivitiesInterestPaid"] = true;
                        break;
                    case "INCOME_SUMMARY":
                        propsObj["incomeTax"] - true;
                        break;
                    case "CASH":
                        propsObj["cash"] = true;
                        propsObj["commonStock"] = true;
                        break;
                    case "INVENTORY":
                        propsObj["inventory"] = true;
                        propsObj["operatingActivitiesPurchasedInventory"] = true;
                        break;
                    case "EQUIPMENT":
                        propsObj["equipment"] = true;
                        propsObj["investingActivitiesOutflowEquipment"] = true;
                        break;
                    case "A_D_EQUIPMENT":
                        propsObj["equipmentDepreciation"] = true;
                        break;
                    case "FURNISHING":
                        propsObj["furnishings"] = true;
                        propsObj["investingActivitiesOutflowFurnishings"] = true;
                        break;
                    case "A_D_FURNISHING":
                        propsObj["furnishingsDepreciation"] = true;
                        break;
                    case "BUILDING":
                        propsObj["buildings"] = true;
                        propsObj["investingActivitiesOutflowBuildings"] = true;
                        break;
                    case "A_D_BUILDING":
                        propsObj["buildingsDepreciation"] = true;
                        break;
                    case "LAND":
                        propsObj["land"] = true;
                        propsObj["investingActivitiesOutflowLand"] = true;
                        break;
                    case "LOAN_PAYABLE":
                        propsObj["loanPayable"] = true;
                        propsObj["financingActivitiesLoanBorrowing"] = true;
                        propsObj["financingActivitiesLoanRepay"] = true;
                        break;
                    case "RETAINED_EARNINGS":
                        propsObj["retainedEarnings"] = true;
                        break;
                    case "BONDS_PAYABLE":
                        propsObj["bondsPayable"] = true;
                        propsObj["bondBorrowing"] = true;
                        propsObj["bondRepayment"] = true;
                        break;
                    case "PREMIUM_ON_BONDS":
                        propsObj["bondsPremiumOrDiscount"] = true;
                        break;
                    case "DISCOUNT_ON_BONDS":
                        propsObj["bondsPremiumOrDiscount"] = true;
                        break;
                    case "INCOME_TAX_EXPENSE":
                        propsObj["operatingActivitiesIncomeTaxesPaid"] = true;
                        break;
                    case "STOCK":
                        propsObj["financingActivitiesCapital"] = true;
                        break;
                    case "RECEIVABLE":
                        propsObj["accountsReceivable"] = true;
                        break;
                    case "BAD_DEBT":
                        propsObj["badDebtExpense"] = true;
                        break;
                    default:
                        break;
                }
            });
            return Object.keys(propsObj);
        },
        filterTAccounts() {
            let selectedJournalEntry = this.getCurrentJournalEntriesAndTAccounts.journalEntries.filter((journalEntry) => {
                return this.selectedJournalEntryId === journalEntry.id
            })[0];

            let selectedAccounts = selectedJournalEntry.entries.map(entry => entry.account);

            this.displayableTAccounts = this.getCurrentJournalEntriesAndTAccounts.tAccounts.filter((tAccount) => {
                return selectedAccounts.includes(tAccount.account)
            });
        },
        submitAnswers() {
            this.answersSubmitted = true;
            this.gradeForThis = () => {
                let grade = 0; // every field, account and amount, is worth a point
                for (const [key, value] of Object.entries(this.answeredEntries)) {
                    if(value.entryAmount === Math.abs(this.correctEntries[key].entryAmount)) {
                        grade++;
                    }
                }
                for (const [key, value] of Object.entries(this.answeredEntries)) {
                    if(value.entryAccount === this.correctEntries[key].entryAccount) {
                        grade++;
                    }
                }
                return grade.toString();
            }
            this.totalPointsAvailable = (Object.keys(this.correctEntries).length * 2).toString();
            if(!localStorage.getItem('answerGrades')) {
                this.answerGradesArray = [];
                this.answerGradesArray.push(this.gradeForThis());
                localStorage.setItem('answerGrades', JSON.stringify(this.answerGradesArray));
            } else {
                this.answerGradesArray = JSON.parse(localStorage.getItem('answerGrades'));
                this.answerGradesArray.push(this.gradeForThis());
                localStorage.setItem('answerGrades', JSON.stringify(this.answerGradesArray));
            }
            if(!localStorage.getItem('answerPossiblePoints')) {
                this.answerGradesPossiblePointsArray = [];
                this.answerGradesPossiblePointsArray.push(this.totalPointsAvailable);
                localStorage.setItem('answerPossiblePoints', JSON.stringify(this.answerGradesPossiblePointsArray));
            } else {
                this.answerGradesPossiblePointsArray = JSON.parse(localStorage.getItem('answerPossiblePoints'));
                this.answerGradesPossiblePointsArray.push(this.totalPointsAvailable);
                localStorage.setItem('answerPossiblePoints', JSON.stringify(this.answerGradesPossiblePointsArray));
            }
        },
        grabAnswers () {
            if(localStorage.getItem('answerGrades')) {
                this.answerGradesArray = JSON.parse(localStorage.getItem('answerGrades'));
            }
            if(localStorage.getItem('answerPossiblePoints')) {
                this.answerGradesPossiblePointsArray = JSON.parse(localStorage.getItem('answerPossiblePoints'));
            }
            if(this.isCurrentRound) {
                this.displayableGrade = "good luck!";
            } else {
                this.displayableGrade = this.answerGradesArray[this.roundIndex] + "/" + this.answerGradesPossiblePointsArray[this.roundIndex];
            }
        },
        async init() {
            if(this.activeRoundId && this.teamId && this.selectedTeam) {
                this.grabAnswers();
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                const gameState = this.getCurrentGameState;
                this.isCurrentRound = this.activeRoundId === gameState.roundId;
                this.roundNum = roundIndex + 1;
                this.roundIndex = roundIndex;
                if(localStorage.getItem('answerGrades')) {
                    this.answerGradesArray = JSON.parse(localStorage.getItem('answerGrades'));
                    this.displayableGrade = this.answerGradesArray[this.roundIndex];
                }
                // if below is truthy, then we are in quiz phase. otherwise, get graded for round index (WORK WITH PAST ROUND SELECT)
                // interesting fact: if you click to view a previous round's grade, this will be false
                if(!this.answerGradesArray[roundIndex]) {
                    await this.fetchJournalEntriesAndTAccounts({
                        roundConfigId: this.activeRoundId,
                        teamId: this.teamId
                    });
                    if(this.getCurrentJournalEntriesAndTAccounts.journalEntries.length) {
                        this.filteredJournalEntries = this.getCurrentJournalEntriesAndTAccounts.journalEntries;
                        this.selectedJournalEntryId = this.filteredJournalEntries[0].id;

                        const enumsList = this.getCurrentJournalEntriesAndTAccounts.journalEntries.flatMap((entry) => {
                            return entry.entries.map(_entry => _entry.account)
                        });
                        const dedupedEnumsList = enumsList.filter((item, pos) => {
                            return enumsList.indexOf(item) == pos;
                        });
                        this.currentAvailableJournalEntryFilters = this.getAccountPropsListFromEnum(dedupedEnumsList);
                    }
                    this.answeredEntries = Object.fromEntries(this.getCurrentJournalEntriesAndTAccounts.journalEntries.flatMap((entry) => {
                    return entry.entries.map(_entry => {
                        return [_entry.id, {
                            entryId: _entry.id, // id hash
                            entryNormalBalance: _entry.normalBalance, // credit, debit, or special
                            entryAccount: null, // string of acc name
                            entryAmount: 0, // dollar amount
                            entryRoundIndex: _entry.roundIndex, // round it was stored from
                            entryGrade: 0, // grade, 1 if either account or credit is correct, 2 if both are, 0 if none
                        }];
                        });
                    }));
                    this.correctEntries = Object.fromEntries(this.getCurrentJournalEntriesAndTAccounts.journalEntries.flatMap((entry) => {
                        return entry.entries.map(_entry => {
                            return [_entry.id, {
                                entryId: _entry.id,
                                entryNormalBalance: _entry.normalBalance,
                                entryAccount: _entry.account,
                                entryAmount: _entry.amount,
                                entryRoundIndex: _entry.roundIndex,
                            }];
                        });
                    }));
                    this.totalPointsAvailable = (Object.keys(this.correctEntries).length * 2).toString();
                } else {
                    this.grabAnswers();
                    return;
                }
                this.isPageReady = true;
            }
        }
    },
    async created() {
        this.teamId = this.$route.params.teamId;
        this.initCurrentAssetsTable();
        await this.init();
    }
}
</script>

<style scoped>
    .text-and-help {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
    }
    input.je-entry {
        width: 100%;
    }

    h1, h2, h3, h4 {
        color: var(--text-primary);
    }
    .journal-entries-and-t-accounts-container {
        padding-bottom: 64px;
    }

    .journal-entries-container {
        padding-top: 20px;
    }

    .journal-entry-table {
        border: solid 1px var(--border-color);
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        max-width: 500px;
        background-color: var(--inner-box-color);
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .account-label-credit {
        padding-left: 20px;
    }

    .t-account-table {
        border: solid 1px black;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-right: 20px;
        width: 400px;
        display: inline-block;
        vertical-align: top;
        background-color: white;
    }

    .border-top {
        border-top: solid 2px black;
    }

    .border-bottom {
        border-bottom: solid 2px black;
    }

    .border-right {
        border-right: solid 2px black;
    }

    .journal-entry-selected {
        background-color: var(--selected-je-bg);
    }

    .journal-entry-header {
        background-color: var(--table-title-bg-color);
        color: white;
        padding-left: 10px;
        padding-right: 10px;
    }

    .journal-entry-content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .journal-entry-amount-column {
        max-width: 70px;
        text-align: center;
    }

    .journal-entry-selected-header {
        background-color: var(--success-bg-color);
        color: white;
        padding-left: 10px;
        padding-right: 10px;
        cursor: auto;
    }

    .journal-entry-table-selected {
        border: 2px solid var(--success-bg-color);
        background-color: white;
    }

    .selected-t-account-entry {
        font-weight: bold;
        background-color: var(--table-cell-color);
    }

    .instructions-container {
      margin-top: 60px;
    }

    .page-title {
        font-weight: bold;
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .filtered-journal-entry-banner {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 15px;
        margin-right: 15px;
        border: 2px solid var(--btn-primary-color);
        font-weight: bold;
        background: rgba(67,176,249,0.12) !important;
    }

    .clear-filter-link {
        color: var(--btn-primary-color);
    }

    .clear-filter-link:hover {
        text-decoration: underline;
    }

    .fixed-assets-content {
      margin-left: 15px;
      margin-right: 15px;
    }

    .fixed-assets-button-container {
      position: relative;
      width: 100%;
    }

    .fixed-assets-link {
      position: absolute;
      right: 15px;
    }

    .fixed-assets-link:hover {
      cursor: pointer;
    }
</style>
