<template>
<!-- eslint-disable-next-line vue/no-mutating-props -->
<md-table md-card v-model="table.rows">
    THEFUCK
    <md-table-row class="main" slot="md-table-row" slot-scope="{ item }">
        <md-table-cell v-for="[key, value] of Object.entries(item.text)" :key="key" class="financial-ratios-table-cell" :md-label="fancy(key)">{{ value }}</md-table-cell>
    </md-table-row>
</md-table>
</template>

<script>
export default {
    // I am very fancy
    methods: {
        fancy(str) {
            return str.match(/[A-Za-z\d][a-z\d]*/g).map(s => s[0].toUpperCase() + s.slice(1)).join(' ');
        }
    },
    name: 'GeneralLedgerTable',
    props: {
        table: Object
    },
    created() {
        console.log(this.table);
    }
}
</script>
