<template>
    <div>
        <div class="title-and-feedback-container">
            <img>
            <h1 style="text-align: center;">Glossary Center</h1>
            <img>
        </div>
        <div style="display: flex; justify-content: center;">
            <input style="width: 1em; height: 1em;" type="checkbox" id="list" value="list" v-model="showList" />
            <label for="list">List</label>
            <input style="width: 1em; height: 1em;" type="checkbox" id="grid" value="grid" v-model="showGrid" />
            <label for="grid">Flash Grid</label>
        </div>
        <div v-if="showGrid" class="glossary-center-container">
            <div class="glossary-center-memorize">
                <div class="glossary-center-gridded">
                    <div v-for="[k] in Object.entries(definitions)" :key="k" class="message">
                        <div class="message-content">
                            <h2><Dict>{{ k }}</Dict></h2>
                            <md-icon class="info-icon">info</md-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showList" class="glossary-center-container-list">
            <div class="glossary-center-list">
                <ul>
                    <li v-for="[k, v] in Object.entries(definitions)" :key="k">
                        <span1 class="term" style="left: 0">{{k + ": "}}</span1>
                        <span class="definition">{{v}}</span>
                        <div class="line"></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import definitions from "../data/definitions.js";
import Dict from "./DictionaryGlossary.vue";

export default {
    name: 'GlossaryCenter',
    components: {
        Dict
    },
    data() {
        return {
            definitions: definitions,
            showGrid: false,
            showList: true,
        }
    }
}
</script>

<style scoped>

    span1 {
        font-weight: bold;
        color: var(--text-primary);
        font-size: 18px;
    }

    span {
        font-weight: normal;
        color: var(--text-primary);
        font-size: 16px;
    }

    h2 {
        color: var(--text-primary);
    }

    h1 {
        color: var(--text-primary);
    }

    label {
        color: var(--text-primary);
        font-size: 1.5em;
        user-select: none;
    }

    .line {
        margin-top: 2px;
        margin-bottom: 2px;
        border-bottom: 1px solid var(--border-color);
        color: var(--text-primary);
    }
    .glossary-center-container {
        height: auto;
    }
    .glossary-center {
        height: 50%;
        width: 90%;
        background-color: var(--main-bg-color);
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        border: 2px solid var(--border-color);
        border-radius: 4px;
        padding: 15px;
        overflow-y: hidden auto;
        overflow-x: hidden auto;
    }
    .glossary-center-memorize {
        background-color: var(--main-bg-color);
        margin: auto;
        margin-top: 50px;
        border: 5px solid var(--border-color);
        border-radius: 4px;
        padding: 15px;
        overflow-y: hidden auto;
        overflow-x: auto;
        height: auto;
        width: 90vw;
    }
    .glossary-center-gridded {
        height: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-gap: 16px;
        align-items: center;
    }
    .glossary-center-container-list {
        padding-bottom: 64px;
        height: 100%;
        text-align: left;
    }
    .glossary-center-list {
        height: 70%;
        width: 80%;
        background-color: var(--main-bg-color);
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        border: 2px solid var(--border-color);
        border-radius: 4px;
        overflow-y: auto;
    }
    .message {
        display: flex;
        padding: 16px;
        border: 1px solid var(--inner-card-secondary);
        border-radius: 4px;
        background-color: var(--inner-box-color);
    }
    .message-content {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 1px;
        align-items: center;
    }
    .term {
        font-weight: bold;
    }
    .definition {
        margin-left: 10px;
    }
    .info-icon {
        color: blue;
        font-size: 16px;
    }
</style>
