<template>
    <div v-if="showBottomNav" class="bottom-nav-container" ref="bottom-nav">
      <div class="bottom-tabs">
        <div v-for="tab in tabsToDisplay" v-bind:key="tab.id">
          <div class="tab-item-container" ref="tab-container">
            <div class="tab-item link" :class="{ 'tab-active' : currentRouteDetail === tab.routeDetail }" @click="goToRoute(tab.routeDetail)">
              <span>{{tab.name}}</span>
            </div>
          </div>
        </div>
      </div>
      <ClippyHelper/>  
    </div>
</template>

<script>
import Vue from 'vue'
import ClippyHelper from './ClippyHelper.vue'
import { MdButton } from 'vue-material/dist/components'
import { mapGetters } from 'vuex'

Vue.use(MdButton);

export default {
    components: {
        ClippyHelper
    },
    name: 'BottomNav',
    data() {
        return {
            showBottomNav: true,
            tabsToDisplay: null
        }
    },
    props: {
        currentRouteDetail: {
            type: String
        },
        activeRoundId: {
            type: String
        },
        gameState: {
            type: Object
        },
        screenWidthPercent: {
            type: Number
        }
    },
    watch: {
        activeRoundId(val) {
            if(val) {
                this.initBottomTabs();
            }
        },
        gameState(val) {
            if(val) {
                this.initBottomTabs();
            }
        },
        currentRouteDetail(val) {
            if(val) {
                this.initBottomTabs();
            }
        },
        screenWidthPercent(val) {
            if(val) {
                this.initBottomTabs();
            }
        }
    },
    computed: mapGetters([
        'getCurrentGameState'
    ]),
    methods: {
        initBottomTabs() {
            const gameState = this.getCurrentGameState;
            let tabWidth = this.screenWidthPercent;

            if(gameState.phase === 'INPUT_SHEET') {
                this.tabsToDisplay = [
                    {
                        name: 'Get Cash',
                        routeDetail: 'get-cash'
                    },
                    {
                        name: 'Spend Cash',
                        routeDetail: 'spend-cash'
                    },
                    {
                        name: 'Go To Market',
                        routeDetail: 'go-to-market'
                    }
                ];
            }

            if(this.currentRouteDetail === 'income-statement' || this.currentRouteDetail === 'balance-sheet' || this.currentRouteDetail === 'cash-flow' || this.currentRouteDetail === 'grades' || this.currentRouteDetail === 'journal-entry-submissions') {
                this.tabsToDisplay = [
                    {
                        name: 'Journal Entry Submissions',
                        routeDetail: 'journal-entry-submissions'
                    },
                    {
                        name: 'Income Statement',
                        routeDetail: 'income-statement'
                    },
                    {
                        name: 'Balance Sheet',
                        routeDetail: 'balance-sheet'
                    },
                    {
                        name: 'Cash Flow',
                        routeDetail: 'cash-flow'
                    },
                ];
            }

            if(this.tabsToDisplay && this.tabsToDisplay.length > 0) {
                tabWidth = this.screenWidthPercent/this.tabsToDisplay.length;
            }

            if(this.currentRouteDetail === 'hasnt-started' || this.currentRouteDetail === 'calc-waiting' || this.currentRouteDetail === 'grade-waiting' || this.currentRouteDetail === 'game-over') {
                this.tabsToDisplay = [];
            } else {
                this.$nextTick(() => {
                    if(this.$refs['tab-container'] && this.$refs['tab-container'].length){
                        for(let el of this.$refs['tab-container']) {
                            el.style.width = tabWidth + "vw";
                        }
                    }
                });
            }
        },
        goToRoute(routeDetail) {
            if(this.$route.params.routeDetail !== routeDetail) {
                this.$router.push({
                    name: 'Game',
                    params: {
                        teamId: this.$route.params.teamId,
                        routeDetail: routeDetail
                    }
                });
            }
        }
    },
    created() {
        this.gameState = this.getCurrentGameState;
        this.initBottomTabs();
    }
}
</script>

<style scoped>


.bottom-nav-container {
  position: fixed;
  bottom: 0px;
  background-color: var(--nav-bar-color);
  height: 64px;
  width: 100%;
  z-index: 10;
}
  
.bottom-tabs {
  color: var(--text-secondary);
  display: flex;
  flex-wrap: wrap;
}

.tab-item-container {
    height: 64px;
    line-height: 64px;
    overflow: hidden;
}

.tab-item {
    height: 100%;
}

.tab-active {
    background-color: var(--bottom-nav-activity-bg);
    cursor: default !important;
    color: black;
}

@media screen and (max-height: 500px){
    .bottom-nav-container {
        position: relative;
    }
}
</style>
