<template>
    <div v-if="isPageReady" class="round-report-container">
        <div class="page-title">
            <span v-if="!isActiveGameRound || showStudentReportDuringCalc">End of </span><span>Round {{roundNum}} Report</span>
        </div>
        <div v-if="!isFlowMode">
            <div v-if="!isActiveGameRound">Market Ended {{marketEndFormatted}}</div>
            <div v-if="isActiveGameRound">Market Closing on {{marketEndFormatted}}</div>
        </div>
        <div class="tax-and-returns">
            <div v-if="isIncomeTaxEnabled()">
                <span class="md-body-1"><b>Income Tax Rate This Round:</b> {{ taxRate.toFixed(2) }}%</span>
            </div>
            <div v-else>
                <span class="md-body-1"><b>Income Tax Rate This Round:</b> None</span>
            </div>
        </div>
        <div class="ar-percentages">
            <div v-if="isAccountsReceivableEnabled() && outstandingPercent > 0" class="md-body-1"><b>Current Round Accounts Receivable Collected:</b> {{ (1 - outstandingPercent) * 100 }}%</div>
            <div v-if="getPreviousRoundAR() > 0" class="md-body-1"><b>Previous Round Accounts Receivable Collected:</b> {{ (1 - badDebtPercent) * 100 }}%</div>
        </div>
        <div class="read-only-assets">
            <div v-if="areStocksEnabled()">
                <h4>Common Stock Sales</h4>
                <div v-if="(currentRoundAssets && currentRoundAssets.stockSales.length) || preloadedStock">
                    <div class="product-cell" v-if="preloadedStock">
                        <div class="cell-detail">Number of Shares Sold: {{preloadedStock.quantityOffered}}</div>
                        <div class="cell-detail">Price per Share: {{preloadedStock.stockPrice | toCurrency}}</div>
                        <div class="cell-detail">Total Proceeds: {{preloadedStock.quantityOffered * preloadedStock.stockPrice | toCurrency}}</div>
                    </div>
                    <div v-if="currentRoundAssets && currentRoundAssets.stockSales && currentRoundAssets.stockSales.length">
                        <div class="product-cell" v-for="stock in currentRoundAssets.stockSales" v-bind:key="stock.id">
                            <div class="cell-detail">Number of Shares Sold: {{stock.quantityOffered}}</div>
                            <div class="cell-detail">Price per Share: {{stock.stockPrice | toCurrency}}</div>
                            <div class="cell-detail">Total Proceeds: {{stock.quantityOffered * stock.stockPrice | toCurrency}}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="product-cell">
                        <div class="cell-detail">N/A</div>
                    </div>
                </div>
            </div>
            <div v-if="areBondsEnabledForGame()">
                <h4>Bond Sales</h4>
                <div v-if="(currentRoundAssets && currentRoundAssets.bondSales.length)">
                    <div v-if="currentRoundAssets && currentRoundAssets.bondSales && currentRoundAssets.bondSales.length">
                        <div class="product-cell" v-for="bond in currentRoundAssets.bondSales" v-bind:key="bond.id">
                            <div class="cell-detail">Number of Bonds Sold: {{bond.numBondsSold}}</div>
                            <div class="cell-detail">Nominal Amount: {{bond.nominalAmount | toCurrency}}</div>
                            <div class="cell-detail">Total Proceeds: {{bond.numBondsSold * bond.nominalAmount | toCurrency}}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="product-cell">
                        <div class="cell-detail">N/A</div>
                    </div>
                </div>
            </div>
            <div v-if="isDebtEnabledForGame()">
                <h4>Debts Borrowed</h4>
                <div v-if="currentRoundAssets">
                    <div v-if="currentRoundAssets.debtsBorrowed && currentRoundAssets.debtsBorrowed.length">
                        <div class="product-cell" v-for="debt in currentRoundAssets.debtsBorrowed" v-bind:key="debt.id">
                            <div class="cell-detail">Borrowing Amount: {{debt.borrowingAmount | toCurrency}}</div>
                            <div class="cell-detail">Rate: {{debt.rate}}</div>
                            <div class="cell-detail">Term: {{debt.term}}</div>
                        </div>
                    </div>
                </div>
                <div v-if="!currentRoundAssets || !currentRoundAssets.debtsBorrowed || !currentRoundAssets.debtsBorrowed.length">
                    <div class="product-cell">
                        <div class="cell-detail">N/A</div>
                    </div>
                </div>
                <h4>Debts Paid</h4>
                <div v-if="currentRoundAssets">
                    <div v-if="currentRoundAssets.debtsRepayed && currentRoundAssets.debtsRepayed.length">
                        <div class="product-cell" v-for="debt in currentRoundAssets.debtsRepayed" v-bind:key="debt.id">
                            <div class="cell-detail">Original Principal: {{debt.originalPrincipal | toCurrency}}</div>
                            <div class="cell-detail">Repayment Amount: {{debt.repaymentAmount | toCurrency}}</div>
                            <div class="cell-detail">Rate: {{debt.rate * 100}}%</div>
                            <div class="cell-detail">Term: {{debt.term}} rounds</div>
                        </div>
                    </div>
                </div>
                <div v-if="!currentRoundAssets || !currentRoundAssets.debtsRepayed || !currentRoundAssets.debtsRepayed.length">
                    <div class="product-cell">
                        <div class="cell-detail">N/A</div>
                    </div>
                </div>
            </div>
            <h4>Land Sales</h4>
            <div v-if="currentRoundAssets">
                <div v-if="currentRoundAssets.landSales && currentRoundAssets.landSales.length">
                    <div class="product-cell" v-for="land in currentRoundAssets.landSales" v-bind:key="land.id">
                        <div class="cell-detail">Original Purchase Price: {{land.originalPurchasePrice | toCurrency}}</div>
                        <div class="cell-detail">Sale Price: {{land.salePrice | toCurrency}}</div>
                    </div>
                </div>
            </div>
            <div v-if="!currentRoundAssets || !currentRoundAssets.landSales || !currentRoundAssets.landSales.length">
                <div class="product-cell">
                    <div class="cell-detail">N/A</div>
                </div>
            </div>
            <h4>Building Sales</h4>
            <div v-if="currentRoundAssets">
                <div v-if="currentRoundAssets.buildingSales && currentRoundAssets.buildingSales.length">
                    <div class="product-cell" v-for="building in currentRoundAssets.buildingSales" v-bind:key="building.id">
                        <div class="cell-detail">Original Purchase Price: {{building.originalPurchasePrice | toCurrency}}</div>
                        <div class="cell-detail">Sale Price: {{building.salePrice | toCurrency}}</div>
                    </div>
                </div>
            </div>
            <div v-if="!currentRoundAssets || !currentRoundAssets.buildingSales || !currentRoundAssets.buildingSales.length">
                <div class="product-cell">
                    <div class="cell-detail">N/A</div>
                </div>
            </div>
            <div v-if="isEquipmentEnabledForGame()">
                <h4>Equipment Sales</h4>
                <div v-if="currentRoundAssets">
                    <div v-if="currentRoundAssets.equipmentSales && currentRoundAssets.equipmentSales.length">
                        <div class="product-cell" v-for="equipment in currentRoundAssets.equipmentSales" v-bind:key="equipment.id">
                            <div class="cell-detail">Original Purchase Price: {{equipment.originalPurchasePrice | toCurrency}}</div>
                            <div class="cell-detail">Sale Price: {{equipment.salePrice | toCurrency}}</div>
                        </div>
                    </div>
                </div>
                <div v-if="!currentRoundAssets || !currentRoundAssets.equipmentSales || !currentRoundAssets.equipmentSales.length">
                    <div class="product-cell">
                        <div class="cell-detail">N/A</div>
                    </div>
                </div>
            </div>
            <h4 v-if="isFurnishingsEnabled()">Store Furnishing Sales</h4>
            <div v-if="currentRoundAssets && isFurnishingsEnabled()">
                <div v-if="currentRoundAssets.storeFurnishingSales && currentRoundAssets.storeFurnishingSales.length">
                    <div class="product-cell" v-for="furnishing in currentRoundAssets.storeFurnishingSales" v-bind:key="furnishing.id">
                        <div class="cell-detail">Original Purchase Price: {{furnishing.originalPurchasePrice | toCurrency}}</div>
                        <div class="cell-detail">Sale Price: {{furnishing.salePrice | toCurrency}}</div>
                    </div>
                </div>
            </div>
            <div v-if="isFurnishingsEnabled() && (!currentRoundAssets || !currentRoundAssets.storeFurnishingSales || !currentRoundAssets.storeFurnishingSales.length)">
                <div class="product-cell">
                    <div class="cell-detail">N/A</div>
                </div>
            </div>
            <h4>Land Purchased</h4>
            <div v-if="currentRoundAssets">
                <div v-if="currentRoundAssets.landPurchases && currentRoundAssets.landPurchases.length">
                    <div class="product-cell" v-for="land in currentRoundAssets.landPurchases" v-bind:key="land.id">
                        <div class="cell-detail">Value: {{land.purchasePrice | toCurrency}}</div>
                        <div class="cell-detail">Building Capacity: {{land.capacity}}</div>
                    </div>
                </div>
            </div>
            <div v-if="!currentRoundAssets || !currentRoundAssets.landPurchases || !currentRoundAssets.landPurchases.length">
                <div class="product-cell">
                    <div class="cell-detail">N/A</div>
                </div>
            </div>
            <h4>Buildings Purchased</h4>
            <div v-if="currentRoundAssets">
                <div v-if="currentRoundAssets.buildingPurchases && currentRoundAssets.buildingPurchases.length">
                    <div class="product-cell" v-for="building in currentRoundAssets.buildingPurchases" v-bind:key="building.id">
                        <div class="cell-detail">Net Book Value: {{building.purchasePrice | toCurrency}}</div>
                        <div class="cell-detail">Condidtion: {{building.type}} </div>
                        <div class="cell-detail">Useful Life: {{building.usefulLife}}</div>
                        <div class="cell-detail">Equipment / Furnishing Capacity: {{building.capacity}}</div>
                        <div class="cell-detail">Depreciation Method: {{building.depreciationMethod}}</div>
                    </div>
                </div>
            </div>
            <div v-if="!currentRoundAssets || !currentRoundAssets.buildingPurchases || !currentRoundAssets.buildingPurchases.length">
                <div class="product-cell">
                    <div class="cell-detail">N/A</div>
                </div>
            </div>
            <h4>Equipment Purchased</h4>
            <div v-if="currentRoundAssets">
                <div v-if="currentRoundAssets.equipmentPurchases && currentRoundAssets.equipmentPurchases.length">
                    <div class="product-cell" v-for="equipment in currentRoundAssets.equipmentPurchases" v-bind:key="equipment.id">
                        <div class="cell-detail">Net Book Value: {{equipment.purchasePrice | toCurrency}}</div>
                        <div class="cell-detail">Condition: {{equipment.type}}</div>
                        <div class="cell-detail">Useful Life: {{equipment.usefulLife}}</div>
                        <div class="cell-detail">Production Capacity: {{equipment.capacity}}</div>
                        <div class="cell-detail">Depreciation Method: {{equipment.depreciationMethod}}</div>
                    </div>
                </div>
            </div>
            <div v-if="!currentRoundAssets || !currentRoundAssets.equipmentPurchases || !currentRoundAssets.equipmentPurchases.length">
                <div class="product-cell">
                    <div class="cell-detail">N/A</div>
                </div>
            </div>
            <h4 v-if="isFurnishingsEnabled()">Store Furnishing Purchases</h4>
            <div v-if="currentRoundAssets && isFurnishingsEnabled()">
                <div v-if="currentRoundAssets.storeFurnishingPurchases && currentRoundAssets.storeFurnishingPurchases.length">
                    <div class="product-cell" v-for="furnishing in currentRoundAssets.storeFurnishingPurchases" v-bind:key="furnishing.id">
                        <div class="cell-detail">Net Book Value: {{furnishing.purchasePrice | toCurrency}}</div>
                        <div class="cell-detail">Condition: {{furnishing.type}}</div>
                        <div class="cell-detail">Useful Life: {{furnishing.usefulLife}}</div>
                        <div class="cell-detail">Inventory Storage Capacity: {{furnishing.capacity}}</div>
                        <div class="cell-detail">Depreciation Method: {{furnishing.depreciationMethod}}</div>
                    </div>
                </div>
            </div>
            <div v-if="isFurnishingsEnabled() && (!currentRoundAssets || !currentRoundAssets.storeFurnishingPurchases || !currentRoundAssets.storeFurnishingPurchases.length)">
                <div class="product-cell">
                    <div class="cell-detail">N/A</div>
                </div>
            </div>

            <div v-if="getIsCurrentActiveRound(activeRoundId) && isPageReady" ref="fixedAssetsLedger">
                <fixed-assets-ledger :hide-useful-life="false"></fixed-assets-ledger>
            </div>
        </div>
        <div>
            <h4>Inventory</h4>
            <div class="inventory-table">
                <div class="md-layout border-bottom">
                    <div class="md-layout-item flex-table-cell-fix border-right"></div>
                    <div class="md-layout-item flex-table-cell-fix border-right bold" v-for="product in getRoundProducts()" v-bind:key="product.id">{{product.name}}</div>
                </div>
                <div class="md-layout border-bottom bold">
                    <div class="md-layout-item flex-table-cell-fix border-right">Round</div>
                    <div class="md-layout-item flex-table-cell-fix md-layout border-right" v-for="product in getRoundProducts()" v-bind:key="product.id">
                        <div class="md-layout-item border-right">Cost</div>
                        <div class="md-layout-item">Units</div>
                    </div>
                </div>
                <div class="md-layout" :class="{ 'border-bottom': i != Object.keys(remainingInventoryCosts).length - 1 }" v-for="(remainingInventoryCost, i) in remainingInventoryCosts" v-bind:key="i">
                    <div class="md-layout-item flex-table-cell-fix border-right">{{ remainingInventoryCost.roundIndex + 1 }}</div>
                    <div class="md-layout-item flex-table-cell-fix md-layout" v-for="(product,i) in getRoundProducts()" v-bind:key="i">
                        <div class="md-layout-item border-right">{{ remainingInventoryCost.inventoryCosts[i].cost | toCurrency }}</div>
                        <div class="md-layout-item border-right">{{ remainingInventoryCost.inventoryCosts[i].remainingUnits }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <h4>Products</h4>
            <div class="product-cell" v-for="gameProduct in getRoundProducts()" v-bind:key="gameProduct.id">
                <div class="cell-title">{{gameProduct.name}}</div>
                <div class="cell-detail">Demand: y = ({{getProductConfig(gameProduct.id).demandSlope}})x + {{getProductConfig(gameProduct.id).demandIntercept}}</div>
                <div class="cell-detail">Unit Cost: {{getProductConfig(gameProduct.id).productionCost | toCurrency}}</div>
                <div v-if="isAdvertisingEnabled()" class="cell-detail">Advertising cost per unit: {{getAdvertisingCostPerUnit() | toCurrency}}</div>
                <div class="cell-detail">Offered: {{totalUnitsOffered(gameProduct.id)}}</div>
                <div class="cell-detail"><span v-if="isEquipmentEnabledForGame()">Units Produced:</span><span v-else>Units Purchased:</span> {{totalUnitsProducedOrPurchased(gameProduct.id)}}</div>

                <div v-if="hasSalesEntries()">
                    <div v-if="totalUnitsSold(gameProduct.id) === 0" class="units-sold-title">Total units sold {{totalUnitsSold(gameProduct.id)}}</div>
                    <div v-if="getSalesEntries(gameProduct.id).length > 0" class="products-sold-table">
                        <div class="md-layout border-bottom">
                            <div class="md-layout-item bold-text">Sold Units</div>
                            <div class="md-layout-item bold-text">Selling Price</div>
                            <div v-if="isAdvertisingEnabled()" class="md-layout-item bold-text">{{getCustomLabelValue("advertisedUnits")}}</div>
                            <div v-if="isSalesReturnsEnabled()" class="md-layout-item bold-text">Returned Units</div>
                        </div>
                        <div v-for="salesEntry in getSalesEntries(gameProduct.id)" v-bind:key="salesEntry.id" class="md-layout">
                            <div v-if="isLegacySalesReport()" class="md-layout-item bold-text">{{ salesEntry.numSold }}/{{salesEntry.numOffered}}</div>
                            <div v-else class="md-layout-item bold-text">
                                <div class="md-layout-item" v-if="salesEntry.inventoryEntries && salesEntry.inventoryEntries.length > 0">Inventory</div>
                                <div class="md-layout-item" v-for="entry in salesEntry.inventoryEntries" :key="entry.cost">{{ entry.quantity }} units @ {{ entry.cost | toCurrency }} cost</div>
                                <div class="md-layout-item" v-if="salesEntry.producedOrPurchasedEntries && salesEntry.producedOrPurchasedEntries.length > 0"><span v-if="isEquipmentEnabledForGame()">Produced</span><span v-else>Purchased</span></div>
                                <div class="md-layout-item" v-for="entry in salesEntry.producedOrPurchasedEntries" :key="entry.cost">{{ entry.quantity }} units @ {{ entry.cost | toCurrency }} cost</div>
                            </div>
                            <div class="md-layout-item bold-text">{{ salesEntry.sellingPrice  | toCurrency }}</div>
                            <div v-if="isAdvertisingEnabled()" class="md-layout-item bold-text">{{ salesEntry.numAdvertised }}</div>
                            <div v-if="isSalesReturnsEnabled()" class="md-layout-item bold-text">{{ Math.floor(salesReturnsPercentage * salesEntry.numSold) }} units @ {{ getReturnedUnitsCost(salesEntry.gameProductId) | toCurrency }} cost</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="units-sold-title">
                        <span v-if="hasCalculatedMarket">Round Complete, No Elections Made</span>
                        <span v-else>Round Not Complete</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import FixedAssetsLedger from './FixedAssetsLedger'
    import {mapGetters, mapActions} from "vuex";
    import { UserTypeEnum } from "temporary-api-types/apiTypes";
    export default {
        name: "RoundReport",
        components: {
            FixedAssetsLedger
        },
        data() {
            return {
                roundNum: null,
                marketEndFormatted: null,
                round: null,
                currentRoundAssets: null,
                isActiveGameRound: false,
                isFlowMode: false,
                showStudentReportDuringCalc: false,
                isPageReady: false,
                hasCalculatedMarket: false,
                remainingInventoryCosts: null,
                currentLands: null,
                currentBuildings: null,
                currentEquipments: null,
                currentFurnishings: null,
                currentDebts: null,
                preloadedStock: null,
                currentBonds: null
            }
        },
        computed: {
            ...mapGetters([
                'getSalesReport',
                'getGameProduct',
                'getCurrentRoundConfig',
                'getCurrentCourse',
                'getFormattedDate',
                'getCurrentRoundElections',
                'getCurrentGameState',
                'getCurrentAssets',
                'getIsCurrentActiveRound',
                'getGameLabels'
            ]),
            salesReturnsPercentage() {
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                const salesReturnsForTeam = this.selectedTeam.semester.teamSalesReturns[roundIndex].find((roundSalesReturn) => roundSalesReturn.teamId === this.selectedTeam.id);
                return salesReturnsForTeam.assignedSalesReturnsPercentage;
            },
            taxRate() {
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                return gameConfig.roundFeatures.incomeTax[roundIndex];
            },
            outstandingPercent() {
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                return gameConfig.roundFeatures.accountsReceivable[roundIndex].outstandingPercent;
            },
            badDebtPercent() {
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                return gameConfig.roundFeatures.accountsReceivable[roundIndex].badDebtPercent;
            }
        },
        props: {
            selectedTeam: {
                type: Object
            },
            activeRoundId: {
                type: String
            },
        },
        methods: {
            ...mapActions([
                'fetchSalesReport',
                'fetchElectionByRoundId',
                'fetchRemainingInventoryCosts',
                'fetchCurrentAssets'
            ]),
            getCustomLabelValue(key) {
                if(this.getGameLabels) {
                    const found = this.getGameLabels.find(label => label.key === key);
                    return found ? found.value : "";
                }
                return "";
            },
            getRoundProducts() {
                if(!this.round) {
                    return [];
                }
                const gameProductIds = this.round.products.map((productConfig) => productConfig.gameProductId)
                return gameProductIds.map((gameProductId) => this.getGameProduct(gameProductId))
            },
            getProductConfig(gameProductId) {
                return this.round.products.find((_productConfig) => _productConfig.gameProductId === gameProductId);
            },
            getAdvertisingCostPerUnit() {
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                return gameConfig.roundFeatures.advertising[roundIndex].cost;
            },
            totalUnitsSold(gameProductId) {
                return this.getSalesEntries(gameProductId)
                    .map((_entry) => _entry.numSold)
                    .reduce((total, numSold) => {
                        total += numSold;
                        return total;
                    }, 0);
            },
            totalUnitsOffered(gameProductId) {
                if(!this.currentRoundAssets || !this.currentRoundAssets.productElections){
                    return 'Not elected';
                }

                const totalUnits = this.currentRoundAssets.productElections.filter((productElection) => productElection.gameProductId === gameProductId)
                    .map((_election) => _election.quantityOffered)
                    .reduce((total, numOffered) => {
                        total += numOffered;
                        return total;
                    }, 0);
                return `${totalUnits} Units`;
            },
            totalUnitsProducedOrPurchased(gameProductId) {
                return this.getSalesReport.reduce((total, salesEntry) => {
                    if(salesEntry.team.id === this.selectedTeam.id && salesEntry.gameProduct.id === gameProductId && salesEntry.isInventory === false) {
                        total += salesEntry.unitsOffered
                    }
                    return total;
                }, 0);
            },
            groupBy(list, key) {
                return list.reduce(function(result, listItem) {
                    (result[listItem[key]] = result[listItem[key]] || []).push(listItem);
                    return result;
                }, {});
            },
            hasSalesEntries() {
                return this.getSalesReport && this.getSalesReport.filter((_entry) => _entry.team.id === this.selectedTeam.id).length > 0;
            },
            isLegacySalesReport() {
                for(let salesEntry of this.getSalesReport) {
                    if(salesEntry.cost === null) {
                        return true;
                    }
                }
                return false;
            },
            getSalesEntries(gameProductId) {
                const productEntries = this.getSalesReport.filter((_entry) => _entry.team.id === this.selectedTeam.id && _entry.gameProduct.id === gameProductId);
                const entriesByPrice = this.groupBy(productEntries, 'offerPrice');
                const prices = Object.keys(entriesByPrice).sort((a, b) => a - b);
                return  prices.map((price) => {
                    const numSold = entriesByPrice[price].map((_entry) => _entry.unitsSold)
                        .reduce((total, _numSold) => {
                            total += _numSold;
                            return total;
                        }, 0);
                    const numOffered = entriesByPrice[price].map((_entry) => _entry.unitsOffered)
                        .reduce((total, _numOffered) => {
                            total += _numOffered;
                            return total;
                        }, 0);
                    const numAdvertised = entriesByPrice[price].filter((_entry) => _entry.isAdvertised)
                        .map((_entry) => _entry.unitsOffered)
                        .reduce((total, _numAdvertised) => {
                            total += _numAdvertised;
                            return total;
                        }, 0);
                    const inventoryEntries = entriesByPrice[price].filter((_entry) => _entry.isInventory === true && _entry.cost !== null)
                        .reduce((result, _entry) => {
                            const cost = _entry.cost;
                            const index = result.findIndex(_result => _result.cost === cost);
                            if(index !== -1) {
                                result[index].quantity += _entry.unitsSold;
                            } else {
                                result.push({
                                    quantity: _entry.unitsSold,
                                    cost
                                });
                            }
                            return result;
                        }, []);
                    const producedOrPurchasedEntries = entriesByPrice[price].filter((_entry) => _entry.isInventory === false)
                        .reduce((result, _entry) => {
                            const cost = _entry.cost;
                            const index = result.findIndex(_result => _result.cost === cost);
                            if(index !== -1) {
                                result[index].quantity += _entry.unitsSold;
                            } else {
                                result.push({
                                    quantity: _entry.unitsSold,
                                    cost
                                });
                            }
                            return result;
                        }, []);
                    return {
                        gameProductId,
                        numSold,
                        numOffered,
                        sellingPrice: parseFloat(price),
                        numAdvertised,
                        inventoryEntries,
                        producedOrPurchasedEntries
                    };
                });
            },
            getReturnedUnitsCost(gameProductId) {
                let cost = 0;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                const currentCourseRounds = courseRounds.slice(0, roundIndex + 1);

                for(let i = currentCourseRounds.length - 1; i >= 0; i--) {
                    const found = courseRounds[i].products.find((_product) => _product.gameProductId === gameProductId);
                    if(found) {
                        cost = found.productionCost;
                        break;
                    }
                }

                return cost;
            },
            getPreviousRoundAR() {
                const teamEntries = this.getSalesReport.filter((_entry) => _entry.team.id === this.selectedTeam.id);
                if(teamEntries && teamEntries.length > 0) {
                    return teamEntries[0].previousRoundAR;
                } else {
                    return 0;
                }
            },
            isAccountsReceivableEnabled() {
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                const currentCourseRounds = courseRounds.slice(0, roundIndex + 1);

                for(let i = 0; i < currentCourseRounds.length; i++) {
                    const outstandingPercent = gameConfig.roundFeatures.accountsReceivable[i].outstandingPercent;
                    if(outstandingPercent > 0) {
                        return true;
                    }
                }
                return false;
            },
            async init() {
                if(this.selectedTeam && this.activeRoundId) {
                    await this.fetchSalesReport({
                        teamId: this.selectedTeam.id,
                        roundConfigId: this.activeRoundId
                    });
                }

                this.getCurrentGameState.phase === 'INPUT_SHEET' && this.getCurrentGameState.roundId === this.activeRoundId ? this.isActiveGameRound = true : this.isActiveGameRound = false;
                if(this.getCurrentGameState.phase === 'CALC_SHEETS' && this.getUserType === UserTypeEnum.Student) {
                    this.showStudentReportDuringCalc = true;
                }
                this.round = this.getCurrentCourse.rounds.find((_round) => _round.id === this.activeRoundId);
                this.roundNum = this.getCurrentCourse.rounds.map((_round) => _round.id).indexOf(this.activeRoundId) + 1;

                const roundTimings = JSON.parse(JSON.stringify(this.selectedTeam.semester.roundTimings));
                const roundTiming = roundTimings[this.roundNum - 1];

                this.hasCalculatedMarket = roundTiming.hasCalculatedMarket;

                if(this.selectedTeam.semester.useFlowMode) {
                    this.isFlowMode = true;
                }
                this.marketEndFormatted = this.getFormattedDate(roundTiming.electionsDeadline, this.selectedTeam.semester.timezone);
                await this.initReadOnlyAssets();

                this.remainingInventoryCosts = await this.fetchRemainingInventoryCosts({
                    roundConfigId: this.activeRoundId,
                    teamId: this.selectedTeam.id
                });

                await this.fetchCurrentAssets({
                    teamId: this.$route.params.teamId
                });

                this.isPageReady = true;
            },
            async initReadOnlyAssets() {
                this.currentRoundAssets = await this.fetchElectionByRoundId({
                    roundConfigId: this.activeRoundId,
                    teamId: this.$route.params.teamId
                });

                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                if(roundIndex === 0) {
                  const gameConfig = this.selectedTeam.semester.gameConfig;
                  const teamStartingStockPrice = gameConfig.stockConfig.teamStartingStockPrice;
                  const initialOfferingNumShares = gameConfig.stockConfig.initialOfferingNumShares;
                  this.preloadedStock = {
                    stockPrice: teamStartingStockPrice,
                    quantityOffered: initialOfferingNumShares
                  };
                } else {
                    this.preloadedStock = null;
                }
            },
            isSalesReturnsEnabled() {
                if(this.selectedTeam) {
                    const gameConfig = this.selectedTeam.semester.gameConfig;
                    const courseRounds = this.getCurrentCourse.rounds;
                    const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                    return !!gameConfig.roundFeatures.salesReturns[roundIndex];
                }
            },
            isIncomeTaxEnabled() {
                if(this.selectedTeam) {
                    const gameConfig = this.selectedTeam.semester.gameConfig;
                    const courseRounds = this.getCurrentCourse.rounds;
                    const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                    const taxConfig = gameConfig.roundFeatures.incomeTax[roundIndex];
                    return taxConfig > 0;
                }
            },
            isAdvertisingEnabled() {
                if(this.selectedTeam) {
                    const gameConfig = this.selectedTeam.semester.gameConfig;
                    const courseRounds = this.getCurrentCourse.rounds;
                    const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                    const adConfig = gameConfig.roundFeatures.advertising[roundIndex];
                    return adConfig.cost > 0 || adConfig.benefit > 0;
                }
            },
            isFurnishingsEnabled() {
                const courseRounds = this.getCurrentCourse.rounds;
                return courseRounds.reduce((result, round) => {
                    if (round.storeFurnishings.length > 0) {
                        return true;
                    }
                    return result;
                }, false);
            },
            areStocksEnabled() {
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                return courseRounds[roundIndex].isStockTradingEnabled || (roundIndex === 0 && this.preloadedStock);
            },
            isDebtEnabledForGame() {
                const roundConfig = this.getCurrentRoundConfig;
                if(!roundConfig) {
                    return true;
                }
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;

                return courseRounds.reduce((result, round, index) => {
                    const borrowingCap = gameConfig.roundFeatures.borrowingCaps[index];
                    if ((roundConfig.debts.length > 0) && (borrowingCap.debtRoundBorrowCap === -1 ||  borrowingCap.debtRoundBorrowCap > 0) && (borrowingCap.totalPrincipalCap === -1 ||  borrowingCap.totalPrincipalCap > 0)) {
                        return true;
                    }
                    return result;
                }, false);
            },
            areBondsEnabledForGame() {
                const roundConfig = this.getCurrentRoundConfig;
                if(!roundConfig) {
                    return true;
                }
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;

                return courseRounds.reduce((result, round, index) => {
                    const borrowingCap = gameConfig.roundFeatures.borrowingCaps[index];
                    const bondOptions = roundConfig.bonds.bondOptions;
                    if (bondOptions.length > 0 && borrowingCap.bondRoundBorrowCap > 0 && borrowingCap.totalPrincipalCap > 0) {
                        return true;
                    }
                    return result;
                }, false);
            },
            isEquipmentEnabledForGame() {
                const courseRounds = this.getCurrentCourse.rounds;
                for(let round of courseRounds) {
                    if(round.equipments.length > 0) {
                        return true;
                    }
                }
                return false;
            }
        },
        watch: {
            async selectedTeam(val) {
                if(val) {
                    await this.init();
                }
            },
            async activeRoundId(val) {
                if(val) {
                    await this.init();
                }
            },
        },
        async created() {
            if(this.selectedTeam && this.activeRoundId) {
                await this.init();
            }
        }
    }
</script>

<style scoped>
    h4 {
        color: var(--text-primary);
    }
    span {
        color: var(--text-primary);
    }
    div {
        color: var(--text-primary);
    }
    .tax-and-returns {
        text-align: center;
        margin-bottom: 10px;
    }
    .ar-percentages {
        text-align: center;
        margin-bottom: 10px;
    }
    .product-cell {
        max-width: 425px;
        border: solid 1px var(--border-color);
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
    }

    .cell-title {
        text-align: left;
        font-weight: bold;
    }

    .cell-detail {
        text-align: left;
        margin-left: 30px;
    }

    .units-sold-title {
        text-align: left;
        font-weight: bold;
        margin-left: 15px;
        margin-top: 15px;
    }

    .page-title {
        font-weight: bold;
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .round-report-container {
        padding-bottom: 64px;
    }

    .products-sold-table {
        padding-top: 20px;
    }

    .bold-text {
        font-weight: bold;
    }

    .last-item {
        padding-bottom: 50px;
    }

    .inventory-table {
        max-width: 425px;
        border-top: solid 1px black;
        border-left: solid 1px black;
        border-bottom: solid 1px black;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
    }

    .border-right {
        border-right: 1px solid black;
    }

    .border-bottom {
        border-bottom: 1px solid black;
    }

    .bold {
        font-weight: bold;
    }

    .flex-table-cell-fix {
        flex-grow: 1;
        flex-basis: 0;
        min-width: 0;
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        height: 5px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.096)    }
</style>
