<template>
    <div>
        <div v-if="showSearch" class="search-type">
            <div>Select Search Field</div>
            <md-radio v-for="searchType in searchTypes" :key="searchType.id" v-model="selectedSearchType" :value="searchType">{{searchType.text}}</md-radio>
            <md-field md-clearable class="search-bar md-toolbar-section-end">
                <md-input :placeholder="getPlaceholderLabel()" v-model="search" @input="searchOnTable" />
            </md-field>
        </div>
        <div class="license-table">
            <md-button v-if="actionButtonCallback" class="md-button button-theme md-icon-button md-dense md-raised md-primary custom-fab" @click="performActionButtonCallback">
                <md-icon>add</md-icon>
            </md-button>
            <md-table v-model="searched" md-sort="name" md-card>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell class="license-table-cell" md-label="Email">
                        <div v-if="item.currentLicense">{{ item.currentLicense.user.firstname }} {{ item.currentLicense.user.lastname }}</div>
                        <div>{{ item.email }}</div>
                        <div v-if="item.currentLicense">
                            <a class="use-game-link" v-if="licenseType !== UserTypeEnum.Admin && isFirstUniqueEmailLicenseForCurrentLicenses(item.currentLicense)" @click="authenticateUserForLicense(item.currentLicense ? item.currentLicense : item.pendingLicense)">Use Game</a>
                        </div>
                        <div>
                            <md-icon v-if="item.licenseStatus === 'VALID'" class="table-icon-success fa fa-check-square"></md-icon>
                            <md-icon v-if="item.licenseStatus !== 'VALID'" class="table-icon-warning fa fa-exclamation-triangle"></md-icon>
                        </div>
                        <div v-if="item.warningNote">{{ item.warningNote }}</div>
                    </md-table-cell>

                    <md-table-cell v-if="item.currentLicense" class="license-table-cell" :colspan="item.pendingLicense ? '1' : '2'">
                        <md-table class="current-license-table" :class="{ 'centered' : !item.pendingLicense }">
                            <md-table-row>
                                <md-table-cell>Current License</md-table-cell>
                                <md-table-cell>
                                    <md-button v-if="item.licenseStatus === 'VALID' && licenseType !== UserTypeEnum.Admin" class="md-raised md-default" @click="showEditLicenseForm(item.currentLicense, 'non-warning')">Edit</md-button>
                                    <md-button v-if="item.licenseStatus !== 'VALID'" class="md-raised theme-btn" @click="initiateNewLicense(item.currentLicense, 'non-warning')">Replace License</md-button>
                                    <md-button v-if="item.licenseStatus === 'VALID'" class="md-raised md-accent" @click="revokeLicense(item.currentLicense, false)">Revoke License</md-button>
                                </md-table-cell>
                            </md-table-row>
                            <md-table-row>
                              <md-table-cell>Issued On</md-table-cell>
                              <md-table-cell>{{ getFormattedDate(parseInt(item.currentLicense.createdAt)/1000) }}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType === UserTypeEnum.Professor">
                                <md-table-cell>License Timeframe</md-table-cell>
                                <md-table-cell>
                                  <div>{{ getFormattedDate(item.currentLicense.licenseStart) }}</div>
                                  <div>to</div>
                                  <div>{{ getFormattedDate(item.currentLicense.licenseEnd) }}</div>
                                </md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType !== UserTypeEnum.Admin">
                                <md-table-cell>School</md-table-cell>
                                <md-table-cell>{{ item.currentLicense.school }}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType !== UserTypeEnum.Admin">
                                <md-table-cell>License Fee</md-table-cell>
                                <md-table-cell>{{ item.currentLicense.price | toCurrency }}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType !== UserTypeEnum.Admin">
                                <md-table-cell>Student Fee</md-table-cell>
                                <md-table-cell>{{ item.currentLicense.studentPrice | toCurrency }}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType !== UserTypeEnum.Admin">
                                <md-table-cell>Allowed Games</md-table-cell>
                                <md-table-cell>{{ item.currentLicense.numAllowedGames }}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType === UserTypeEnum.Student">
                                <md-table-cell>Semester Id</md-table-cell>
                                <md-table-cell>{{ item.currentLicense.semesterId }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                    </md-table-cell>
                    <md-table-cell v-else class="license-table-cell">
                        <md-table class="current-license-table">
                            <md-table-row>
                                <md-table-cell>Current License</md-table-cell>
                                <md-table-cell>
                                    <!-- <md-button class="md-raised md-default" @click="showEditLicenseForm(item, 'non-warning')">Edit</md-button>
                                    <md-button class="md-raised md-default" @click="showEditLicenseForm(item, 'non-warning')">Send New License Invite</md-button> -->
                                </md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>
                                </md-table-cell>
                                <md-table-cell>
                                </md-table-cell>
                                <!-- <md-table-cell>License Confirmed and Valid Until</md-table-cell>
                                <md-table-cell>{{ getFormattedDate(item.currentLicense.licenseEnd) }}</md-table-cell> -->
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>
                                </md-table-cell>
                                <md-table-cell>
                                </md-table-cell>
                                <!-- <md-table-cell>School</md-table-cell>
                                <md-table-cell>{{ item.currentLicense.school }}</md-table-cell> -->
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>
                                </md-table-cell>
                                <md-table-cell>
                                </md-table-cell>
                                <!-- <md-table-cell>License Fee</md-table-cell>
                                <md-table-cell>{{ item.currentLicense.price | toCurrency }}</md-table-cell> -->
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>
                                </md-table-cell>
                                <md-table-cell>
                                </md-table-cell>
                                <!-- <md-table-cell>Student Fee</md-table-cell>
                                <md-table-cell>{{ item.currentLicense.studentPrice | toCurrency }}</md-table-cell> -->
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell>
                                </md-table-cell>
                                <md-table-cell>
                                </md-table-cell>
                                <!-- <md-table-cell>Allowed Games</md-table-cell>
                                <md-table-cell>{{ item.currentLicense.numAllowedGames }}</md-table-cell> -->
                            </md-table-row>
                        </md-table>
                    </md-table-cell>
                    <md-table-cell v-if="item.pendingLicense" class="license-table-cell">
                        <md-table class="pending-license-table">
                            <md-table-row>
                                <md-table-cell>Pending License</md-table-cell>
                                <md-table-cell>
                                    <md-button class="md-raised theme-btn" @click="initiateNewLicense(item.pendingLicense, 'non-warning')">Replace License</md-button>
                                </md-table-cell>
                            </md-table-row>
                            <md-table-row>
                              <md-table-cell>Issued On</md-table-cell>
                              <md-table-cell>{{ getFormattedDate(parseInt(item.pendingLicense.createdAt)/1000) }}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType === UserTypeEnum.Professor">
                                <md-table-cell>License Timeframe</md-table-cell>
                                <md-table-cell>
                                  <div>{{ getFormattedDate(item.pendingLicense.licenseStart) }}</div>
                                  <div>to</div>
                                  <div>{{ getFormattedDate(item.pendingLicense.licenseEnd) }}</div>
                                </md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType !== UserTypeEnum.Admin">
                                <md-table-cell>School</md-table-cell>
                                <md-table-cell>{{ item.pendingLicense.school }}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType !== UserTypeEnum.Admin && licenseType !== UserTypeEnum.Student">
                                <md-table-cell>License Fee</md-table-cell>
                                <md-table-cell>{{ item.pendingLicense.price | toCurrency }}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType !== UserTypeEnum.Admin">
                                <md-table-cell>Student Fee</md-table-cell>
                                <md-table-cell>{{ item.pendingLicense.studentPrice | toCurrency }}</md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="licenseType !== UserTypeEnum.Admin && licenseType !== UserTypeEnum.Student">
                                <md-table-cell>Allowed Games</md-table-cell>
                                <md-table-cell>{{ item.pendingLicense.numAllowedGames }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                    </md-table-cell>
                </md-table-row>
            </md-table>
        </div>
        <md-dialog class="modal revoke-license-modal" :md-active="showRevokeOptionsModal">
            <md-dialog-title class="modal-title">Revoke License</md-dialog-title>

            <div class="md-layout">
                <div class="md-layout-item"></div>
                <div class="md-layout-item btn-group">
                    <md-button class="md-accent md-raised" @click="revokeLicense(this.selectedLicense, false)">Revoke</md-button>
                    <md-button class="md-accent md-raised" @click="revokeLicense(this.selectedLicense, true)">Revoke and Ban</md-button>
                </div>
                <div class="md-layout-item"></div>
            </div>

            <md-dialog-actions>
                <md-button class="md-accent" @click="resetEditLicenseData()">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-dialog class="modal edit-professor-license-modal" :md-active="showLicenseFormModal">
            <md-dialog-title class="modal-title">Edit License</md-dialog-title>

            <LicenseForm :form-type="'edit'" :success-cb="licenseTableSuccessCb" :is-warning-license="isWarningLicense" :non-editable-fields="nonEditableFields" :license="selectedLicense" :license-type="UserTypeEnum.Professor"></LicenseForm>
            <md-dialog-actions>
                <md-button class="md-accent" @click="showLicenseFormModal = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
        <span class="error" v-if="serverError">{{serverError}}</span>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { MdTable } from 'vue-material/dist/components'
import { MdRipple } from 'vue-material/dist/components'
import LicenseForm from './LicenseForm'
import { DateTime } from 'luxon'
import { UserTypeEnum } from "temporary-api-types/apiTypes";
Vue.use(MdTable);
Vue.use(MdRipple);
export default {
    name: 'LicenseTable',
    components: {
        LicenseForm
	},
    data() {
        return {
            tableReady: false,
            search: null,
            searched: [],
            searchTypes: [
                {
                    property: "all",
                    text: "All"
                },
                {
                    property: "firstname",
                    text: "First Name"
                },
                {
                    property: "lastname",
                    text: "Last Name"
                },
                {
                    property: "email",
                    text: "Email"
                }
            ],
            selectedSearchType: null,
            showRevokeOptionsModal: false,
            showLicenseFormModal: false,
            selectedLicense: null,
            nonEditableFields: null,
            isWarningLicense: false,
            serverError: null,
            url: process.env.VUE_APP_LAMBDA_URL,
            UserTypeEnum
        }
    },
    props: {
        licenses: {
            type: Array
        },
        showSearch: {
            type: Boolean
        },
        licenseType: {
            type: String
        },
        actionButtonCallback: {
            type: Function
        },
        successCb: {
            type: Function
        }
    },
    computed: {
        ...mapGetters([
            'getFormattedDate',
            'getUserType',
            'getSemesterList',
            'getAccessToken'
        ])
    },
    watch: {
        licenses(val) {
            if(val) {
                this.searched = val;
            }
        },
        selectedSearchType(val) {
            if(val) {
                this.searchOnTable();
            }
        }
    },
    methods: {
        ...mapActions([
            'adminCreateOrUpdateLicense',
            'fetchSemesterList',
            'setAdminUserToken',
            'getStudentTeam',
            'configureUserType',
            'configureUserEmail',
            'fetchUserByEmail'
        ]),
        formatCreatedDate(date) {
            DateTime.from
        },
        async authenticateUserForLicense(license) {
            const userEmail = license.user.email;
            const data = {
                authType: 'CUSTOM_AUTH',
                authParameters: {
                    USERNAME: userEmail
                },
                authToken: this.getAccessToken
            };
            try {
                const response = await fetch(`${this.url}/authentication`, {
                    method: 'POST',
                    mode: 'cors',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Request-Method': 'POST'
                    },
                    body: JSON.stringify(data)
                });
                const responseJson = await response.json();          
                if(response.ok) {
                    await this.configureUserEmail(userEmail);
                    const adminUserIdToken = responseJson.input["AuthenticationResult"]["IdToken"];
                    await this.setAdminUserToken(adminUserIdToken);
                    await this.goToUserSemester();
                    return;
                } else {
                    return;
                }
            } catch(err) {
                return err;
            }
        },
        async goToUserSemester() {
            await this.configureUserType(this.licenseType);
            
            try {
                await this.fetchSemesterList();
            } catch(err) {
                if(err.graphQLErrors && err.graphQLErrors.length) {
                    const errorMessage = err.graphQLErrors[0].message;
                    if(errorMessage === "user license is revoked, no repurchase") {
                    this.serverError = "This license has been revoked and flagged. Please contact Accountonomics support for more details.";
                    } 
                    if(errorMessage === "user license is revoked") {
                    this.serverError = "Invalid license. Please try purchasing a new one";
                    }
                } 
                return;
            }
            const semesterList = this.getSemesterList;
            if(semesterList.length === 1 && semesterList[0].teams.length === 1 && this.getUserType === UserTypeEnum.Student) {
                const semesterId = this.getSemesterList[0].id
                const teamId = this.getSemesterList[0].teams[0].id
                await this.getStudentTeam({teamId});
                this.$router.push({
                    name: 'GameIndex',
                    params: {
                        teamId,
                        semesterId
                    }
                });
            } else {
                this.$router.push({
                    name: 'Semester'
                });
            }
        },
        isFirstUniqueEmailLicenseForCurrentLicenses(license) {
            const email = license.email;
            const filteredLicenses = this.licenses.filter((_license) => {
                return _license.email === email && !!_license.currentLicense
            }).map(_license => _license.currentLicense);
            const index = filteredLicenses.findIndex(_license => _license.id === license.id);

            return index === 0;
        },
        async licenseTableSuccessCb() {
            this.showLicenseFormModal = false;
            await this.successCb();
        },
        performActionButtonCallback() {
            this.actionButtonCallback();
        },
        getPlaceholderLabel() {
            return `Search by ${this.selectedSearchType.text}...`;
        },
        resetEditLicenseData() {
            this.showRevokeOptionsModal = false;
            this.selectedLicense = null;
        },
        async revokeLicense(license, isBanned) {
            const nowSeconds = DateTime.local().toSeconds();
            try {
                await this.adminCreateOrUpdateLicense({ 
                    email: license.user.email,
                    licenseType: this.licenseType,
                    licenseInput: {
                        id: license.id,
                        numAllowedGames: license.numAllowedGames,
                        price: license.price,
                        studentPrice: license.studentPrice,
                        revokedTimestamp: nowSeconds,
                        isBanned: isBanned
                    }
                });
                await this.successCb();
                this.resetEditLicenseData();
            } catch(err) {
                return err;
            }
        },
        searchByName(items, term, property) {
            let returnedItems = items;
            if(term) {
                if(property === 'all') {
                    const searchableUserProps = ['email', 'firstname', 'lastname'];
                    returnedItems = items.filter((item) => {
                        return searchableUserProps.some((prop) => {
                          if(prop === 'email') {
                              if(this.toLower(item.email).includes(this.toLower(term))) {
                                  return true;
                              }
                          }
                          if(item.currentLicense) {
                              const fullnameSearch = item.currentLicense.user.firstname + item.currentLicense.user.lastname;
                              if(this.toLower(fullnameSearch).includes(this.toLower(term.replace(/\s/g,'')))) {
                                  return true;
                              }
                          }
                          return false;
                        });
                    });
                } else {
                    returnedItems = items.filter((item) => {
                        if(property === 'email') {
                            if(this.toLower(item.email).includes(this.toLower(term))) {
                                return true;
                            }
                        } else {
                            if(item.currentLicense) {
                                if(this.toLower(item.currentLicense.user[property]).includes(this.toLower(term.replace(/\s/g,'')))) {
                                    return true;
                                }
                            }
                            return false;
                        }
                        return false;
                    });
                }
            }
            return returnedItems;
        },
        searchOnTable() {
            if(this.licenses) {
                this.searched = this.searchByName(this.licenses, this.search, this.selectedSearchType.property);
            }
        },
        async initiateNewLicense(license, cellType) {
          if(this.licenseType === UserTypeEnum.Admin) {
            // create new admin license with email from args.license
            await this.createNewAdminLicense(license);
            await this.successCb();
            this.resetEditLicenseData();
          } else {
            this.showEditLicenseForm(license, cellType);
          }
        },
        async createNewAdminLicense (oldLicense) {
          await this.adminCreateOrUpdateLicense({
            email: oldLicense.email,
            licenseType: this.licenseType,
            licenseInput: {
              numAllowedGames: oldLicense.numAllowedGames,
              price: oldLicense.price,
              studentPrice: oldLicense.studentPrice,
              revokedTimestamp: null,
              isBanned: false
            }
          });
          await this.sendLicenseToExistingUser(oldLicense.email);
        },
        async sendLicenseToExistingUser(email) {
            const user = await this.fetchUserByEmail({ email });
            const data = {
                email,
                groupName: this.licenseType
            };
            if(user) {
                try {
                    const response = await fetch(`${this.url}/sendLicenseInfo`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Request-Method': 'POST'
                        },
                        body: JSON.stringify(data)
                    });
                    if(!response.ok) {
                        this.serverError = "Something went wrong. Please try again";
                        this.httpWait = false;
                        return Promise.reject(this.serverError);
                    }
                    if(this.successCb) {
                        await this.successCb();
                        this.httpWait = false;
                    }
                    this.httpWait = false;
                    return;
                } catch(err) {
                    this.serverError = "Something went wrong. Please try again";
                    this.httpWait = false;
                    return Promise.reject(err);
                }
            } else {
                try {
                    const response = await fetch(`${this.url}/sendLicenseInvite`, {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Request-Method': 'POST'
                        },
                        body: JSON.stringify(data)
                    });
                    if(!response.ok) {
                        this.serverError = "Something went wrong. Please try again";
                        this.httpWait = false;
                        return Promise.reject(this.serverError);
                    }
                    if(this.successCb) {
                        await this.successCb();
                        this.httpWait = false;
                    }
                    this.httpWait = false;
                    return;
                } catch(err) {
                    this.serverError = "Something went wrong. Please try again";
                    this.httpWait = false;
                    return Promise.reject(err);
                }
            }
        },
        showEditLicenseForm(license, cellType) {
            this.selectedLicense = license;
            this.showLicenseFormModal = true;
            if(cellType === 'non-warning') {
                this.nonEditableFields = ['firstName', 'lastName', 'email'];
                this.isWarningLicense = false;
            } else if(cellType === 'warning') {
                this.nonEditableFields = ['firstName', 'lastName', 'email'];
                this.isWarningLicense = true;
            }
        },
        showRevokeOptions(license) {
            this.selectedLicense = license;
            this.showRevokeOptionsModal = true;
        },
        toLower(text) {
            return text.toString().toLowerCase();
        },
        async unrevokeLicense(license) {
            this.selectedLicense = license;
            try {
                await this.adminCreateOrUpdateLicense({ 
                    email: this.selectedLicense.user.email,
                    licenseType: this.licenseType,
                    licenseInput: {
                        id: this.selectedLicense.id,
                        numAllowedGames: this.selectedLicense.numAllowedGames,
                        price: this.selectedLicense.price,
                        studentPrice: this.selectedLicense.studentPrice,
                        revokedTimestamp: null,
                        isBanned: false
                    }
                });
                await this.successCb();
                this.resetEditLicenseData();
            } catch(err) {
                return err;
            }
        },
        getTimestamp(isoString) {
            return DateTime.fromISO(isoString).ts;
        }
    },
    created() {
        this.selectedSearchType = this.searchTypes[0];
    }
}
</script>

<style scoped>
.license-table {
    position: relative;
}
.license-table-cell {
    text-align: left;
}
.search-type {
    text-align: left;
}
.search-bar {
    max-width: 500px;
}
.custom-fab {
    position: absolute;
    right: 0;
    top: -17px;
    z-index: 10;
}
.modal {
    overflow-y: auto;
    width: 70vw;
}
.modal-title {
    text-align: center;
}
.btn-group {
    padding: 1em;
    text-align: center;
}
.error {
	color: var(--warning-text-color);
}
.current-license-table {
    width: 600px;
    background: whitesmoke;
}
.centered {
    margin-left: auto;
    margin-right: auto;
}
.pending-license-table {
    width: 600px;
    background: whitesmoke;
}
.table-icon-success {
    color: var(--success-bg-color) !important;
    width: 75px;
    min-width: 75px;
    height: 75px;
    font-size: 75px!important;
}
.table-icon-warning {
    color: #ffc933 !important;
    width: 75px;
    min-width: 75px;
    height: 75px;
    font-size: 75px!important;
}
.theme-btn {
  background-color: var(--btn-primary-color) !important;;
}
.use-game-link {
  cursor: pointer;
}
</style>
