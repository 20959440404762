<template>
<div class="md-layout">
    <div class="productBlock" v-if="getCurrentRoundConfig.products && getCurrentRoundConfig.products.length > 0 && currentProductElections">
        <div class="wrapper">
            <md-card v-for="(productConfig, productIndex) in getCurrentRoundConfig.products" v-bind:key="productConfig.id" :class="{ 'selected-border' : isProductLocallyElected(productConfig.gameProductId) }">
                <md-card-header>
                    <div class="md-title">{{getGameProduct(productConfig.gameProductId).name}}</div>
                </md-card-header>
                <md-card-content>
                    <div class="product-config">
                        <div class="config-title">COST</div>
                        <div class="config-detail">{{productConfig.productionCost | toCurrency}}</div>
                    </div>
                    <div v-if="isAdvertisingEnabled" >
                        <div class="product-config inline">
                            <div class="config-title">{{getCustomLabelValue("advtCost")}}<small>(per unit)</small></div>
                            <div class="config-detail">{{advertisingConfig.cost | toCurrency}}</div>
                        </div>
                        <div class="product-config inline">
                            <div class="config-title">{{getCustomLabelValue("advtBenefit")}}</div>
                            <div class="config-detail">{{advertisingConfig.benefit | toCurrency}}</div>
                        </div>
                    </div>
                    <GChart type="LineChart"
                            :data="getDemandCurveData(productConfig)"
                            :options="demandCurveGraphOptions"
                    />
                    <div class="demand-formula">y = ({{productConfig.demandSlope}})x + {{productConfig.demandIntercept}}</div>
                    <md-field v-if="!isProductLocallyElected(productConfig.gameProductId)">
                        <label>Number of Units to Offer for Sale</label>
                        <md-input 
                            v-model="electionInputs[productConfig.gameProductId].numOffered" 
                            @keypress="preventNegativeAndDecimal"
                            @click="selectNumUnits(productIndex)"
                            :ref="'numUnitsInput' + productIndex" 
                            type="text"
                            @blur="numOfferedBlur(productConfig.gameProductId)"
                            pattern="\d*">
                        </md-input>
                    </md-field>
                    <div v-if="isProductLocallyElected(productConfig.gameProductId)" class="left-text space-top">
                        <div class="election-config-label">Go to Market with</div>
                        <div class="election-config-value">{{electionInputs[productConfig.gameProductId].numOffered}} Units</div>
                    </div>
                    <md-field v-if="!isProductLocallyElected(productConfig.gameProductId)">
                        <label>Unit Retail Price</label>
                        <currency-input :currency-to-format.sync="electionInputs[productConfig.gameProductId].price" :input-callback="checkDirtyProductPrice" :allows-negative="false"></currency-input>
                    </md-field>
                    <div v-if="isProductLocallyElected(productConfig.gameProductId)" class="left-text" v-bind:class="{ 'space-bottom': !isAdvertisingEnabled }">
                        <div class="election-config-label">Selling Price</div>
                        <div class="election-config-value">{{electionInputs[productConfig.gameProductId].price | toCurrency}}</div>
                    </div>
                    <md-field v-if="!isProductLocallyElected(productConfig.gameProductId) && isAdvertisingEnabled" :ref="'numAdsField-' + productConfig.gameProductId">
                        <label>{{getCustomLabelValue("numUnitsToAdvertise")}}</label>
                        <md-input 
                            v-model="electionInputs[productConfig.gameProductId].numAds" 
                            @keypress="preventNegativeAndDecimal"
                            @blur="numAdvertisedBlur(productConfig.gameProductId)"
                            @click="selectNumAds(productConfig.gameProductId)"
                            :ref="'numAdsInput-' + productConfig.gameProductId"
                            type="text"
                            pattern="\d*">
                        </md-input>
                    </md-field>
                    <div v-if="isProductLocallyElected(productConfig.gameProductId) && isAdvertisingEnabled" class="left-text">
                        <div class="election-config-label">{{getCustomLabelValue("unitsToAdvertise")}}</div>
                        <div class="election-config-value">{{electionInputs[productConfig.gameProductId].numAds}} Units</div>
                    </div>
                    <div v-if="isProductLocallyElected(productConfig.gameProductId)" class="left-text space-bottom">
                        <div class="election-config-label">Cost</div>
                        <div class="election-config-value">{{productionCosts[productConfig.gameProductId] | toCurrency}}</div>
                    </div>
                    <div>
                        <div class="warning" v-if="numOfferedWarningIds.indexOf(productConfig.gameProductId) >= 0">Units to Offer into market must be greater than 0 to submit.  To offer 0, do not submit this product.</div>
                        <div class="ad-warning" v-if="adWarningConfigIds.indexOf(productConfig.gameProductId) >= 0">{{getCustomLabelValue("offerPriceWithAdsMessage")}}</div>
                        <div class="num-ad-warning" v-if="numAdvertisedWarningIds.indexOf(productConfig.gameProductId) >= 0">{{getCustomLabelValue("unitsAdvertisingLessThanOfferedMessage")}}</div>
                        <div class="price-warning" v-if="priceWarningConfigIds.indexOf(productConfig.gameProductId) >= 0">Price must be higher than 0</div>
                        <md-button v-if="!isProductLocallyElected(productConfig.gameProductId)" @click="electProduct(productConfig.gameProductId, productConfig.id)" :disabled="submitDisabled(productConfig.gameProductId)" class="btn-primary md-raised md-primary">Save</md-button>
                        <md-button v-if="isProductLocallyElected(productConfig.gameProductId)" @click="cancelProductElection(productConfig.gameProductId)" class="md-raised btn-warning">Cancel Election</md-button>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import {
    MdCard,
    MdField,
    MdIcon
} from 'vue-material/dist/components';
import CurrencyInput from './CurrencyInput';
import { GChart } from 'vue-google-charts'
import {
    mapGetters,
    mapActions,
} from 'vuex'
export default {
    name: 'Product',
    components: {
        GChart,
        'currency-input': CurrencyInput
    },
    data() {
        return {
            attrs: {
                currentProductElections: this.productElections,
                electedProducts: [],
                currentProductionCost: this.productionCost
            },
            electionInputs: {},
            demandCurveData: [],
            dirtyProductIds: [],
            adWarningConfigIds: [],
            priceWarningConfigIds: [],
            numOfferedWarningIds: [],
            numAdvertisedWarningIds: [],
            productionCosts: {}
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentRoundConfig',
            'getCurrentCourse',
            'getGameProduct',
            'getGameLabels',
            'isDarkMode'
        ]),
        currentProductElections: {
            get() {
                return this.attrs.currentProductElections;
            },
            set(value) {
                this.attrs.currentProductElections = value;
                this.$emit(`update:product-elections`, value);
            },
        },
        currentProductionCost: {
            get() {
                return this.attrs.currentProductionCost;
            },
            set(value) {
                this.attrs.currentProductionCost = value;
                this.$emit(`update:production-cost`, value);
            },
        },
        electedProducts: {
            get() {
                return this.attrs.electedProducts;
            },
            set(value) {
                this.attrs.electedProducts = value;
            }
        },
        demandCurveGraphOptions: {
            get() {
                let maxValX = 0;
                let maxValY = 0;

                for(let productConfig of this.getCurrentRoundConfig.products) {
                    const xAxisCross = productConfig.demandIntercept * -1 / productConfig.demandSlope;
                    maxValX = Math.max(maxValX, xAxisCross + 10);
                    maxValY = Math.max(maxValY, productConfig.demandIntercept + 20);
                }

                console.log(this.isDarkMode)

                return {
                    title: "Demand Curve",
                    legend: 'none',
                    vAxis: {
                        title: 'Retail Price',
                        minValue: 0,
                        maxValue: maxValY,
                        textStyle: {
                            color: !this.isDarkMode ? '#000' : '#fff'
                        },
                        gridlines: {
                            color: !this.isDarkMode ? '#000' : '#fff'
                        },
                        baselineColor: !this.isDarkMode ? '#000' : '#fff'
                    },
                    backgroundTransparency: 0,
                    backgroundColor: 'transparent',
                    hAxis: {
                        title: 'Units Sold (thousands)',
                        minValue: 0,
                        maxValue: maxValX,
                        textStyle: {
                            color: !this.isDarkMode ? '#000' : '#fff'
                        },
                        gridlines: {
                            color: !this.isDarkMode ? '#000' : '#fff'
                        },
                        baselineColor: !this.isDarkMode ? '#000' : '#fff'
                    },
                    series: {
                      0: {color: '#523f98'}
                    }
                }
            }
        }
    },
    methods: {
        getCustomLabelValue(key) {
            if(this.getGameLabels) {
                const found = this.getGameLabels.find(label => label.key === key);
                return found ? found.value : "";
            }
            return "";
        },
        formatCurrency(value) {
          if(typeof value !== "number") {
            return value;
          }
          var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
          });
          return formatter.format(value);
        },
        numInStock(gameProductId) {
          return this.assets.products
              .filter((report) => report.gameProduct.id === gameProductId)
              .map((report) => report.inventoryAmount)
              .reduce((total, inventoryAmount) => {
                total += inventoryAmount
                return total;
              }, 0);
        },
        getDemandCurveData(productConfig) {
            const xAxisCross = productConfig.demandIntercept * -1 / productConfig.demandSlope;
            return [...Array(Math.ceil(xAxisCross)).keys()].reduce((_data, roundIndex) => {
              if(roundIndex === 0) {
                return _data;
              }
              const roundYValue = (productConfig.demandSlope * roundIndex) + productConfig.demandIntercept;
              _data.push([roundIndex, roundYValue, `Units Sold: ${roundIndex}K \n Demand Price: ${this.formatCurrency(roundYValue)}`]);
              if(roundIndex === Math.ceil(xAxisCross) - 1 && roundIndex !== xAxisCross) {
                // our index has moved past the x axis cross, so the x axis cross is a fractional number...add the x axis cross as last data point
                _data.push([xAxisCross, 0, `Units Sold: ${xAxisCross}K \n Demand Price: ${this.formatCurrency(0)}`]);
              }
              return _data;
            }, [
              ['Units Sold (thousands)', 'Demand Price', {label: 'tooltip', role: 'tooltip'}],
              [0, productConfig.demandIntercept, `Units Sold: 0K \n Demand Price: ${this.formatCurrency(productConfig.demandIntercept)}`]
            ]);
        },
        isProductLocallyElected(gameProductId) {
            return this.currentProductElections
                .map((electedProduct) => `${electedProduct.gameProductId}`)
                .indexOf(`${gameProductId}`) >= 0;
        },
        calculateProductionCost(gameProductId) {
            const productConfig = this.getCurrentRoundConfig.products.find((_product) => _product.gameProductId === gameProductId);
            const numProduced = this.electionInputs[gameProductId].numOffered - this.numInStock(gameProductId);
            let productionCost = numProduced > 0 ? productConfig.productionCost * numProduced : 0;
            const numAdvertised = parseInt(this.electionInputs[gameProductId].numAds);
            if(this.isAdvertisingEnabled && this.advertisingConfig.cost > 0 && numAdvertised > 0) {
                productionCost += (this.advertisingConfig.cost * numAdvertised);
            }
            return productionCost;
        },
        electProduct(gameProductId, productConfigId) {
            const priceWarnIndex = this.priceWarningConfigIds.indexOf(gameProductId)
            const adWarnIndex = this.adWarningConfigIds.indexOf(gameProductId)

            if(priceWarnIndex >= 0){
                this.priceWarningConfigIds.splice(priceWarnIndex, 1);
            }
            if(adWarnIndex >= 0){
                this.adWarningConfigIds.splice(adWarnIndex, 1);
            }
            const offerPrice = this.electionInputs[gameProductId].price
            if(offerPrice <= 0) {
                this.priceWarningConfigIds.push(gameProductId)
                return;
            }
            this.productionCosts[gameProductId] = this.calculateProductionCost(gameProductId);
            if(this.isAdvertisingEnabled && this.advertisingConfig.benefit > 0 && offerPrice <= this.advertisingConfig.benefit) {
                this.adWarningConfigIds.push(gameProductId)
                return;
            }
            const electedIds = this.currentProductElections
                .map((electedProduct) => `${electedProduct.gameProductId}`);

            if(electedIds.indexOf(`${gameProductId}`) === -1) {
                this.currentProductElections.push({
                    productConfigId: productConfigId,
                    gameProductId: gameProductId,
                    quantityOffered: this.electionInputs[gameProductId].numOffered,
                    offerPrice: this.electionInputs[gameProductId].price,
                    numUnitsToAdvertise: this.electionInputs[gameProductId].numAds
                });
            }

            for(let product of this.currentProductElections) {
                if(this.dirtyProductIds.includes(product.gameProductId)) {
                    this.dirtyProductIds.splice(this.dirtyProductIds.indexOf(product.gameProductId), 1);
                }
            }
        },
        cancelProductElection(gameProductId) {
            this.productionCosts[gameProductId] = 0; 
            const electionIndex = this.currentProductElections
                .map((electedProduct) => `${electedProduct.gameProductId}`)
                .indexOf(`${gameProductId}`);

            if(electionIndex >= 0) {
                this.currentProductElections.splice(electionIndex, 1);
            }
            if(this.electionInputs[gameProductId].price > 0) {
                this.dirtyProductIds.push(gameProductId);
            }
        },
        selectNumUnits(productIndex) {
            const input = this.$refs['numUnitsInput' + productIndex.toString()][0].$el;
            input.focus();
            input.select();
        },
        selectNumAds(gameProductId) {
            const input = this.$refs['numAdsInput-' + gameProductId][0].$el;
            input.focus();
            input.select();
        },
        checkDirtyProductPrice() {
            const electionInputKeys = Object.keys(this.electionInputs);
            for(let key in this.electionInputs) {
                if(this.electionInputs[key].price > 0 && !this.dirtyProductIds.includes(key)) {
                    this.dirtyProductIds.push(key);
                }
                if(this.electionInputs[key].price <= 0 && this.dirtyProductIds.includes(key)) {
                    this.dirtyProductIds.splice(this.dirtyProductIds.indexOf(key), 1);
                }
            }
            if(this.electedProducts) {
                for(let i = 0; i < this.electedProducts.length; i++) {
                    if(electionInputKeys.includes(this.electedProducts[i].gameProductId)) {
                        this.dirtyProductIds.splice(this.dirtyProductIds.indexOf(this.electedProducts[i].gameProductId), 1);
                    }
                }
            }
        },
        preventNaN(gameProductId) {
            if(isNaN(this.electionInputs[gameProductId].numAds) || this.electionInputs[gameProductId].numAds === ''){
                Vue.nextTick(() => {
                  this.electionInputs[gameProductId].numAds = 0;
                  const field = this.$refs['numAdsField-' + gameProductId][0].$el;
                  const input = this.$refs['numAdsInput-' + gameProductId][0].$el;
                  input.value = 0;
                  field.classList.add("md-has-value");
                })
            }
        },
        preventNegativeAndDecimal(event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if(keyCode < 48 || keyCode > 57) {
              event.preventDefault();
            }
        },
        numAdvertisedBlur(gameProductId) {
            this.preventNaN(gameProductId);
            const warnIndex = this.numAdvertisedWarningIds.indexOf(gameProductId)
            if(warnIndex >= 0){
                this.numAdvertisedWarningIds.splice(warnIndex, 1);
            }

            if(parseInt(this.electionInputs[gameProductId].numAds) > parseInt(this.electionInputs[gameProductId].numOffered)) {
                this.numAdvertisedWarningIds.push(gameProductId);
            }
        },
        numOfferedBlur(gameProductId) {
            const warnIndex = this.numOfferedWarningIds.indexOf(gameProductId)
            if(warnIndex >= 0){
                this.numOfferedWarningIds.splice(warnIndex, 1);
            }

            if(parseInt(this.electionInputs[gameProductId].numOffered) <= 0) {
                this.numOfferedWarningIds.push(gameProductId);
            }

            const adWarnIndex = this.numAdvertisedWarningIds.indexOf(gameProductId)
            if(adWarnIndex >= 0){
              this.numAdvertisedWarningIds.splice(adWarnIndex, 1);
            }

            if(parseInt(this.electionInputs[gameProductId].numAds) > parseInt(this.electionInputs[gameProductId].numOffered)) {
              this.numAdvertisedWarningIds.push(gameProductId);
            }
        },
        submitDisabled(gameProductId) {
            return this.numOfferedWarningIds.indexOf(gameProductId) >= 0 || this.numAdvertisedWarningIds.indexOf(gameProductId) >= 0
        }
    },
    watch: {
        currentProductElections(newVal, oldVal) {
            if(newVal) {
                this.$emit(`update:product-elections`, newVal);
                this.electedProducts = newVal.map((electedProduct) => {
                    return {
                        productConfigId: electedProduct.productConfigId,
                        gameProductId: electedProduct.gameProductId
                    };
                });
            }
        },
        dirtyProductIds(val) {
            if(val) {
                if(val.length) {
                    this.$emit(`update:is-product-saved`, false);
                } else {
                    this.$emit(`update:is-product-saved`, true);
                }
            }
        },
        productionCosts: {
            handler(val) {
                this.currentProductionCost = Object.values(this.productionCosts).reduce((total, productCost) => {
                    return total + productCost;
                }, 0);
            },
            deep: true // this allows the watched prop to detect changes in the object keys
        }
    },
    props: {
        productElections: {
            type: Array,
        },
        isAdvertisingEnabled: {
            type: Boolean
        },
        advertisingConfig: {
            type: Object
        },
        isProductSaved: {
            type: Boolean
        },
        productionCost: {
            type: Number
        },
        assets: {
            type: Object
        }
    },
    created() {
        const roundConfig = this.getCurrentRoundConfig;
        this.electionInputs = {};
        for(let productConfig of roundConfig.products) {
            // $set must be used when setting object props that need to watched for reactive changes
            this.$set(this.productionCosts, productConfig.gameProductId, 0);
            this.electionInputs[productConfig.gameProductId] = {numOffered: 1, price: 0, numAds: 0}
        }

        this.currentProductElections = this.productElections;
        if(!this.currentProductElections) {
            this.currentProductElections = [];
        }

        for(let productElection of this.currentProductElections){
            const gameProductId = productElection.gameProductId;
            const productConfigId = productElection.productConfigId;
            const numOffered = productElection.quantityOffered;
            const price = productElection.offerPrice;
            const numAds = productElection.numUnitsToAdvertise;
            this.electionInputs[gameProductId] = {numOffered, price, numAds};
            this.electedProducts.push({gameProductId, productConfigId});
            this.productionCosts[productElection.gameProductId] = this.calculateProductionCost(gameProductId);
        }
    }
   }

Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdIcon);
</script>

<style scoped>
    .md-layout-item {
        padding: 10px;
    }

    .wrapper {
        background-color: var(--main-box-color);
    }

    .md-layout-item {
        padding: 10px;
    }

    .productBlock {
        margin-left: auto;
        margin-right: auto;
        min-width: 98%;
        max-width: 98%;
        padding: 0;
    }

    .md-card {
        width: 300px;
        margin: 20px;
        display: inline-block;
        vertical-align: top;
    }

    .config-title {
        font-size: 10px;
    }

    .demand-formula {
      font-weight: bold;
    }

    .config-detail {
        font-weight: bold;
        font-size: 14px;
        margin-top: -7px;
        margin-bottom: 10px;
    }

    .inline {
        display: inline-block;
        margin-right: 30px;
        margin-left: 10px;
    }

    .product-config {
        text-align: center;
    }

    .election-config-label {
        display: inline-block;
    }

    .election-config-value {
        display: inline-block;
        font-weight: bold;
        margin-left: 15px;
    }

    .left-text {
        text-align: left;
    }

    .space-top {
        margin-top: 15px;
    }

    .space-bottom {
        margin-bottom: 15px;
    }

    .price-warning {
        color: var(--btn-warning-color);
    }

    .ad-warning {
        color: var(--btn-warning-color);
    }

    .num-ad-warning {
        color: var(--btn-warning-color);
    }
</style>
