<template>
  <div v-if="isPageReady" class="container">
    <div class="main-box">
      <div v-if="license">
        <h1 class="text-center">Renew License</h1>
        <div class="md-layout">
          <div class="md-layout-item"></div>
          <div v-if="getUserType === UserTypeEnum.Professor"
              class="md-layout-item md-large-size-50 md-medium-size-50 md-small-size-70 md-xsmall-size-80 main-box">
            <div class="form-container">
              <h3>License Info</h3>
              <div>Your one time license fee: {{ license.price | toCurrency }}</div>
              <div>Each student will pay: {{ license.studentPrice | toCurrency }}</div>
              <div>You will be allowed {{ license.numAllowedGames }} games</div>
              <div>Your license will end on {{ getFormattedDate(license.licenseEnd) }}</div>
            </div>
            <div class="form-container">
              <div v-if="showStripePaymentForm" class="license-container">
                <div class="md-layout">
                  <div class="md-layout-item"></div>
                  <StripeCardElement :amount="calculatePaymentAmount()"
                                    class="md-layout-item md-large-size-75 md-medium-size-75 md-small-size-75 md-xsmall-size-100"
                                    ref="cardForm"></StripeCardElement>
                  <div class="md-layout-item"></div>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item"></div>
                <md-button :disabled="httpWait" @click="confirmLicense()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
                  {{ getCheckoutText() }}
                  <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                </md-button>
                <div class="md-layout-item"></div>
              </div>
              <span class="error" v-if="serverError">{{ serverError }}</span>
            </div>
          </div>
          <div v-if="getUserType === UserTypeEnum.Admin"
              class="md-layout-item md-large-size-50 md-medium-size-50 md-small-size-70 md-xsmall-size-80 main-box">
            <div class="form-container">
              <div class="md-layout">
                <div class="md-layout-item"></div>
                <md-button :disabled="httpWait" @click="confirmLicense()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
                  Confirm License
                  <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                </md-button>
                <div class="md-layout-item"></div>
              </div>
              <span class="error" v-if="serverError">{{ serverError }}</span>
            </div>
          </div>
          <div class="md-layout-item"></div>
        </div>
      </div>
      <div v-else class="md-layout">
        <div class="md-layout-item"></div>
        <div class="md-layout-item">
          <span class="error" v-if="serverError">{{ serverError }}</span>
        </div>
        <div class="md-layout-item"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import StripeCardElement from './StripeCardElement'
import {UserTypeEnum} from "temporary-api-types/apiTypes";

export default {
  name: 'ExistingUserLicenseOnboard',
  components: {
    StripeCardElement
  },
  data() {
    return {
      currentEmail: null,
      serverError: null,
      license: null,
      inputError: null,
      showStripePaymentForm: true,
      url: process.env.VUE_APP_LAMBDA_URL,
      isPageReady: false,
      UserTypeEnum,
      httpWait: false
    }
  },
  props: {
    email: {
      type: String
    },
    successCb: {
      type: Function
    }
  },
  computed: {
    ...mapGetters([
      'getUserType',
      'getFormattedDate'
    ])
  },
  methods: {
    async confirmLicense() {
      this.httpWait = true;
      if (this.calculatePaymentAmount() > 0) {
        const paymentResult = await this.$refs.cardForm.confirmPaymentIntent();
        if (paymentResult.error) {
          this.serverError = paymentResult.error.message;
          this.httpWait = false;
          return paymentResult.error;
        }
      }
      try {
        const data = {
          user: {
            email: this.currentEmail
          },
          licenseKey: this.license.key,
          licenseType: this.getUserType
        };
        const response = await fetch(`${this.url}/onboardWithPresetLicense`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'POST'
          },
          body: JSON.stringify(data)
        });
        if (response.ok) {
          if (this.successCb) {
            this.successCb();
          } else {
            this.httpWait = false;
            this.$router.push({
              name: 'Login',
              params: {
                notifyMessage: "Successfully paid for new license",
                email: this.currentEmail
              }
            });
          }
        } else {
          this.serverError = "Something went wrong. Please try again";
          this.httpWait = false;
          return this.serverError;
        }
      } catch (err) {
        this.httpWait = false;
        return err;
      }
      this.httpWait = false;
    },
    calculatePaymentAmount() {
      if (this.license.price && this.license.price > 0) {
        return this.license.price;
      }
      return null;
    },
    getCheckoutText() {
      const paymentAmount = this.calculatePaymentAmount();
      if (paymentAmount && paymentAmount > 0) {
        return `Pay ${this.$options.filters.toCurrency(paymentAmount)}`;
      }
      return "Submit";
    },
    async getLicenseDetail() {
      this.httpWait = true;
      const data = {
        email: this.currentEmail,
        licenseType: this.getUserType
      };
      try {
        const response = await fetch(`${this.url}/license`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'POST'
          },
          body: JSON.stringify(data)
        });
        const responseJson = await response.json();
        if (response.ok) {
          this.license = responseJson;
          if(!this.license) {
            this.serverError = "License not found. Please contact accountonomics for more details";
            this.httpWait = false;
            return;
          }
          if (!this.license.price || this.license.price < 0) {
            this.showStripePaymentForm = false;
          }
          this.httpWait = false;
        } else {
          if (this.getUserType === UserTypeEnum.Professor) {
            this.license = {
              school: "",
              price: 1000,
              studentPrice: 27,
              numAllowedGames: 7,
              licenseStart: Date.now() / 1000,
              licenseEnd: (Date.now() + 604800000) / 1000 // one week
            }
          }
          this.httpWait = false;
        }
      } catch (err) {
        this.serverError = "Something went wrong. Please try again";
        this.httpWait = false;
        return err;
      }
    },
  },
  async created() {
    if (this.email) {
      this.currentEmail = this.email;
    } else {
      this.currentEmail = this.$route.query.email;
    }
    await this.getLicenseDetail();
    this.isPageReady = true;
  }
}
</script>

<style scoped>
h1 {
  padding: 1em;
}

.container {
  margin: 1em;
}

.main-box {
  background-color: var(--main-box-color);
  margin: 1em;
}

.text-center {
  text-align: center;
}

.error {
  color: var(--warning-text-color);
}

.form-error {
  display: block !important;
  left: 0;
  height: 20px;
  position: absolute;
  bottom: -22px;
  font-size: 12px;
  transition: .3s cubic-bezier(.4, 0, .2, 1);
}

.retype-password-container {
  padding-top: 50px;
}

@media screen and (min-width: 1900px) {
  .main-box {
    min-width: 1000px;
  }
}
</style>
