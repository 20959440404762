<template>
  <div v-if="isPageReady" class="container">
    <div class="main-box">
      <div v-if="license">
        <h1 class="text-center">Onboard</h1>
        <div class="md-layout">
          <div class="md-layout-item"></div>
          <div class="md-layout-item md-large-size-50 md-medium-size-50 md-small-size-70 md-xsmall-size-80 main-box">
            <div class="form-container">
              <div class="md-layout">
                <div class="md-layout-item"></div>
                <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                  <label>First Name</label>
                  <md-input v-model="firstName"></md-input>
                  <span v-if="inputError === 'firstName'" class="error form-error">Please enter first name</span>
                </md-field>
                <div class="md-layout-item"></div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item"></div>
                <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                  <label>Last Name</label>
                  <md-input v-model="lastName"></md-input>
                  <span v-if="inputError === 'lastName'" class="error form-error">Please enter last name</span>
                </md-field>
                <div class="md-layout-item"></div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item"></div>
                <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                  <label>Password</label>
                  <md-input v-model="password" type="password"></md-input>
                  <span v-if="inputError === 'password'" class="error form-error">Please enter a valid password: must contain at least 1 uppercase character, 1 lowercase character, 1 number and be at least 6 characters in length</span>
                </md-field>
                <div class="md-layout-item"></div>
              </div>
              <div :class="{ 'retype-password-container' : inputError === 'password' }">
                <div class="md-layout">
                  <div class="md-layout-item"></div>
                  <md-field class="md-layout-item md-large-size-33 md-medium-size-50 md-small-size-75 md-xsmall-size-100">
                    <label>Confirm Password</label>
                    <md-input v-model="retypePassword" type="password"></md-input>
                    <span v-if="inputError === 'passwordNotMatch'" class="error form-error">Passwords must match</span>
                  </md-field>
                  <div class="md-layout-item"></div>
                </div>
              </div>
              <div v-if="getUserType === UserTypeEnum.Professor" class="md-layout-item">
                <div class="form-container">
                  <h3>License Info</h3>
                  <div>Your one time license fee: {{ license.price | toCurrency }}</div>
                  <div>Each student will pay: {{ license.studentPrice | toCurrency }}</div>
                  <div>You will be allowed {{ license.numAllowedGames }} games</div>
                  <div>Your license will end on {{ getFormattedDate(license.licenseEnd) }}</div>
                </div>
                <div class="form-container">
                  <div v-if="showStripePaymentForm" class="license-container">
                    <div class="md-layout">
                      <div class="md-layout-item"></div>
                      <StripeCardElement :amount="calculatePaymentAmount()"
                                        class="md-layout-item md-large-size-75 md-medium-size-75 md-small-size-75 md-xsmall-size-100"
                                        ref="cardForm"></StripeCardElement>
                      <div class="md-layout-item"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item"></div>
                <md-button :disabled="httpWait" @click="confirmSignUp()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
                  {{ getCheckoutText() }}
                  <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                </md-button>
                <div class="md-layout-item"></div>
              </div>
              <span class="error" v-if="serverError">{{ serverError }}</span>
            </div>
          </div>
          <div class="md-layout-item"></div>
        </div>
      </div>
      <div v-else class="md-layout">
        <div class="md-layout-item"></div>
        <div class="md-layout-item">
          <span class="error" v-if="serverError">{{ serverError }}</span>
        </div>
        <div class="md-layout-item"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import StripeCardElement from './StripeCardElement'
import {UserTypeEnum} from "temporary-api-types/apiTypes";

export default {
  name: 'AdminInitiatedOnboard',
  components: {
    StripeCardElement
  },
  data() {
    return {
      email: null,
      confirmationCode: null,
      firstName: null,
      lastName: null,
      password: null,
      retypePassword: null,
      serverError: null,
      licensePrice: 1,
      licenseKey: null,
      inputError: null,
      showStripePaymentForm: true,
      url: process.env.VUE_APP_LAMBDA_URL,
      UserTypeEnum,
      httpWait: false,
      isPageReady: false,
      license: null
    }
  },
  computed: {
    ...mapGetters([
      'getUserType',
      'getFormattedDate'
    ])
  },
  methods: {
    async confirmSignUp() {
      if (this.calculatePaymentAmount() > 0) {
        this.httpWait = true;
        const paymentResult = await this.$refs.cardForm.confirmPaymentIntent();
        if (paymentResult.error) {
          this.serverError = paymentResult.error.message;
          this.httpWait = false;
          return paymentResult.error;
        }
      }
      if (this.validateForm()) {
        this.httpWait = true;
        try {
          await this.setPassword();
          const data = {
            user: {
              email: this.email,
              firstname: this.firstName,
              lastname: this.lastName
            },
            licenseKey: this.licenseKey,
            licenseType: this.getUserType
          };
          try {
            const response = await fetch(`${this.url}/onboardWithPresetLicense`, {
              method: 'POST',
              mode: 'cors',
              cache: 'no-cache',
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'POST'
              },
              body: JSON.stringify(data)
            });
            if (response.ok) {
              this.httpWait = false;
              this.$router.push({
                name: 'Login',
                params: {
                  notifyMessage: "Successfully registered",
                  email: this.email
                }
              });
            } else {
              this.serverError = "Something went wrong. Please try again";
              this.httpWait = false;
              return this.serverError;
            }
          } catch (err) {
            this.serverError = "Something went wrong. Please try again";
            this.httpWait = false;
            return err;
          }
        } catch (err) {
          this.httpWait = false;
          return err;
        }
      }
    },
    getCheckoutText() {
      const paymentAmount = this.calculatePaymentAmount();
      if (paymentAmount && paymentAmount > 0) {
        return `Pay ${this.$options.filters.toCurrency(paymentAmount)}`;
      }
      return "Submit";
    },
    calculatePaymentAmount() {
      if (this.licensePrice && this.licensePrice > 0 && this.getUserType === UserTypeEnum.Professor) {
        return this.licensePrice;
      }
      return null;
    },
    async getLicenseDetail() {
      this.httpWait = true;
      const data = {
        email: this.email,
        licenseType: this.getUserType
      };
      try {
        const response = await fetch(`${this.url}/license`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'POST'
          },
          body: JSON.stringify(data)
        });
        const responseJson = await response.json();
        if (response.ok) {
          this.license = responseJson;
          if(!this.license) {
            this.serverError = "License not found. Please contact accountonomics for more details";
            this.httpWait = false;
            return;
          }
          this.licensePrice = responseJson.price;
          this.licenseKey = responseJson.key;
          if (!this.licensePrice || this.licensePrice < 0) {
            this.showStripePaymentForm = false;
          }
          this.httpWait = false;
        } else {
          this.serverError = responseJson.message;
          this.httpWait = false;
          return this.serverError;
        }
      } catch (err) {
        this.serverError = "Something went wrong. Please try again";
        this.httpWait = false;
        return err;
      }
    },
    async setPassword() {
      const data = {
        email: this.email,
        password: this.password,
        confirmationCode: this.confirmationCode
      };
      try {
        const response = await fetch(`${this.url}/resetPassword`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'POST'
          },
          body: JSON.stringify(data)
        });
        const responseJson = await response.json();
        if (!response.ok) {
          this.serverError = "Could not set password. Please try again";
          if (responseJson.code === 'ExpiredCodeException') {
            this.serverError = "The email link to set up your account has expired. Just now, we sent you a new link.  Please check your email and use the new link to initiate your account";
            await this.resendConfirmationCode();
          }
          this.httpWait = false;
          return Promise.reject(responseJson);
        }
        return;
      } catch (err) {
        this.serverError = "Something went wrong. Please try again";
        this.httpWait = false;
        return Promise.reject(err);
      }
    },
    async resendConfirmationCode() {
      const data = {
        email: this.email,
        admin: true
      };

      try {
        const response = await fetch(`${this.url}/resendConfirmationCode`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'POST'
          },
          body: JSON.stringify(data)
        });

        let notifyMessage = "The link for setting up this account has expired. Check your email for a new link";

        if(!response.ok) {
          notifyMessage = "Error sending account setup link after the old one expired. Please contact Support";
        }

        this.$router.push({
          name: 'NotifyMessage',
          params: {
            message: notifyMessage
          }
        });
      } catch(err) {
        this.serverError = "Something went wrong. Please try again";
        return err;
      }
    },
    validateForm() {
      if (!this.firstName) {
        this.inputError = "firstName";
        return false;
      } else if (!this.lastName) {
        this.inputError = "lastName";
        return false;
      } else if (!this.validatePassword()) {
        return;
      } else if (this.password !== this.retypePassword) {
        this.inputError = "passwordNotMatch";
        return false;
      } else {
        this.inputError = "";
        return true;
      }
    },
    validatePassword() {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9!@#$&%*\-<>=?|:;~(){}[\\-`.+,/"]{6,}$/;
      if (this.password && re.test(this.password)) {
        return true;
      } else {
        this.inputError = "password";
        return false;
      }
    }
  },
  async created() {
    this.email = this.$route.query.email;
    this.confirmationCode = this.$route.query.code;
    await this.getLicenseDetail();
    this.isPageReady = true;
  }
}
</script>

<style scoped>
h1 {
  padding: 1em;
}

.container {
  margin: 1em;
}

.main-box {
  background-color: var(--main-box-color);
  margin: 1em;
}

.text-center {
  text-align: center;
}

.error {
  color: var(--warning-text-color);
}

.form-error {
  display: block !important;
  left: 0;
  height: 20px;
  position: absolute;
  bottom: -22px;
  font-size: 12px;
  transition: .3s cubic-bezier(.4, 0, .2, 1);
}

.retype-password-container {
  padding-top: 50px;
}

@media screen and (min-width: 1900px) {
  .main-box {
    min-width: 1000px;
  }
}
</style>
