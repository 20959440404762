<template>
  <div v-if="isPageReady" class="income-statement-container">
    <div v-if="allowSubmissionWhenValidGameConfigAndGameState && !gameStateDisabledLabel && gameStateDisabledLabel !== ''">
      <div class="no-sheet" v-if="(!currentIncomeStatement || !currentIncomeStatement.submittedBy) && showTeamAnswers">
        <span style="color: var(--text-primary);" v-if="!isPastIncomeStatement">Team {{this.selectedTeam.name}} has not yet submitted an Income Statement for Round {{ this.getActiveRoundIndex() + 1 }}</span>
        <span style="color: var(--text-primary);" v-if="isPastIncomeStatement">Team {{this.selectedTeam.name}} did not submit an Income Statement for Round {{ this.getActiveRoundIndex() + 1 }}</span>
      </div>
      <div class="countdown" v-if="!isPastIncomeStatement && shouldShowCountdown">
          <Countdown :deadline="currentDeadline" :countdown-id="'income-statement'" :hide-labels="true" :hide-big-units="true"></Countdown>
          <div class="remaining-label">remaining</div>
      </div>
      <div v-if="isPastIncomeStatement && pastIncomeStatementTotal && showTeamAnswers">
        <h1 style="color: var(--text-primary);">{{pastIncomeStatementCorrect}}/{{pastIncomeStatementTotal}} PTS</h1>
      </div>
      <div class="selected-color-indicator-container" v-if="showCorrectAnswers && isSplitView && this.currentAvailableJournalEntryFilters && this.currentAvailableJournalEntryFilters.length">
        <span class="selected-color-indicator">____</span> <span style="color: var(--text-primary);">= Filter Journal Entries by Accounts for Line Item</span>
      </div>
      <div v-if="isEditable">
        <h1 style="color: var(--text-primary);">Fill out sheet to be graded</h1>
        <h4 style="color: var(--text-primary);">Income accounts should be shown as positive figures and expense accounts as negative.</h4>
        <div class="md-layout btn-group">
          <md-button v-if="!isEditing" @click="toggleIsEditing()" class="md-raised btn-rounded">
            EDIT
          </md-button>
          <md-button v-if="isEditing" @click="submitCurrentIncomeStatement()" class="md-raised btn-rounded save-btn">
            SAVE
          </md-button>
          <md-button v-if="isEditing" @click="cancelCurrentIncomeStatement()" class="md-raised btn-rounded cancel-btn">
            CANCEL
          </md-button>
          <div style="color: var(--text-primary);" class="text-next-to-button">Don't forget to save your work often</div>
        </div>
      </div>
      <div v-if="isEditable" class="alert">
        <div v-if="showFormError && !dismissAlert" class="alert-error">
          {{errorMessage}}
          <span @click="clearAlert()"><md-icon class="fa fa-times-circle light"></md-icon></span>
        </div>
        <div v-if="currentIncomeStatement && currentIncomeStatement.submittedBy && !hasSuccessfullySubmitted" class="alert-success">
        <span>
          Team has submitted income statement
          <br>
          Submitted by {{currentIncomeStatement.submittedBy.firstname}} {{currentIncomeStatement.submittedBy.lastname}} at {{getFormattedDate(parseInt(currentIncomeStatement.createdAt)/1000, selectedTeam.semester.timezone)}}
        </span>
        </div>
        <div v-if="hasSuccessfullySubmitted" class="alert-success alert-success-pulse">
          <span v-if="hasSuccessfullySubmitted">You successfully submitted!</span>
        </div>
      </div>
    </div>
    <div class="main-box" v-if="!gameStateDisabledLabel && gameStateDisabledLabel !== ''">
      <div v-if="(allowSubmissionWhenValidGameConfigAndGameState && !isEditing) || !allowSubmissionWhenValidGameConfigAndGameState" class="read-only-table">
        <md-table>
          <md-table-toolbar class="table-title">
            <h1 class="md-title text-center title-text">
              <span v-if="isPastIncomeStatement">ROUND {{roundNum}}</span>
              INCOME STATEMENT
            </h1>
          </md-table-toolbar>

          <md-table-row class="non-input-header">
            <md-table-cell class="table-header">Sales</md-table-cell>
            <md-table-cell v-if="showTeamAnswers" v-bind:class="[{'correct-answers-cell': isPastIncomeStatement && currentIncomeStatement}, 'mobile-table-cell answers-container']">
              <span class="md-layout" v-if="!isPastIncomeStatement && allowSubmissionWhenValidGameConfigAndGameState && getUserType === UserTypeEnum.Student">*for negative values, prefix your input with either "-" or "("</span>
              <span v-if="currentIncomeStatement">
                <span v-if="getUserType === UserTypeEnum.Student">Your</span> Team's Answers
              </span>
            </md-table-cell>
            <md-table-cell v-bind:class="[{'correct-answers-cell': isPastIncomeStatement && currentIncomeStatement}, 'mobile-table-cell answers-container']"><span v-if="showTeamAnswers">Correct Answers</span></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('revenue')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('revenue')}, {'selected-cell': currentJournalEntryFilter === 'revenue'}]" class="sub-class category-label">
              Revenue
              <div v-if="currentJournalEntryFilter === 'revenue'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.revenue === null) && !disabledFields.includes('revenue')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('revenue')">{{currentIncomeStatement.revenue | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerRevenue" v-bind:class="[incorrectAnswerFields.includes('revenue') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('revenue')}]">
                  {{getCorrectAnswer("revenue") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isSalesReturnsEnabled()" @click="toggleJournalEntryFilter('returns')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('returns')}, {'selected-cell': currentJournalEntryFilter === 'returns'}]" class="sub-class category-label">
              Returns
              <div v-if="currentJournalEntryFilter === 'returns'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.returns === null) && !disabledFields.includes('returns')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('returns')">{{currentIncomeStatement.returns | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div v-bind:class="[incorrectAnswerFields.includes('returns') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('returns')}]">
                  {{getCorrectAnswer("returns") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isSalesReturnsEnabled()" @click="toggleJournalEntryFilter('net')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('net')}, {'selected-cell': currentJournalEntryFilter === 'net'}]" class="table-header category-label">
              Net
              <div v-if="currentJournalEntryFilter === 'net'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.net === null) && !disabledFields.includes('net')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('net')">{{currentIncomeStatement.net | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div v-bind:class="[incorrectAnswerFields.includes('net') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('net')}]">
                  {{getCorrectAnswer("net") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-bottom-table-cell-border" @click="toggleJournalEntryFilter('costOfGoodsSold')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('costOfGoodsSold')}, {'selected-cell': currentJournalEntryFilter === 'costOfGoodsSold'}]" class="table-header category-label">
              Cost of Goods Sold
              <div v-if="currentJournalEntryFilter === 'costOfGoodsSold'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.costOfGoodsSold === null) && !disabledFields.includes('costOfGoodsSold')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('costOfGoodsSold')">{{currentIncomeStatement.costOfGoodsSold | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div v-bind:class="[incorrectAnswerFields.includes('costOfGoodsSold') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('costOfGoodsSold')}]">
                  {{getCorrectAnswer("costOfGoodsSold") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-table-cell-border" @click="toggleJournalEntryFilter('grossProfit')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('grossProfit')}, {'selected-cell': currentJournalEntryFilter === 'grossProfit'}]" class="table-header category-label">
              Gross Profit
              <div v-if="currentJournalEntryFilter === 'grossProfit'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.grossProfit === null) && !disabledFields.includes('grossProfit')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('grossProfit')">{{currentIncomeStatement.grossProfit | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerGrossProfit" v-bind:class="[incorrectAnswerFields.includes('grossProfit') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('grossProfit')}]">
                  {{getCorrectAnswer("grossProfit") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="non-input-header">
            <md-table-cell class="table-header category-label">Operating Expense</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell v-if="showTeamAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row v-if="isAdvertisingEnabled()" @click="toggleJournalEntryFilter('adExpense')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('adExpense')}, {'selected-cell': currentJournalEntryFilter === 'adExpense'}]" class="sub-class category-label">
              {{getCustomLabelValue("advertisingExpense")}}
              <div v-if="currentJournalEntryFilter === 'adExpense'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.adExpense === null) && !disabledFields.includes('adExpense')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('adExpense')">{{currentIncomeStatement.adExpense | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerAdExpense" v-bind:class="[incorrectAnswerFields.includes('adExpense') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('adExpense')}]">
                  {{getCorrectAnswer("adExpense") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-table-cell-border" @click="toggleJournalEntryFilter('depreciationExpense')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('depreciationExpense')}, {'selected-cell': currentJournalEntryFilter === 'depreciationExpense'}]" class="sub-class category-label">
              Depreciation Expense
              <div v-if="currentJournalEntryFilter === 'depreciationExpense'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.depreciationExpense === null) && !disabledFields.includes('depreciationExpense')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('depreciationExpense')">{{currentIncomeStatement.depreciationExpense | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerDepreciationExpense" v-bind:class="[incorrectAnswerFields.includes('depreciationExpense') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('depreciationExpense')}]">
                  {{getCorrectAnswer("depreciationExpense") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="non-input-header">
            <md-table-cell class="table-header category-label">Other Income / Expenses</md-table-cell>
            <md-table-cell></md-table-cell>
            <md-table-cell v-if="showTeamAnswers"></md-table-cell>
          </md-table-row>

          <md-table-row @click="toggleJournalEntryFilter('interest')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('interest')}, {'selected-cell': currentJournalEntryFilter === 'interest'}]" class="sub-class category-label">
              Interest
              <div v-if="currentJournalEntryFilter === 'interest'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.interest === null) && !disabledFields.includes('interest')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('interest')">{{currentIncomeStatement.interest | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerInterest" v-bind:class="[incorrectAnswerFields.includes('interest') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('interest')}]">
                  {{getCorrectAnswer("interest") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isAccountsReceivableEnabled()" @click="toggleJournalEntryFilter('badDebtExpense')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('badDebtExpense')}, {'selected-cell': currentJournalEntryFilter === 'badDebtExpense'}]" class="sub-class category-label">
              Bad Debt Expense
              <div v-if="currentJournalEntryFilter === 'badDebtExpense'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.badDebtExpense === null) && !disabledFields.includes('badDebtExpense')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('badDebtExpense')">{{currentIncomeStatement.badDebtExpense | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerBadDebtExpense" v-bind:class="[incorrectAnswerFields.includes('badDebtExpense') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('badDebtExpense')}]">
                  {{getCorrectAnswer("badDebtExpense") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-bind:class="{'bottom-table-cell-border': isAdvertisingEnabled()}" @click="toggleJournalEntryFilter('gainOnAssetSales')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('gainOnAssetSales')}, {'selected-cell': currentJournalEntryFilter === 'gainOnAssetSales'}]" class="sub-class category-label">
              Sale of Land, Building & Equipment
              <div v-if="currentJournalEntryFilter === 'gainOnAssetSales'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.gainOnAssetSales === null) && !disabledFields.includes('gainOnAssetSales')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('gainOnAssetSales')">{{currentIncomeStatement.gainOnAssetSales | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerGainOnAssetSales" v-bind:class="[incorrectAnswerFields.includes('gainOnAssetSales') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('gainOnAssetSales')}]">
                  {{getCorrectAnswer("gainOnAssetSales") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isIncomeTaxEnabled()" class="bottom-table-cell-border">
            <md-table-cell class="table-header category-label">
              Income Before Tax
              <div v-if="currentJournalEntryFilter === 'total'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.total === null) && !disabledFields.includes('total')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('total')">{{currentIncomeStatement.total | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerTotal" v-bind:class="[incorrectAnswerFields.includes('total') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('total')}]">
                  {{getCorrectAnswer("total") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isIncomeTaxEnabled()" class="bottom-table-cell-border" @click="toggleJournalEntryFilter('incomeTax')">
            <md-table-cell :class="[{'link selectable-cell': showCorrectAnswers && isSplitView && currentAvailableJournalEntryFilters && currentAvailableJournalEntryFilters.includes('incomeTax')}, {'selected-cell': currentJournalEntryFilter === 'incomeTax'}]" class="table-header category-label">
              Income Tax Expense
              <div v-if="currentJournalEntryFilter === 'incomeTax'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.incomeTax === null) && !disabledFields.includes('incomeTax')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('incomeTax')">{{currentIncomeStatement.incomeTax | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerIncomeTax" v-bind:class="[incorrectAnswerFields.includes('incomeTax') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('incomeTax')}]">
                  {{getCorrectAnswer("incomeTax") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">
              Net Income
              <div v-if="currentJournalEntryFilter === 'netIncome'" class="clear-filter">Clear Filter</div>
            </md-table-cell>
            <md-table-cell v-if="showTeamAnswers || isEditable" class="category-value">
              <div class="answers-container">
                <div v-if="(!currentIncomeStatement || currentIncomeStatement.netIncome === null) && !disabledFields.includes('netIncome')">
                  <span>blank</span>
                </div>
                <div v-else>
                  <span v-if="!disabledFields.includes('netIncome')">{{currentIncomeStatement.netIncome | toCurrency}}</span>
                </div>
              </div>
            </md-table-cell>
            <md-table-cell class="correct-answers-cell" v-if="currentIncomeStatement && showCorrectAnswers">
              <div class="answers-container">
                <div ref="correctAnswerNetIncome" v-bind:class="[incorrectAnswerFields.includes('netIncome') ? 'incorrect-answer' : 'correct-answer', {'black-text' : !showTeamAnswers || disabledFields.includes('netIncome')}]">
                  {{getCorrectAnswer("netIncome") | toCurrency}}
                </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>

      <div v-if="allowSubmissionWhenValidGameConfigAndGameState && isEditing && !isPastIncomeStatement" class="input-table">
        <md-table>
          <md-table-toolbar class="table-title">
            <h1 class="md-title text-center title-text">
              INCOME STATEMENT
            </h1>
          </md-table-toolbar>

          <md-table-row class="non-input-header">
            <md-table-cell class="table-header">Sales</md-table-cell>
            <md-table-cell>
              <span class="md-layout">*for negative values, prefix your input with either "-" or "("</span>
              <span class="md-layout">$ = Field submission required for grading</span>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="sub-class category-label">Revenue</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('revenue')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.revenue">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isSalesReturnsEnabled()">
            <md-table-cell class="sub-class category-label">Returns</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('returns')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.returns">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isSalesReturnsEnabled()">
            <md-table-cell class="table-header category-label">Net</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('net')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.net">
                  </currency-input>
                  <md-input v-else disabled class="non-editable-input">
                  </md-input>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="top-bottom-table-cell-border">
            <md-table-cell class="table-header category-label">Cost of Goods Sold</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('costOfGoodsSold')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.costOfGoodsSold">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-table-cell-border">
            <md-table-cell class="table-header category-label">Gross Profit</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('grossProfit')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.grossProfit">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="non-input-header">
            <md-table-cell class="table-header category-label">Operating Expense</md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row v-if="isAdvertisingEnabled()">
            <md-table-cell class="sub-class category-label">{{getCustomLabelValue("advertisingExpense")}}</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('adExpense')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.adExpense">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="bottom-table-cell-border">
            <md-table-cell class="sub-class category-label">Depreciation Expense</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('depreciationExpense')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.depreciationExpense">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row class="non-input-header">
            <md-table-cell class="table-header category-label">Other Expenses</md-table-cell>
            <md-table-cell></md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="sub-class category-label">Interest</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('interest')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.interest">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isAccountsReceivableEnabled()">
            <md-table-cell class="sub-class category-label">Bad Debt Expense</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('badDebtExpense')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.badDebtExpense">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-bind:class="{'bottom-table-cell-border': isAdvertisingEnabled()}">
            <md-table-cell class="sub-class category-label">Sale of Land, Building & Equipment</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('gainOnAssetSales')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.gainOnAssetSales">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isIncomeTaxEnabled()" class="bottom-table-cell-border">
            <md-table-cell class="table-header category-label">Income Before Tax</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('total')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.total">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row v-if="isIncomeTaxEnabled()" class="bottom-table-cell-border">
            <md-table-cell class="table-header category-label">Income Tax Expense</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('incomeTax')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.incomeTax">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell class="table-header category-label">Net Income</md-table-cell>
            <md-table-cell>
              <div class="currency-input-container">
                <md-field>
                  <currency-input v-if="!disabledFields.includes('netIncome')" :allows-null-val=true :allows-negative=true :currency-to-format.sync="incomeStatementInput.netIncome">
                  </currency-input>
                  <div v-else>
                    <md-tooltip class="tooltip" md-direction="left">Submission is not required for this field</md-tooltip>
                    <md-input disabled class="non-editable-input">
                    </md-input>
                  </div>
                </md-field>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
    <div v-else v-bind:class="{'game-state-disabled-label': gameStateDisabledLabel !== ''}">
      <span style="color: var(--text-primary);"> {{gameStateDisabledLabel}} </span>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import CurrencyInput from './CurrencyInput';
import Countdown from './Countdown';
import { MdContent } from 'vue-material/dist/components'
import { MdTable } from 'vue-material/dist/components'
import { MdCard } from 'vue-material/dist/components'
import { MdField } from 'vue-material/dist/components'
import { DateTime } from 'luxon'
import { mapGetters, mapActions } from 'vuex'
import {
  GameRoundPhase,
  RoundModuleDisplaySettingEnum,
  UserTypeEnum
} from "temporary-api-types/apiTypes";

Vue.use(MdContent)
Vue.use(MdTable)
Vue.use(MdCard)
Vue.use(MdField)

export default {
  name: 'IncomeStatement',
  components: {
    Countdown,
    'currency-input': CurrencyInput
  },
  data() {
    return {
      attrs: {
        currentTeamHasSubmittedIncomeStatement: this.teamHasSubmittedIncomeStatement,
        currentJournalEntryFilter: this.journalEntryFilter,
        currentAvailableJournalEntryFilters: this.availableJournalEntryFilters
      },
      isEditing: false,
      currentIncomeStatement: {},
      incomeStatementInput: {
        roundConfigId: null,
        revenue: null,
        returns: null,
        net: null,
        costOfGoodsSold: null,
        grossProfit: null,
        adExpense: null,
        depreciationExpense: null,
        interest: null,
        gainOnAssetSales: null,
        total: null,
        incomeTax: null,
        netIncome: null
      },
      dismissAlert: false,
      teamId: null,
      isPastIncomeStatement: false,
      incorrectAnswerFields: [],
      correctAnswers: null,
      roundNum: null,
      pastIncomeStatementCorrect: null,
      pastIncomeStatementTotal: null,
      currentGamePhase: null,
      showFormError: false,
      errorMessage: "Cannot submit. Check form and try again",
      isPageReady: false,
      hasSuccessfullySubmitted: false,
      disabledFields: [],
      gameStateDisabledLabel: null,
      showTeamAnswers: false,
      showCorrectAnswers: false,
      isEditable: false,
      UserTypeEnum
    }
  },
  props: {
    allowSubmissionWhenValidGameConfigAndGameState: {
      type: Boolean
    },
    activeRoundId: {
      type: String
    },
    selectedTeam: {
      type: Object
    },
    teamHasSubmittedIncomeStatement: {
      type: Boolean
    },
    journalEntryFilter: {
      type: String
    },
    isSplitView: {
      type: Boolean
    },
    availableJournalEntryFilters: {
      type: Array
    }
  },
  watch: {
    selectedTeam(val) {
      if(val) {
        this.initIncomeStatement();
      }
    },
    activeRoundId(val) {
      if(val) {
        this.initIncomeStatement();
      }
    },
    journalEntryFilter(val) {
      this.currentJournalEntryFilter = val;
    },
    availableJournalEntryFilters(val) {
      this.currentAvailableJournalEntryFilters = val;
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentIncomeStatement',
      'getCurrentRoundConfig',
      'getCurrentCourse',
      'getCurrentGameState',
      'getNextDeadline',
      'getFormattedDate',
      'getUserType',
      'getGameLabels'
      ]),
    currentDeadline() {
      return this.getNextDeadline;
    },
    currentTeamHasSubmittedIncomeStatement: {
      get() {
        return this.attrs.currentTeamHasSubmittedIncomeStatement;
      },
      set(value) {
        this.attrs.currentTeamHasSubmittedIncomeStatement = value;
        this.$emit(`update:team-has-submitted-income-statement`, value);
      }
    },
    currentJournalEntryFilter: {
      get() {
        return this.attrs.currentJournalEntryFilter;
      },
      set(value) {
        this.attrs.currentJournalEntryFilter = value;
        this.$emit(`update:journal-entry-filter`, value);
      }
    },
    currentAvailableJournalEntryFilters: {
      get() {
        return this.attrs.currentAvailableJournalEntryFilters;
      },
      set(value) {
        this.attrs.currentAvailableJournalEntryFilters = value;
        this.$emit(`update:available-journal-entry-filters-list`, value);
      }
    },
    shouldShowCountdown() {
      const nowSeconds = DateTime.local().toSeconds();
      const nextDeadlineSeconds = this.getNextDeadline;
      if(isNaN(nextDeadlineSeconds)) {
        return false;
      }
      // show countdown if time is less than 1 hour
      return nextDeadlineSeconds - nowSeconds <= 3599;
    }
  },
  methods: {
    ...mapActions([
      'fetchIncomeStatement',
      'submitIncomeStatement',
      'fetchGradingReport'
    ]),
    getCustomLabelValue(key) {
      if(this.getGameLabels) {
        const found = this.getGameLabels.find(label => label.key === key);
        return found ? found.value : "";
      }
      return "";
    },
    checkGameStateAndConfig() {
      this.gameStateDisabledLabel = null;
      this.isEditable = false;
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      const isConfig = gameConfig.roundFeatures.incomeStatementConfig[roundIndex].roundSetting;
      if(isConfig === RoundModuleDisplaySettingEnum.Off) {
        const verb = this.isPastIncomeStatement ? 'was' : 'is';
        // setting gameStateDisabledLabel label to an empty string omits the entire UI (mainly used in competitor financials when nothing should show due to the round setting)
        this.gameStateDisabledLabel = this.allowSubmissionWhenValidGameConfigAndGameState ? `Income Statement submission ${verb} not required in Round ${roundIndex + 1}` : "";
        this.showTeamAnswers = false;
        this.showCorrectAnswers = false;
      } else if(isConfig === RoundModuleDisplaySettingEnum.ShowForPastRounds) {
        if(this.getUserType === UserTypeEnum.Student && !this.isPastIncomeStatement) {
          this.gameStateDisabledLabel = `Income Statement submission is not required in Round ${roundIndex + 1}`
        }
        this.showTeamAnswers = false;
        this.showCorrectAnswers = true;
      } else if(isConfig === RoundModuleDisplaySettingEnum.ShowForAllRounds) {
        this.showTeamAnswers = false;
        this.showCorrectAnswers = true;
      } else if(isConfig === RoundModuleDisplaySettingEnum.Submit) {
        this.showTeamAnswers = (this.allowSubmissionWhenValidGameConfigAndGameState && this.getUserType !== UserTypeEnum.Student) || (this.allowSubmissionWhenValidGameConfigAndGameState && this.isPastIncomeStatement);
        this.isEditable = this.getUserType === UserTypeEnum.Student && !this.isPastIncomeStatement;
        this.showCorrectAnswers = this.getUserType !== UserTypeEnum.Student || this.isPastIncomeStatement;
      }
    },
    toggleIsEditing() {
      this.isEditing = !this.isEditing;
    },
    async submitCurrentIncomeStatement() {
      if(!this.incomeStatementInput["roundConfigId"]) {
        this.incomeStatementInput["roundConfigId"] = this.getCurrentRoundConfig.id;
      }
      this.incomeStatementInput.teamId = this.teamId;
      try {
        await this.submitIncomeStatement(this.incomeStatementInput);
        this.currentIncomeStatement = this.getCurrentIncomeStatement;
        for(let input in this.incomeStatementInput) {
          this.incomeStatementInput[input] = this.incomeStatementInput[input];
        }
        this.isEditing = false;
        this.dismissAlert = false;
        this.hasSuccessfullySubmitted = true;
        this.currentTeamHasSubmittedIncomeStatement = true;
      } catch(err) {
        this.hasSuccessfullySubmitted = false;
        if(err == 'Error: GraphQL error: The submission deadline has passed') {
          this.errorMessage = "The submission deadline has passed"
        }
        this.dismissAlert = false;
        this.showFormError = true;
        return err;
      }
    },
    clearIncomeElections() {
      this.incomeStatementInput = {
        revenue: null,
        returns: null,
        net: null,
        costOfGoodsSold: null,
        grossProfit: null,
        adExpense: null,
        depreciationExpense: null,
        interest: null,
        gainOnAssetSales: null,
        total: null,
        incomeTax: null,
        netIncome: null
      };
      this.isEditing = false;
    },
    clearAlert() {
      this.dismissAlert = true;
    },
    async initIncomeStatement() {
      const gameState = this.getCurrentGameState;
      this.currentGamePhase = gameState.phase;
      this.clearIncomeElections();
      this.teamId = this.selectedTeam ? this.selectedTeam.id : this.$route.params.teamId;
      await this.fetchIncomeStatement({
        roundConfigId: this.activeRoundId,
        teamId: this.teamId
      });
      if(this.activeRoundId) {
        if(this.activeRoundId !== this.getCurrentRoundConfig.id || this.currentGamePhase === GameRoundPhase.GameOver || this.currentGamePhase === GameRoundPhase.Graded) {
          this.isPastIncomeStatement = true;
          this.roundNum = this.getCurrentCourse.rounds.map((_round) => _round.id).indexOf(this.activeRoundId) + 1;
        } else {
          this.isPastIncomeStatement = false;
        }
      }
      this.currentIncomeStatement = this.getCurrentIncomeStatement;
      if(!this.isPastIncomeStatement) {
        for(let input in this.currentIncomeStatement) {
          if(this.incomeStatementInput.hasOwnProperty(input)) {
            this.incomeStatementInput[input] = this.currentIncomeStatement[input];
          }
        }
        this.incomeStatementInput["roundConfigId"] = this.getCurrentRoundConfig.id;
      } else {
        this.getPastReport();
      }
      if(this.currentIncomeStatement) {
        this.incorrectAnswerFields = !this.currentIncomeStatement.incorrectAnswers ? [] : this.currentIncomeStatement.incorrectAnswers.map((_incorrectAnswer) => _incorrectAnswer.fieldName);
      }
      if(!this.allowSubmissionWhenValidGameConfigAndGameState) {
        this.isEditing = false;
      }
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      this.disabledFields = gameConfig.roundFeatures.incomeStatementConfig[roundIndex].disabledFields;
      if(!this.disabledFields) {
        this.disabledFields = [];
      }
      if(this.getCurrentIncomeStatement && this.getCurrentIncomeStatement.submittedBy) {
        this.currentTeamHasSubmittedIncomeStatement = true;
      }
      this.checkGameStateAndConfig();
      this.currentJournalEntryFilter = null;
      if(!this.currentAvailableJournalEntryFilters) {
        this.currentAvailableJournalEntryFilters = [];
      }
      this.isPageReady = true;
    },
    getActiveRoundIndex() {
      const courseRounds = this.getCurrentCourse.rounds;
      return courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
    },
    isAdvertisingEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      if(roundIndex > -1) {
        const adConfig = gameConfig.roundFeatures.advertising[roundIndex];
        return adConfig.cost > 0 || adConfig.benefit > 0;
      }
    },
    isSalesReturnsEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      if(roundIndex > -1) {
        return !!gameConfig.roundFeatures.salesReturns[roundIndex];
      }
      return false;
    },
    isIncomeTaxEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const roundIndex = this.getActiveRoundIndex();
      if(roundIndex > -1) {
        const taxRate = gameConfig.roundFeatures.incomeTax[roundIndex];
        return taxRate > 0;
      }
      return false;
    },
    isAccountsReceivableEnabled() {
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const courseRounds = this.getCurrentCourse.rounds;
      const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
      const currentCourseRounds = courseRounds.slice(0, roundIndex + 1);

      for(let i = 0; i < currentCourseRounds.length; i++) {
        const outstandingPercent = gameConfig.roundFeatures.accountsReceivable[i].outstandingPercent;
        if(outstandingPercent > 0) {
          return true;
        }
      }
      return false;
    },
    getCorrectAnswer(fieldName) {
      if(this.disabledFields.includes(fieldName)) {
        const answer = this.currentIncomeStatement.disabledFieldAnswers.find((answer) => answer.fieldName === fieldName);
        if(!answer) {
          return '';
        }
        return answer.correctAnswer;
      }

      if(this.incorrectAnswerFields.includes(fieldName)) {
        const answer = this.currentIncomeStatement.incorrectAnswers?.find((answer) => answer.fieldName === fieldName);
        if(!answer) {
          return '';
        }
        return answer.correctAnswer;
      }

      const answer = this.currentIncomeStatement.correctAnswers?.find((answer) => answer.fieldName === fieldName);
      if(answer) {
        return answer.correctAnswer;
      }

      return this.currentIncomeStatement[fieldName];
    },
    async getPastReport() {
      try {
        const gradeReport = await this.fetchGradingReport({
          roundConfigId: this.activeRoundId,
          teamId: this.teamId
        });
        this.pastIncomeStatementCorrect = gradeReport.incomeStatementNumCorrect;
        this.pastIncomeStatementTotal = gradeReport.incomeStatementTotal;
      } catch(error) {
        return error;
      }
    },
    cancelCurrentIncomeStatement() {
      if(this.currentIncomeStatement) {
        for(let input in this.currentIncomeStatement) {
          if(this.incomeStatementInput.hasOwnProperty(input)) {
            this.incomeStatementInput[input] = this.currentIncomeStatement[input];
          }
        }
      } else {
        this.clearIncomeElections();
      }
      this.isEditing = false;
    },
    toggleJournalEntryFilter(filter) {
      if(this.showCorrectAnswers && this.isSplitView && this.currentAvailableJournalEntryFilters.includes(filter)) {
        if(this.currentJournalEntryFilter === filter) {
          this.currentJournalEntryFilter = null;
        } else {
          this.currentJournalEntryFilter = filter;
        }
      }
    }
  },
  created() {
    if(this.activeRoundId && this.selectedTeam) {
      this.initIncomeStatement();
    }
  }
}
</script>

<style scoped>
  h1 {
    padding: 1em;
  }

  span {
    color: var(--text-primary);
  }
  .main-box {
    border: 1px solid black;
    margin: 1em;
    text-align: left;
  }
  .table-title {
    background-color: var(--table-title-bg-color) !important;
  }
  .title-text {
    font-weight: bold;
    color: white !important;
  }
  .text-center {
    text-align: center;
  }
  .btn-group {
    padding: 1em;
    display: table;
  }
  .table-header {
    font-weight: bold;
  }
  .sub-class {
    padding-left: 30px;
  }
  .category-label {
    border-right: 1px solid rgba(0,0,0,0.12);
  }
  .error {
    color: #ff1744;
  }
  .form-error {
    display: block!important;
    left: 0;
    height: 20px;
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    transition: .3s cubic-bezier(.4,0,.2,1);
  }
  .btn-rounded {
    border-radius: 4px;
  }
  .save-btn {
    color: white !important;
    background-color: var(--btn-primary-color) !important;
  }
  .cancel-btn {
    color: #000000 !important;
    background-color: var(--btn-secondary-color) !important;
  }
  .alert {
    margin-left: 1em;
    margin-right: 1em;
  }
  .alert-error {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #ff1744;
    color: white;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
  }
  .fa.fa-times-circle.light {
    color: white;
    cursor: pointer;
  }
  .income-statement-container {
    padding-bottom: 64px;
  }
  .incorrect-answer {
    color: var(--warning-text-color);
  }
  .correct-answer {
    color: var(--success-bg-color);
  }
  .category-value {
    text-align: center;
    max-width: 100px;
    min-width: 100px;
  }
  .md-field {
    min-height: 0px;
    margin: 0;
    padding-top: 0px;
  }
  .currency-input-container {
    float: right;
  }
  .answers-container {
    text-align: center;
  }
  .remaining-label {
    margin-top: -8px;
  }
  .correct-answers-cell {
    border-left: 1px solid rgba(0,0,0,0.12);
    font-weight: bold;
  }
  @media screen and (max-width: 550px){
    .currency-input-container {
      float: none;
    }
  }
  @media screen and (max-width: 800px){
    .mobile-table-cell {
      width: 50%;
    }
  }
  .text-next-to-button {
    display: table-cell;
    vertical-align: middle;
  }
  .black-text {
    color: black;
  }

  .no-sheet {
    margin-top: 20px;
  }

  .game-state-disabled-label {
    margin-top: 150px;
    font-weight: bold;
    border: 1px solid var(--text-primary);
    display: inline-block;
    padding: 15px;
  }

  .selectable-cell {
    margin: -5px;
    border: 2px solid var(--btn-primary-color) !important;
  }

  .selected-cell {
    background: rgba(67,176,249,0.12) !important;
  }

  .selected-cell:hover {
    background: rgba(67,176,249,0.12) !important;
  }

  .selected-color-indicator {
    color: var(--btn-primary-color);
  }

  .clear-filter {
    position: absolute;
    font-weight: bold;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--btn-primary-color);
  }
</style>
