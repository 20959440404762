<template>
  <div class="md-layout" style="justify-content: center; align-items: center; height: 100vh;">
    <div class="md-layout-item md-large-size-50 md-medium-size-50 md-small-size-70 md-xsmall-size-80 main-box">
      <div class="md-layout">
        <div :class="[{'md-large-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100': getUserType !== UserTypeEnum.Admin}, {'md-size-100': getUserType === UserTypeEnum.Admin}, 'md-layout-item']">
          <div class="login-wrapper">
            <h1>Welcome Back!</h1>
            <h2><span v-if="getUserType">{{getUserTypeHeader()}}</span> Login</h2>
          </div>
          <div v-if="getUserType" class="login-form-container">
            <div class="notify-message" v-if="notifyMessage">{{notifyMessage}}</div>
            <div class="md-layout">
              <div class="md-layout-item"></div>
              <md-field class="md-layout-item md-large-size-66 md-medium-size-50 md-small-size-70 md-xsmall-size-80">
                <label style="color: var(--text-primary);">Email</label>
                <md-input v-model="currentEmail" type="email"></md-input>
                <span v-if="inputError === 'email'" class="error form-error">Please enter a valid email</span>
                <span v-if="inputError === 'emailForgotPassword'" class="error form-error">Forgot password requires a valid email</span>
              </md-field>
              <div class="md-layout-item"></div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item"></div>
              <md-field class="md-layout-item md-large-size-66 md-medium-size-50 md-small-size-70 md-xsmall-size-80">
                <label style="color: var(--text-primary);">Password</label>
                <md-input v-model="password" type="password"></md-input>
                <span v-if="inputError === 'password'" class="error form-error">Please enter password</span>
              </md-field>
              <div class="md-layout-item"></div>
            </div>
            <div class="forgot-password-container">
              <div class="text-center">
                <span class="blue-link link underline" @click="initiateForgotPassword">Forgot Password?</span>
              </div>
            </div>
            <div class="btn-group">
              <md-button :disabled="httpWait" @click="submitLogin()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
                Login
                <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
              </md-button>
            </div>
            <span class="error">{{serverError}}</span>
          </div>
        </div>
        <div v-if="getUserType !== UserTypeEnum.Admin" class="md-layout-item md-large-size-33 md-medium-size-33 md-small-size-100 md-xsmall-size-100">
          <div v-if="getUserType" class="register-action-container">
            <div class="register-wrapper">
            </div>
            <div><h3>New User?</h3></div>
            <div><h3>Create an Account</h3></div>
            <div class="btn-group">
              <md-button @click="goToOnboard()" class="md-raised btn-primary">Here</md-button>
            </div>
            <span class="error">{{serverError}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { MdButton } from 'vue-material/dist/components'
import { MdField } from 'vue-material/dist/components'
import { MdProgress } from 'vue-material/dist/components'
import { mapGetters, mapActions } from 'vuex'
import { UserTypeEnum } from "temporary-api-types/apiTypes";

Vue.use(MdButton);
Vue.use(MdField);
Vue.use(MdProgress);
export default {
  name: 'Login',
  data() {
    return {
      password: "",
      attrs: {
        currentEmail: ""
      },
      inputError: "",
      serverError: "",
      url: process.env.VUE_APP_LAMBDA_URL,
      UserTypeEnum,
      httpWait: false
    }
  },
  computed: {
    ...mapGetters([
      'getUserType',
      'getTeam',
      'getSemesterList'
    ]),
    currentEmail: {
      get() {
        return this.attrs.currentEmail;
      },
      set(value) {
        this.attrs.currentEmail = value;
      }
    }
  },
  methods: {
    ...mapActions([
      'login',
      'setUserToken',
      'setUserRefreshToken',
      'setUserTokenExpTime',
      'fetchSemesterList',
      'getStudentTeam',
      'setAccessToken',
      'configureUserType',
      'configureUserEmail',
      'validateAdminLicense'
    ]),
    back() {
      this.password = "";
    },
    async initiateForgotPassword() {
      if(this.validateEmail(this.currentEmail)) {
        const data = {
          email: this.currentEmail,
          groupName: this.getUserType
        };
        try {
          const response = await fetch(`${this.url}/forgotPassword`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'POST'
            },
            body: JSON.stringify(data)
          });
          if(response.ok) {
            this.notifyMessage = "A message has been sent to your email to reset your password"
          } else {
            this.notifyMessage = "Could not initiate reset password"
            return this.serverError;
          }
        } catch(err) {
          this.serverError = "Something went wrong. Please try again";
          return err;
        }
      } else {
        this.inputError = "emailForgotPassword";
      }
    },
    getUserTypeHeader() {
      if(this.getUserType === UserTypeEnum.Professor) {
        return "Professor";
      } else if(this.getUserType === UserTypeEnum.Student) {
        return "Student";
      } else if(this.getUserType === UserTypeEnum.Admin) {
        return "Administrator";
      }
    },
    validateForm() {
      if(this.validateEmail(this.currentEmail)) {
        if(!this.password) {
          this.inputError = "password";
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    validateEmail(email) {
      let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(email && re.test(String(email).toLowerCase())) {
        return true;
      } else {
        this.inputError = "email";
        return false;
      }
    },
    async submitLogin() {
      this.httpWait = true;
      if(this.validateForm()) {
        const data = {
          authParameters: {
            USERNAME: this.currentEmail.toLowerCase(),
            PASSWORD: this.password
          },
          groupName: this.getUserType
        };
        try {
          const response = await fetch(`${this.url}/authentication`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'POST'
            },
            body: JSON.stringify(data)
          });
          const responseJson = await response.json();          
          if(response.ok) {
            const token = responseJson.input["AuthenticationResult"]["IdToken"];
            const accessToken = responseJson.input["AuthenticationResult"]["AccessToken"];
            const tokenExpSeconds = responseJson.input["AuthenticationResult"]["ExpiresIn"];
            const refreshToken = responseJson.input["AuthenticationResult"]["RefreshToken"];
            const currentTimestamp = Date.now();
            const tokenExpTime = currentTimestamp + (tokenExpSeconds * 1000);
            await this.setUserToken(token);
            await this.setAccessToken(accessToken);
            await this.configureUserEmail(this.currentEmail.toLowerCase());
            await this.setUserTokenExpTime(tokenExpTime);
            await this.setUserRefreshToken(refreshToken);
            if(this.getUserType === UserTypeEnum.Admin) {
              try {
                await this.validateAdminLicense({
                  userEmail: this.currentEmail.toLowerCase()
                });

                this.httpWait = false;
                this.$router.push({
                  name: 'AdminHome'
                });
              } catch(err) {
                if(err.graphQLErrors && err.graphQLErrors.length) {
                  const errorMessage = err.graphQLErrors[0].message;
                  if(errorMessage) {
                    this.serverError = errorMessage;
                  }
                } else {
                  this.serverError = "Something went wrong. Please try again";
                }
                this.httpWait = false;
              }
            } else {
              try {
                await this.fetchSemesterList();
                this.httpWait = false;
              } catch(err) {
                if(err.graphQLErrors && err.graphQLErrors.length) {
                  const errorMessage = err.graphQLErrors[0].message;
                  if(errorMessage === "user license is revoked, no repurchase") {
                    this.serverError = "This license has been revoked and flagged. Please contact Accountonomics support for more details.";
                  } 
                  if(errorMessage === "user license is revoked") {
                    this.serverError = "Invalid license. Please try purchasing a new one";
                  }
                } 
                this.httpWait = false;
                return;
              }
              this.$router.push({
                name: 'Semester'
              });
            }
          } else {
            if(responseJson.code === "UserNotFoundException" || responseJson.message === "Incorrect username or password.") {
              this.serverError = "Incorrect username or password";
            } else if(responseJson.message === "not authorized") {
              this.serverError = "Not authorized";
            } else {
              this.serverError = "Something went wrong. Please try again";
            }
            this.httpWait = false;
            return this.serverError;
          }
        } catch(err) {
          this.serverError = "Something went wrong. Please try again";
          this.httpWait = false;
          return err;
        }
      }
      this.httpWait = false;
    },
    goToOnboard() {
      this.$router.push({
        name: 'Onboard',
        params: {
          showBackButton: true,
          successCb: this.onboardSuccessCallback
        }
      });
    },
    onboardSuccessCallback() {
      this.$router.push({
        name: 'Semester'
      });
		},
    enableSubmitOnEnterKey() {
      document.onkeydown = (e) => {
        e = e || window.event;
        if(e.which === 13 || e.keyCode === 13) {
          this.submitLogin();
        }
      }
    }
  },
  props: {
    notifyMessage: String,
    email: String,
  },
  async created() {
    if(localStorage.getItem("isDarkMode") === "true") {
			document.body.classList.add('dark-mode');
		} else {
			document.body.classList.remove('dark-mode');
		}
    await this.configureUserType();
    if(this.$route.params.notifyMessage) {
      this.notifyMessage = this.$route.params.notifyMessage;
    }
    if(this.email) {
      this.currentEmail = this.email.toLowerCase();
    }
    window.addEventListener('keydown', this.enableSubmitOnEnterKey);
  },
  destroyed () {
    window.removeEventListener('keydown', this.enableSubmitOnEnterKey);
  },
}
</script>

<style scoped>

h1, h2, h3, span, md-input {
  color: var(--text-primary);
}


  .login-wrapper {
    padding: 1em;
    height: 125px;
  }
  .register-wrapper {
    padding: 1em;
    height: 125px;
  }
 .main-box {
    background-color: var(--main-box-color);
    box-shadow: 5px 5px 3px var(--box-shadow-color);
    margin: 1em;
    min-height: 280px;
  }
  .text-center {
    text-align: center;
  }
  .btn-group {
    padding: 1em;
  }
  .error {
    color: #ff1744;
  }
  .form-error {
    display: block!important;
    left: 0;
    height: 20px;
    position: absolute;
    bottom: -22px;
    font-size: 12px;
    transition: .3s cubic-bezier(.4,0,.2,1);
  }
  .notify-message {
    color: var(--onboard-message-color);
  }

  .underline:hover {
    text-decoration: underline;
  }
  .btn-wrapper {
		display: inline-block;
	}
	.btn-container {
		padding-top: 20px;
		padding-left: 20px;
		text-align: left;
	}

  @media screen and (max-width: 960px){
    .register-wrapper {
      padding: 0;
      height: 0;
    }
  }
</style>