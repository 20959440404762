<template>
  <div class="round-nav-container" :class="{'menu-open-z-index': isMenuOpen}" ref="round-nav" v-if="isPageReady">
    <md-app md-mode="reveal">
      <md-app-toolbar class="nav-color md-layout app-toolbar">
        <div v-if="currentDeadline">
          <Countdown :deadline.sync="currentDeadline" v-on:ended="countdownEnded()" :hide-ui="true" :countdown-id="'hidden'"></Countdown>
        </div>
        <div class="md-layout-item md-size-20 text-left">
          <span class="nav-title-left-padding">
            <span class="link nav-title" @click="goToHome()">Account-o-nomics</span>
          </span>
        </div>
        <div v-if="routeDetail !== 'is-paused'" class="md-layout-item md-size-20">
          <div :class="isScoreboardVisible ? 'tab-active' : 'tab-inactive'" class="nav-left">
            <div class="link scoreboard-title" @click="toggleScoreboardMenu()">
              <span class="nav-title">Scoreboard</span>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-5">
          <div :class="isMessageCenterVisable ? 'tab-active' : 'tab-inactive'" class="nav-left">
            <div class="link message-center-title" @click="toggleMessageCenterMenu()">
              <md-badge class="custom-badge" v-if="numUncheckedMessages > 0" :md-content="numUncheckedMessages">
                <md-icon class="far fa-bell hidden"></md-icon>
              </md-badge>
              <md-icon class="far fa-bell" :class="{'light' : !isMessageCenterVisable}"></md-icon>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-5">
          <div :class="isGlossaryVisible ? 'tab-active' : 'tab-inactive'" class="nav-left">
            <div class="link glossary-title" @click="toggleGlossaryMenu()">
              <md-icon class="far fa-list-alt" :class="{'light' : !isGlossaryVisible}"></md-icon>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-5">
          <div class="tab-inactive">
            <div class="link dark-mode" @click="toggleDarkMode()">
              <div v-if="isDarkMode">
                <md-icon class="fas fa-moon" style="color: var(--moon-color);"></md-icon>
              </div>
              <div v-else>
                <md-icon class="far fa-moon" style="color: var(--moon-color);"></md-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-45">
          <div class="nav-wrapper">
            <div :class="isTeamMenuVisible ? 'tab-active' : 'tab-inactive'" class="nav-left">
              <div :class="isLongTeamName() ? 'nav-team-name-title-long' : 'nav-team-name-title'">
                <div class="link" @click="toggleTeamMenu()">
                  <div v-bind:class="{'nav-team-title-right': !isLongTeamName(), 'nav-team-title-right-long': isLongTeamName()}"><span class="nav-title">{{selectedTeam.name}}</span></div>
                </div>
              </div>
            </div>
            <div v-if="getCurrentGameState.phase !== 'HASNT_STARTED' && getCurrentGameState.phase !== 'CALC_WAITING' && getCurrentGameState.phase !== 'GRADE_WAITING' && routeDetail !== 'is-paused'" :class="isRoundMenuVisible ? 'tab-active' : 'tab-inactive'" class="nav-right nav-round-title">
              <div v-if="isRoundMenuVisible">
                <div class="link" @click="toggleRoundMenu()">
                    <div class="md-layout md-alignment-center">
                      <div class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 nav-round-title-left"><span class="nav-title">SELECT</span></div>
                      <div class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 nav-round-title-right"><span class="nav-title">ROUND</span></div>
                    </div>
                </div>
              </div>
              <div v-if="!isRoundMenuVisible">
                <div class="link" @click="toggleRoundMenu()">
                  <div class="md-layout md-alignment-center">
                    <div v-if="!currentRoundString" class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 nav-round-title-left">SELECT</div>
                    <div v-bind:class="[{'nav-round-title-left': currentRoundString !== null, 'nav-round-title-right': currentRoundString === null}, 'md-layout-item', 'md-medium-size-33', 'md-small-size-50', 'md-xsmall-size-100']"><span class="nav-title">ROUND</span></div>
                    <div v-if="currentRoundString !== null" class="md-layout-item md-medium-size-33 md-small-size-50 md-xsmall-size-100 nav-round-title-right"><span class="nav-title">{{currentRoundString}}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </md-app-toolbar>
    </md-app>
    <div v-if="getCurrentGameState.phase === 'HASNT_STARTED'" class="md-layout-item deadline-title">
      <div><strong>{{ getDeadlineMessage() }}</strong> <strong> {{getDeadlineDate()}} </strong></div>
      <div v-if="currentDeadline">
        <Countdown :deadline="currentDeadline" :countdown-id="'wait'"></Countdown>
      </div>
    </div>
    <div class="scoreboard" v-if="isScoreboardVisible && routeDetail !== 'is-paused'">
      <scoreboard :active-round-id="currentActiveRoundId"></scoreboard>
    </div>
    <div class="message-center" v-if="isMessageCenterVisable">
      <message-center :team="selectedTeam" :num-unchecked-messages.sync="numUncheckedMessages"></message-center>
    </div>
    <div class="glossary" v-if="isGlossaryVisible">
      <glossary></glossary>
    </div>
    <!-- begin round menu -->
    <div class="round-nav text-left" v-if="isRoundMenuVisible" ref="round-nav-scroll">
      <div class="md-layout">
        <div class="md-layout-item">
          <div v-for="round in rounds" v-bind:key="round.id">
            <div class="md-title">
              <div v-bind:class="{disabled: round.isFuture}" class="round-title">Round {{round.roundNum}}</div>
              <div v-if="round.isPast">
                <div class="deadline-title">MARKET ENDED {{getPastDeadline(round.roundNum)}}</div>
                <div class="link round-link" @click="goToRoute('competitor-financials', round)">Competitor Financials</div>
                <div v-if="showJournalEntryTAccountLink(round)" class="link round-link" @click="goToRoute('round-report', round)">Journal Entries and T Accounts</div>
                <div class="link round-link" @click="goToRoute('round-report', round)">Round Report</div>
                <div class="link round-link" @click="goToFinancialsWithOptions('income-statement', round, false)">
                  Financial Statements
                  <span class="total-round-score">{{round.combinedNumCorrect}}/{{round.combinedTotal}} PTS</span>
                </div>
                <div class="link round-link financial-sheet-link" @click="goToFinancialsWithOptions('income-statement', round, false)">Income Statement<span v-if="isIncomeStatementSubmissionEnabled(round.roundIndex)" class="round-score">{{round.pastIncomeStatementCorrect}}/{{round.pastIncomeStatementTotal}} PTS</span></div>
                <div class="link round-link financial-sheet-link" @click="goToFinancialsWithOptions('balance-sheet', round, false)">Balance Sheet <span v-if="isBalanceSheetSubmissionEnabled(round.roundIndex)" class="round-score">{{round.pastBalanceSheetCorrect}}/{{round.pastBalanceSheetTotal}} PTS</span></div>
                <div class="link round-link financial-sheet-link" @click="goToFinancialsWithOptions('cash-flow', round, false)">Cash Flow <span v-if="isCashFlowSubmissionEnabled(round.roundIndex)" class="round-score">{{round.pastCashFlowCorrect}}/{{round.pastCashFlowTotal}} PTS</span></div>
                <div class="link round-link financial-sheet-link" @click="goToFinancialsWithOptions('journal-entry-submissions', round, false)">Journal Entries <span v-if="isJournalEntrySubmissionEnabled(round.roundIndex)" class="round-score">Click to see grade</span></div>
              </div>
              <div v-if="round.isActive">
                <!-- these are active round states when the game isnt in a waiting period -->
                <div v-if="currentRound" class="md-layout-item deadline-title">
                  <div><strong>{{ getDeadlineMessage() }}</strong> <strong>{{getDeadlineDate()}}</strong></div>
                  <div v-if="currentDeadline">
                    <Countdown :deadline="currentDeadline" :countdown-id="'nav'"></Countdown>
                  </div>
                </div>
                <div :class="{ 'disabled': round.phase === 'calcSheets' || round.phase === 'afterSecondSubmission' }">
                  Beginning of Year 1/1/{{currentYearStart + (round.roundIndex)}}
                  <div v-if="getUserType === UserTypeEnum.Student">
                    <div class="link round-link" @click="goToRoute('get-cash', round)">Get Cash</div>
                    <div class="link round-link" @click="goToRoute('spend-cash', round)">Spend Cash</div>
                    <div class="link round-link" @click="goToRoute('go-to-market', round)">Go to Market</div>
                  </div>
                  <div v-if="getUserType === UserTypeEnum.Professor">
                    <div class="link round-link" @click="goToRoute('round-report', round)">Current Round Report</div>
                  </div>
                </div>
                <div :class="{ 'disabled': round.phase === 'inputSheet' }">
                  End of Year 12/31/{{currentYearStart + (round.roundIndex)}}
                  <div v-if="round.phase === 'afterSecondSubmission' || getUserType !== UserTypeEnum.Student">
                    <div class="link round-link" @click="goToRoute('competitor-financials', round)">Competitor Financials</div>
                  </div>
                  <div v-if="showJournalEntryTAccountLink(round)" class="link round-link" @click="goToRoute('round-report', round)">Journal Entries and T Accounts</div>
                  <div class="link round-link" @click="goToRoute('round-report', round)">Round Report</div>
                  <div class="link round-link" @click="goToFinancialsWithOptions('income-statement', round, false)">
                    Financial Statements
                    <span v-if="showAllSheetSubmissionsComplete(round.roundIndex) && round.phase === 'calcSheets'">
                      <md-icon class="fa fa-check-circle"></md-icon>
                    </span>
                  </div>
                  <div class="link round-link financial-sheet-link" @click="goToFinancialsWithOptions('income-statement', round, false)">
                    <span v-if="isIncomeStatementSubmissionEnabled(round.roundIndex) && round.phase === 'calcSheets'">
                      <md-icon v-if="currentTeamHasSubmittedIncomeStatement" class="fa fa-check-circle"><md-tooltip class="tooltip" md-direction="top">Submitted for grading</md-tooltip></md-icon>
                      <md-icon v-else class="far fa-file-alt"><md-tooltip class="tooltip" md-direction="top">Submission is required for grading</md-tooltip></md-icon>                      
                    </span>
                    <span v-else>
                      <md-icon></md-icon>
                    </span>
                    Income Statement 
                    <span v-if="round.phase === 'afterSecondSubmission' && isIncomeStatementSubmissionEnabled(round.roundIndex)" class="round-score">{{round.pastIncomeStatementCorrect}}/{{round.pastIncomeStatementTotal}} PTS</span>
                  </div>
                  <div class="link round-link financial-sheet-link" @click="goToFinancialsWithOptions('balance-sheet', round, false)">
                    <span v-if="isBalanceSheetSubmissionEnabled(round.roundIndex) && round.phase === 'calcSheets'">
                      <md-icon v-if="currentTeamHasSubmittedBalanceSheet" class="fa fa-check-circle"><md-tooltip class="tooltip" md-direction="top">Submitted for grading</md-tooltip></md-icon>
                      <md-icon v-else class="far fa-file-alt"><md-tooltip class="tooltip" md-direction="top">Submission is required for grading</md-tooltip></md-icon>
                    </span>
                    <span v-else>
                      <md-icon></md-icon>
                    </span>
                    Balance Sheet 
                    <span v-if="round.phase === 'afterSecondSubmission' && isBalanceSheetSubmissionEnabled(round.roundIndex)" class="round-score">{{round.pastBalanceSheetCorrect}}/{{round.pastBalanceSheetTotal}} PTS</span>
                  </div>
                  <div class="link round-link financial-sheet-link" @click="goToFinancialsWithOptions('cash-flow', round, false)">
                    <span v-if="isCashFlowSubmissionEnabled(round.roundIndex) && round.phase === 'calcSheets'">
                      <md-icon v-if="currentTeamHasSubmittedCashFlow" class="fa fa-check-circle"><md-tooltip class="tooltip" md-direction="top">Submitted for grading</md-tooltip></md-icon>
                      <md-icon v-else class="far fa-file-alt"><md-tooltip class="tooltip" md-direction="top">Submission is required for grading</md-tooltip></md-icon>
                    </span>
                    <span v-else>
                      <md-icon></md-icon>
                    </span>
                    Cash Flow 
                    <span v-if="round.phase === 'afterSecondSubmission' && isCashFlowSubmissionEnabled(round.roundIndex)" class="round-score">{{round.pastCashFlowCorrect}}/{{round.pastCashFlowTotal}} PTS</span>
                  </div>
                  <div class="link round-link financial-sheet-link" @click="goToFinancialsWithOptions('journal-entry-submissions', round, false)">
                    <span v-if="isJournalEntrySubmissionEnabled(round.roundIndex) && round.phase === 'calcSheets'">
                      <md-icon v-if="currentTeamHasSubmittedJournalEntries" class="fa fa-check-circle"><md-tooltip class="tooltip" md-direction="top">Submitted for grading</md-tooltip></md-icon>
                      <md-icon v-else class="far fa-file-alt"><md-tooltip class="tooltip" md-direction="top">Submission is required for grading</md-tooltip></md-icon>
                    </span>
                    <span v-else>
                      <md-icon></md-icon>
                    </span>
                    Journal Entries 
                    <span v-if="round.phase === 'afterSecondSubmission' && isJournalEntrySubmissionEnabled(round.roundIndex)" class="round-score">{{round.pastJournalEntryCorrect}}/{{round.pastJournalEntryTotal}}PTS</span>
                  </div>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
    <!-- end round menu -->
    <!-- begin team menu -->
    <div class="team-nav" v-if="isTeamMenuVisible">
      <div class="team-name-section">
        <h1>{{selectedTeam.semester.name}}</h1>
        <div class="md-layout">
          <div class="md-layout-item team-menu-team-title">
            {{selectedTeam.name}}
          </div>
        </div>
        <div v-if="currentRound.roundNum > 1">
          <div class="md-layout">
            <div class="md-layout-item team-menu-pts">
              {{calculateTeamNumCorrect(currentRound.roundNum - 1)}} / {{calculateTeamTotalPoints(currentRound.roundNum - 1)}} pts
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              Rounds completed: {{currentRound.roundNum - 1}}
            </div>
          </div>
        </div>
      </div>

      <div class="team-cell-section">
        <div v-if="isTeamManagementMode">
          <div class="team-menu-cell">
            <div class="team-menu-cell-item team-select-cell link">
              <div @click="isTeamManagementMode = false">Back to team selection</div>
            </div>
          </div>
        </div>
        <div v-if="getUserType === UserTypeEnum.Professor">
          <div v-if="!isTeamManagementMode">
            <div class="team-menu-cell">
              <div class="team-menu-cell-item team-select-cell">
                <strong>Select Team</strong>
              </div>
            </div>
            <div v-for="team in selectableProfessorTeams" v-bind:key="team.id" class="team-menu-cell">
              <div class="md-layout">
                <div class="md-layout-item team-menu-cell-item team-select-cell link" :class="{selected : team.id === currentSelectedTeam.id}" @click="selectTeam(team)">
                  {{team.name}}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="team-menu-cell">
              <div class="team-menu-cell-item team-select-cell">
                <strong>Team Management</strong>
              </div>
            </div>
            <div v-for="team in teamInfo" v-bind:key="team.id" class="team-menu-cell">
              <div class="md-layout">
                <div class="md-layout-item team-menu-cell-item team-select-cell">
                  {{team.name}} - {{team.students.length}}/{{team.semester.numStudentsPerTeam}} Students
                </div>
                <div v-if="selectedStudent && !showRemoveStudentModal" class="md-layout-item team-menu-cell-item team-select-cell">
                  <span v-if="isStudentOnTeam(team)" @click="selectedStudent = null, oldTeam = null" class="link">
                    Don't Move {{selectedStudent.firstname}}
                  </span>
                  <span v-else @click="moveStudentToSelectedTeam(team.id)" class="link">
                    <span v-if="team.semester.numStudentsPerTeam > team.students.length">Move {{selectedStudent.firstname}} to {{team.name}}</span>
                  </span>
                </div>
              </div>
              <div v-for="student in team.students" v-bind:key="student.id">
                <div class="md-layout">
                  <div class="md-layout-item team-menu-cell-item student-select-cell">
                    {{student.firstname}} {{student.lastname}}
                  </div>
                  <div class="md-layout-item">
                    <span @click="selectStudent(student, team)" class="icon-group-left">
                      <md-icon class="fas fa-truck-moving light link"></md-icon>
                      <md-tooltip class="tooltip" md-direction="top">Move</md-tooltip>
                    </span>
                    <span v-if="adminUserToken" @click="openRemoveStudentDialog(student, team)">
                      <md-icon class="fas fa-trash light link"></md-icon>
                      <md-tooltip class="tooltip" md-direction="top">Remove from Game</md-tooltip>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>

        <div v-if="notifyMessage">
          {{notifyMessage}}
        </div>
        <div v-if="!isTeamManagementMode">
          <div v-if="getUserType === UserTypeEnum.Professor" class="team-menu-cell">
            <div class="md-layout">
              <div class="md-layout-item team-menu-cell-item link" @click="isTeamManagementMode = true">
                Team Management
              </div>
            </div>
          </div>
          <div v-if="getUserType === UserTypeEnum.Professor" class="team-menu-cell">
            <div class="md-layout">
              <div class="md-layout-item team-menu-cell-item link" @click="fetchAndShowGeneralLedger()">
                View Game General Ledger
              </div>
            </div>
          </div>
          <div v-if="getUserType === UserTypeEnum.Professor" class="team-menu-cell">
            <div class="md-layout">
              <div class="md-layout-item team-menu-cell-item link" @click="fetchAndShowTeamsInfo()">
                View statuses (submissions and submission date)
              </div>
            </div>
          </div>
          <div class="team-menu-cell">
            <div class="md-layout">
              <div class="md-layout-item team-menu-cell-item link" @click="initiateChangePassword()">
                Change Password
              </div>
            </div>
          </div>     
          <div class="team-menu-cell">
            <div class="md-layout">
              <div class="md-layout-item team-menu-cell-item link" @click="logout()">
                Sign Out
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- end team menu -->
    <md-dialog class="general-ledger-modal" :md-active="showGeneralLedgerModal">
      <md-dialog-title class="modal-title">General Ledger</md-dialog-title>

      <GeneralLedgerTable :ledger-entries="ledgerEntries"></GeneralLedgerTable>
      <md-dialog-actions>
        <md-button class="md-accent" @click="showGeneralLedgerModal = false">Close</md-button>
      </md-dialog-actions>
    </md-dialog>

    <!-- this needs to be refactored -->
    <md-dialog class="general-ledger-modal" :md-active="teamsInfo !== null">
      <md-dialog-title class="modal-title">Teams Info</md-dialog-title>
      
      <TeamsInfoTable :table="teamsInfo"></TeamsInfoTable>

      <md-dialog-actions>
        <!-- this is inefficient -->
        <md-button class="md-accent" @click="teamsInfo = null">Close</md-button>
      </md-dialog-actions>
    </md-dialog>
    <md-dialog v-if="adminUserToken && selectedStudent && selectedTeam" class="remove-student-modal" :md-active="showRemoveStudentModal">
      <md-dialog-title class="modal-title">Are you sure you want to boot {{selectedStudent.firstname}} from team {{selectedTeam.name}}?</md-dialog-title>

      <md-dialog-actions>
        <md-button class="md-accent btn-primary" @click="removeStudentFromGame()">Boot</md-button>
        <md-button class="md-accent btn-warning" @click="cancelRemoveStudent()">Close</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>

import Vue from 'vue'
import { MdApp } from 'vue-material/dist/components'
import { MdDrawer } from 'vue-material/dist/components'
import { MdToolbar } from 'vue-material/dist/components'
import Countdown from './Countdown'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {GameRoundPhase} from "temporary-api-types/apiTypes";
import GeneralLedgerTable from './GeneralLedgerTable'
import Scoreboard from './Scoreboard.vue';
import { UserTypeEnum } from "temporary-api-types/apiTypes";
import MessageCenter from './MessageCenter.vue';
import GlossaryViewport from './GlossaryViewport.vue';
import TeamsInfoTable from './TeamsInfoTable.vue';
Vue.use(MdToolbar)
Vue.use(MdApp)
Vue.use(MdDrawer)
export default {
  name: 'RoundNavigation',
  components: {
    Countdown,
    GeneralLedgerTable,
    'glossary': GlossaryViewport,
    'scoreboard': Scoreboard,
    'message-center': MessageCenter,
    TeamsInfoTable
  },
  data() {
    return {
      attrs: {
        currentSelectedTeam: this.selectedTeam,
        currentActiveRoundId: this.activeRoundId,
        currentShowMobileRoundReportOverlay: this.showMobileRoundReportOverlay,
        currentTeamHasSubmittedIncomeStatement: this.teamHasSubmittedIncomeStatement,
        currentTeamHasSubmittedBalanceSheet: this.teamHasSubmittedBalanceSheet,
        currentTeamHasSubmittedCashFlow: this.teamHasSubmittedCashFlow,
        currentTeamHasSubmittedJournalEntries: this.teamHasSubmittedJournalEntries
      },
      selectedRoundNum: null,
      rounds: [],
      currentRound: {
        id: "",
        phase: "",
        roundNum: ""
      },
      teamId: null,
      formerTeam: null,
      isScoreboardVisible: false,
      isTeamMenuVisible: false,
      isRoundMenuVisible: false,
      isMessageCenterVisable: false,
      isGlossaryVisible: false,
      calledFromTeamChange: false,
      notifyMessage: null,
      showGeneralLedgerModal: false,
      teamsInfo: null,
      ledgerEntries: null,
      url: process.env.VUE_APP_LAMBDA_URL,
      adminUserToken: null,
      UserTypeEnum,
      isPageReady: false,
      selectableProfessorTeams: null,
      isTeamManagementMode: false,
      teamInfo: null,
      selectedStudent: null,
      oldTeam: null,
      numUncheckedMessages: 0,
      showRemoveStudentModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'getUserType',
      'getUserEmail',
      'getCurrentCourse',
      'getTeam',
      'getSemesterList',
      'getCurrentGameState',
      'getProfessorTeams',
      'getFormattedDate',
      'getNextDeadline',
      'getCurrentIncomeStatement',
      'getCurrentBalanceSheet',
      'getCurrentCashFlowStatement',
      'isDarkMode',
    ]),
    currentSelectedTeam: {
      get() {
        return this.attrs.currentSelectedTeam;
      },
      set(value) {
        this.attrs.currentSelectedTeam = value;
        this.$emit(`update:selected-team`, value);
      }
    },
    currentActiveRoundId: {
      get() {
        return this.attrs.currentActiveRoundId;
      },
      set(value) {
        this.attrs.currentActiveRoundId = value;
        this.$emit(`update:active-round-id`, value);
      },
    },
    currentShowMobileRoundReportOverlay: {
      get() {
        return this.attrs.currentShowMobileRoundReportOverlay;
      },
      set(value) {
        this.attrs.currentShowMobileRoundReportOverlay = value;
        this.$emit(`update:show-mobile-round-report-overlay`, value);
      }
    },
    currentGameState() {
      return this.$store.state.game.currentGameState;
    },
    currentRoundString() {
      if(this.selectedRoundNum) {
        switch (this.selectedRoundNum) {
          case 1:
            return "ONE";
          case 2:
            return "TWO";
          case 3:
            return "THREE";
          case 4:
            return "FOUR";
          case 5:
            return "FIVE";
          case 6:
            return "SIX";
          case 7:
            return "SEVEN";
          case 8:
            return "EIGHT";
          case 9:
            return "NINE"
          case 10:
            return "TEN"
          default:
            return (this.selectedRoundNum).toString();
        }
      } else {
        return null;
      }
    },
    currentTeamHasSubmittedIncomeStatement: {
      get() {
        return this.attrs.currentTeamHasSubmittedIncomeStatement;
      },
      set(value) {
        this.attrs.currentTeamHasSubmittedIncomeStatement = value;
        this.$emit(`update:team-has-submitted-income-statement`, value);
      }
    },
    currentTeamHasSubmittedBalanceSheet: {
      get() {
        return this.attrs.currentTeamHasSubmittedBalanceSheet;
      },
      set(value) {
        this.attrs.currentTeamHasSubmittedBalanceSheet = value;
        this.$emit(`update:team-has-submitted-balance-sheet`, value);
      }
    },
    currentTeamHasSubmittedCashFlow: {
      get() {
        return this.attrs.currentTeamHasSubmittedCashFlow;
      },
      set(value) {
        this.attrs.currentTeamHasSubmittedCashFlow = value;
        this.$emit(`update:team-has-submitted-cash-flow`, value);
      }
    },
    currentTeamHasSubmittedJournalEntries: {
      get() {
        return this.attrs.currentTeamHasSubmittedJournalEntries;
      },
      set(value) {
        this.attrs.currentTeamHasSubmittedJournalEntries = value;
        this.$emit(`update:team-has-submitted-journal-entries`, value);
      }
    },
    currentDeadline() {
      return this.getNextDeadline;
    },
    currentYearStart() {
      const roundTimings = JSON.parse(JSON.stringify(this.currentSelectedTeam.semester.roundTimings));
      const beginningOfYearRoundTiming = roundTimings[0];
      const beginningOfYearSeconds = beginningOfYearRoundTiming.electionsOpen;
      const date = new Date(beginningOfYearSeconds * 1000)

      return date.getFullYear();
    }
  },
  props: {
    selectedTeam: {
      type: Object
    },
    activeRoundId: {
      type: String
    },
    routeDetail: {
      type: String
    },
    isMenuOpen: {
      type: Boolean
    },
    showMobileRoundReportOverlay: {
      type: Boolean
    },
    showScoreboardOnLoad: {
      type: Boolean
    },
    leftSideToShowInFinancialsView: {
      type: String
    },
    teamHasSubmittedIncomeStatement: {
      type: Boolean
    },
    teamHasSubmittedBalanceSheet: {
      type: Boolean
    },
    teamHasSubmittedCashFlow: {
      type: Boolean
    }
  },
  watch: {
    activeRoundId(val) {
      if(val) {
        this.updateRounds();
      }
    },
    async currentSelectedTeam(val) {
      if (val) {
        let routeDetail = this.$route.params.routeDetail;
        this.$emit(`update:selected-team`, val);
        this.teamId = val.id
        if (this.$route.params.teamId !== this.teamId && this.$route.params.routeDetail === routeDetail) {
          this.$router.push({
            name: 'Game',
            params: {
              teamId: this.teamId,
              routeDetail: routeDetail
            }
          });
        }
        if(!this.calledFromTeamChange) {
          this.updateRounds();
        } else {
          this.initPastReportsForCurrentTeam();
          await this.setCurrentSheetSubmissionStatus();
        }
        this.calledFromTeamChange = false;
        for(let round of this.rounds) {
          if(round.isPast || (round.isActive && round.phase === 'afterSecondSubmission')) {
            this.getPastReport(round)
          }
        }

        // await this.getUnreadMessages(val.id);
      }
    },
    routeDetail(val) {
      if(val) {
        this.updateRounds();
      }
    },
    isScoreboardVisible(val) {
      this.$emit(`update:is-menu-open`, this.isScoreboardVisible );
    },
    isTeamMenuVisible(val) {
      this.$emit(`update:is-menu-open`, this.isTeamMenuVisible || this.isRoundMenuVisible || this.isScoreboardVisible);
    },
    isRoundMenuVisible(val) {
      if(val === true) {
          setTimeout(() => {
              // scroll to the current round as best as possible
              const courseRounds = JSON.parse(JSON.stringify(this.getCurrentCourse.rounds));
              const roundHeight = 270;
              const roundIds = courseRounds.map((round) => round.id);
              let roundIndex = roundIds.indexOf(this.currentActiveRoundId);
              if(this.$route.params.routeDetail === 'game-over') {
                  roundIndex = roundIds.length - 1;
              }
              if(roundIndex < 0 || this.$route.params.routeDetail === 'hasnt-started') {
                roundIndex = 0;
              }
              const scrollAmount = roundIndex * roundHeight;
              Vue.nextTick(() => {
                if(scrollAmount > this.$refs['round-nav-scroll'].offsetHeight) {
                    this.$refs['round-nav-scroll'].scrollTop = scrollAmount;
                }
              });
          }, 100);
      }
      this.$emit(`update:is-menu-open`, this.isTeamMenuVisible || this.isRoundMenuVisible || this.isScoreboardVisible);
    },
    isMessageCenterVisable(val) {
      this.$emit(`update:is-menu-open`, this.isMessageCenterVisable );
    },
    isGlossaryVisible(val) {
      this.$emit(`update:is-menu-open`, this.isGlossaryVisible );
    },
    teamHasSubmittedIncomeStatement(val) {
      if(val) {
        this.currentTeamHasSubmittedIncomeStatement = val;
      }
    },
    teamHasSubmittedBalanceSheet(val) {
      if(val) {
        this.currentTeamHasSubmittedBalanceSheet = val;
      }
    },
    teamHasSubmittedCashFlow(val) {
      if(val) {
        this.currentTeamHasSubmittedCashFlow = val;
      }
    },
    teamHasSubmittedJournalEntries(val) {
      if(val) {
        this.currentTeamHasSubmittedJournalEntries = val;
      }
    },
    async isTeamManagementMode(val) {
      if(val) {
        await this.getTeamInfo();
      }
    }
  },
  methods: {
    ...mapActions([
      'initGame',
      'fetchGradingReport',
      'resetVuexState',
      'getGeneralLedgerEntries',
      'getTeamsInfo',
      'setAdminUserToken',
      'configureUserType',
      'configureUserEmail',
      'fetchIncomeStatement',
      'fetchBalanceSheet',
      'fetchCashFlowStatement',
      'fetchTeamInfo',
      'switchTeams',
      'removeStudentFromTeam',
      'fetchGameMessages',
      'toggleDarkMode',
    ]),
    showAllSheetSubmissionsComplete(roundIndex) {
      const incomeStatementEnabled = this.isIncomeStatementSubmissionEnabled(roundIndex);
      const balanceSheetEnabled = this.isBalanceSheetSubmissionEnabled(roundIndex);
      const cashFlowEnabled = this.isCashFlowSubmissionEnabled(roundIndex);
      const journalEntryEnabled = this.isJournalEntrySubmissionEnabled(roundIndex);

      if(incomeStatementEnabled || balanceSheetEnabled || cashFlowEnabled) {
        if(incomeStatementEnabled && !this.currentTeamHasSubmittedIncomeStatement) {
          return;
        }
        if(balanceSheetEnabled && !this.currentTeamHasSubmittedBalanceSheet) {
          return;
        }
        if(cashFlowEnabled && !this.currentTeamHasSubmittedCashFlow) {
          return;
        }
        if(journalEntryEnabled && !this.currentTeamHasSubmittedJournalEntries) {
          return;
        }
        return true;
      }

      return false;
    },
    initPastReportsForCurrentTeam() {
      for(let round of this.rounds) {
        if(round.isPast || (round.isActive && round.phase === 'afterSecondSubmission')) {
          this.getPastReport(round)
        }
      }
    },
    isLongTeamName() {
      if(this.currentSelectedTeam) {
        return this.currentSelectedTeam.name.length > 16
      }
    },
    isIncomeStatementSubmissionEnabled(roundIndex) {
      const gameConfig = JSON.parse(JSON.stringify(this.currentSelectedTeam.semester.gameConfig));
      return gameConfig.roundFeatures.incomeStatementConfig[roundIndex].roundSetting === "SUBMIT";
    },
    isBalanceSheetSubmissionEnabled(roundIndex) {
      const gameConfig = JSON.parse(JSON.stringify(this.currentSelectedTeam.semester.gameConfig));
      return gameConfig.roundFeatures.balanceSheetConfig[roundIndex].roundSetting === "SUBMIT";
    },
    isCashFlowSubmissionEnabled(roundIndex) {
      const gameConfig = JSON.parse(JSON.stringify(this.currentSelectedTeam.semester.gameConfig));
      return gameConfig.roundFeatures.cashFlowConfig[roundIndex].roundSetting === "SUBMIT";
    },
    isJournalEntrySubmissionEnabled(roundIndex) {
      const gameConfig = JSON.parse(JSON.stringify(this.currentSelectedTeam.semester.gameConfig));
      return gameConfig.roundFeatures.journalEntrySubmissionConfig[roundIndex].enabledJournalEntrySubmissions.length > 0;
    },
    goToHome() {
      if(this.$route.name !== 'Semester') {
        this.$router.push({ 
          name: 'Semester'
        });
      }
    },
    determineStudentHome() {
      const gameState = this.currentGameState;
      switch (gameState.phase) {
        case GameRoundPhase.HasntStarted:
          if(this.$route.params.routeDetail !== 'hasnt-started') {
            this.$router.push({
              name: 'Game',
              params: {routeDetail: 'hasnt-started', teamId: this.$route.params.teamId}
            });
            break;
          }
          break;
        case GameRoundPhase.InputSheet:
          if(this.$route.params.routeDetail !== 'get-cash') {
            this.$router.push({
              name: 'Game',
              params: {routeDetail: 'get-cash', teamId: this.$route.params.teamId}
            });
            break;
          }
          break;
        case GameRoundPhase.CalcWaiting:
          if(this.$route.params.routeDetail !== 'calc-waiting') {
            this.$router.push({
              name: 'Game',
              params: {routeDetail: 'calc-waiting', teamId: this.$route.params.teamId}
            });
            break;
          }
          break;
        case GameRoundPhase.CalcSheets:
          if(this.$route.params.routeDetail !== 'income-statement') {
            this.$router.push({
              name: 'Game',
              params: {routeDetail: 'income-statement', teamId: this.$route.params.teamId}
            });
            break;
          }
          break;
        case GameRoundPhase.GradeWaiting:
          if(this.$route.params.routeDetail !== 'grade-waiting') {
            this.$router.push({
              name: 'Game',
              params: {routeDetail: 'grade-waiting', teamId: this.$route.params.teamId}
            });
            break;
          }
          break;
        case GameRoundPhase.Graded:
          if(this.$route.params.routeDetail !== 'grades') {
            this.$router.push({
              name: 'Game',
              params: {routeDetail: 'grades', teamId: this.$route.params.teamId}
            });
            break;
          }
          break;
        case GameRoundPhase.GameOver:
          if(this.$route.params.routeDetail !== 'game-over') {
            this.$router.push({
              name: 'Game',
              params: {routeDetail: 'game-over', teamId: this.$route.params.teamId}
            });
            break;
          }
          break;
        default:
          break;
      }
    },
    async fetchAndShowGeneralLedger() {
      this.ledgerEntries = await this.getGeneralLedgerEntries({
        semesterId: this.$route.params.semesterId
      });

      this.showGeneralLedgerModal = true;
    },
    async fetchAndShowTeamsInfo() {
      this.teamsInfo = await this.getTeamsInfo({
        semesterId: this.$route.params.semesterId
      });
    },
    goToRoute(routeDetail, round) {
      if(round) {
        this.currentActiveRoundId = round.id;
        this.selectedRoundNum = round.roundNum;
      } else {
        const gameState = this.getCurrentGameState;
        this.currentActiveRoundId = gameState.roundId;
      }
      if(!this.showJournalEntryTAccountLink(round) && routeDetail === 'round-report') {
        routeDetail = 'income-statement';
      }
      if(this.$route.params.teamId !== this.teamId || this.$route.params.routeDetail !== routeDetail) {
        this.$router.push({ 
          name: 'Game',
          params: {
            teamId: this.teamId,
            routeDetail: routeDetail
          }
        });
      } 
      this.toggleRoundMenu();
    },
    async initiateChangePassword() {
      const email = this.getUserEmail;
      const data = {
        email: email,
        groupName: this.getUserType
      };

      try {
        const response = await fetch(`${this.url}/forgotPassword`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
              'Content-Type': 'application/json',
              'Access-Control-Request-Method': 'POST'
          },
          body: JSON.stringify(data)
        });

        if(response.ok) {
          this.notifyMessage = "A message has been sent to your email. Please check for password reset instructions"
        } else {
          this.notifyMessage = "Could not initiate change password"
          return;
        }
      } catch(err) {
        this.notifyMessage = "Could not initiate change password"
        return err;
      }
    },
    goToFinancialsWithOptions(routeDetail, round, showMobileRoundReport) {
      this.currentShowMobileRoundReportOverlay = showMobileRoundReport;
      this.goToRoute(routeDetail, round);
    },
    async setCurrentSheetSubmissionStatus() {
      if(this.currentRound.id) {
        await this.fetchIncomeStatement({
          roundConfigId: this.currentRound.id,
          teamId: this.selectedTeam.id
        });
        await this.fetchBalanceSheet({
          roundConfigId: this.currentRound.id,
          teamId: this.selectedTeam.id
        });
        await this.fetchCashFlowStatement({
          roundConfigId: this.currentRound.id,
          teamId: this.selectedTeam.id
        });

        if(this.getCurrentIncomeStatement && this.getCurrentIncomeStatement.submittedBy) {
          this.currentTeamHasSubmittedIncomeStatement = true;
        }
        if(this.getCurrentBalanceSheet && this.getCurrentBalanceSheet.submittedBy) {
          this.currentTeamHasSubmittedBalanceSheet = true;
        }
        if(this.getCurrentCashFlowStatement && this.getCurrentCashFlowStatement.submittedBy) {
          this.currentTeamHasSubmittedCashFlow = true;
        }
      } else {
        this.currentTeamHasSubmittedIncomeStatement = false;
        this.currentTeamHasSubmittedBalanceSheet = false;
        this.currentTeamHasSubmittedCashFlow = false;
      }
    },
    showJournalEntryTAccountLink(round) {
      const roundIndex = round.roundNum - 1;
      const gameConfig = this.selectedTeam.semester.gameConfig;
      const result = round.isActive ? gameConfig.roundFeatures.journalEntryTAccountConfig[roundIndex] === "OFF" || gameConfig.roundFeatures.journalEntryTAccountConfig[roundIndex] === "SHOW_FOR_PAST_ROUNDS" : gameConfig.roundFeatures.journalEntryTAccountConfig[roundIndex] === "OFF";
      return !result;
    },
    toggleScoreboardMenu() {
      this.isScoreboardVisible = !this.isScoreboardVisible;
      if(this.isScoreboardVisible) {
        this.isTeamMenuVisible = false;
        this.isRoundMenuVisible = false;
        this.isTeamManagementMode = false;
        this.selectedStudent = null;
        this.oldTeam = null;
        this.isMessageCenterVisable = false;
        this.isGlossaryVisible = false;
      }
    },
    toggleRoundMenu() {
      this.isRoundMenuVisible = !this.isRoundMenuVisible;
      if(this.isRoundMenuVisible) {
        this.isTeamMenuVisible = false;
        this.isScoreboardVisible = false;
        this.isTeamManagementMode = false;
        this.selectedStudent = null;
        this.oldTeam = null;
        this.isMessageCenterVisable = false;
        this.isGlossaryVisible = false;
      }
    },
    toggleTeamMenu() {
      this.notifyMessage = null;
      this.isTeamMenuVisible = !this.isTeamMenuVisible;
      if(!this.isTeamMenuVisible) {
        this.isTeamManagementMode = false;
        this.selectedStudent = null;
        this.oldTeam = null;
      }
      if(this.isTeamMenuVisible) {
        this.isRoundMenuVisible = false;
        this.isScoreboardVisible = false;
        this.isMessageCenterVisable = false;
        this.isGlossaryVisible = false;
      }
    },
    toggleMessageCenterMenu() {
      this.isMessageCenterVisable = !this.isMessageCenterVisable;
      if(this.isMessageCenterVisable) {
        this.isTeamMenuVisible = false;
        this.isRoundMenuVisible = false;
        this.isTeamManagementMode = false;
        this.isScoreboardVisible = false;
        this.selectedStudent = null;
        this.oldTeam = null;
        this.isGlossaryVisible = false;
      }
    },
    toggleGlossaryMenu() {
      this.isGlossaryVisible = !this.isGlossaryVisible;
      if(this.isGlossaryVisible) {
        this.isTeamMenuVisible = false;
        this.isRoundMenuVisible = false;
        this.isTeamManagementMode = false;
        this.isScoreboardVisible = false;
        this.selectedStudent = null;
        this.oldTeam = null;
        this.isMessageCenterVisable = false;
      }
    },
    async updateRounds() {
      const gameState = this.getCurrentGameState;
      this.rounds = [];
      this.currentRound.phase = gameState.phase;
      this.currentRound.id = gameState.roundId;
      this.currentRound.roundNum = gameState.roundIndex + 1;
      const courseRounds = JSON.parse(JSON.stringify(this.getCurrentCourse.rounds));
      for (let i = 0; i < courseRounds.length; i++) {
        this.rounds.push({
          id: courseRounds[i].id,
          roundNum: i + 1,
          roundIndex: i
        });
        if(gameState.phase !== 'GAME_OVER') {
          if (this.currentRound.id === this.rounds[i].id) {
            this.rounds[i].isActive = true;
            if(!this.selectedRoundNum) {
              this.selectedRoundNum = this.rounds[i].roundNum;
            }
            if (this.currentRound.phase === 'INPUT_SHEET') {
              this.rounds[i].phase = 'inputSheet';
            } else if (this.currentRound.phase === 'HASNT_STARTED') {
              this.rounds[i].phase = 'hasntStarted';
            } else if (this.currentRound.phase === 'CALC_WAITING') {
              this.rounds[i].phase = 'calcWaiting';
            } else if (this.currentRound.phase === 'CALC_SHEETS') {
              this.rounds[i].phase = 'calcSheets';
            } else if (this.currentRound.phase === 'GRADE_WAITING') {
              this.rounds[i].phase = 'gradeWaiting';
            } else {
              this.rounds[i].phase = 'afterSecondSubmission';
            }
          } else {
            const roundIds = courseRounds.map((round) => round.id);
            if (roundIds.indexOf(this.currentRound.id) > roundIds.indexOf(courseRounds[i].id)) {
              this.rounds[i].isPast = true;
            } else {
              this.rounds[i].isFuture = true;
            }
          }
        } else {
          this.rounds[i].isPast = true;
          this.currentRound.phase = gameState.phase;
          this.currentRound.roundNum = this.rounds.length + 1;
        }
      }
      this.initPastReportsForCurrentTeam();
      await this.setCurrentSheetSubmissionStatus();
    },
    async getPastReport(round) {
      try {
        const gradeReport = await this.fetchGradingReport({
          roundConfigId: round.id,
          teamId: this.teamId
        });
        round['pastIncomeStatementCorrect'] = gradeReport.incomeStatementNumCorrect;
        round['pastIncomeStatementTotal'] = gradeReport.incomeStatementTotal;
        round['pastBalanceSheetCorrect'] = gradeReport.balanceSheetNumCorrect;
        round['pastBalanceSheetTotal'] = gradeReport.balanceSheetTotal;
        round['pastCashFlowCorrect'] = gradeReport.cashFlowNumCorrect;
        round['pastCashFlowTotal'] = gradeReport.cashFlowTotal;
        round['pastJournalEntryCorrect'] = gradeReport.journalEntryNumCorrect;
        round['pastJournalEntryTotal'] = gradeReport.journalEntryTotal;
        round['combinedNumCorrect'] = gradeReport.combinedNumCorrect;
        round['combinedTotal'] = gradeReport.combinedTotal;
      } catch(error) {
        return error;
      } 
    },
    async countdownEnded() {
      await this.initGame({
        semesterId: this.$route.params.semesterId,
        teamId: this.$route.params.teamId
      });
      const gameState = this.getCurrentGameState;
      this.currentActiveRoundId = gameState.roundId;
      await this.updateRounds();
      this.$emit('countdown-ended');
    },
    getDeadlineMessage() {
      const gameState = this.getCurrentGameState;
      if (!gameState || !this.currentSelectedTeam) {
        return '';
      }
      if(this.currentSelectedTeam.semester.useFlowMode) {
        return 'FLOW mode (game moves on once all teams submit)';
      }
      switch(gameState.phase) {
        case GameRoundPhase.HasntStarted:
          return `Game Begins: `;
        case GameRoundPhase.InputSheet:
          return `Submission Deadline: `;
        case GameRoundPhase.CalcSheets:
          return `Submission Deadline: `;
        case GameRoundPhase.Graded:
          return `Next Round Starts: `;
        case GameRoundPhase.GameOver:
          return `Round Ended On: `;
        default:
          return '';
      }
    },
    getDeadlineDate() {
      const gameState = this.getCurrentGameState;
      if(!gameState || !this.currentSelectedTeam || this.currentSelectedTeam.semester.useFlowMode) {
        return '';
      }
      const roundTimings = JSON.parse(JSON.stringify(this.currentSelectedTeam.semester.roundTimings));
      const roundIndex = gameState.roundIndex >= 0 ? gameState.roundIndex : 0;
      const currentRoundTiming = roundTimings[roundIndex];
      switch (gameState.phase) {
        case GameRoundPhase.HasntStarted:
          return this.getFormattedDate(currentRoundTiming.electionsOpen, this.currentSelectedTeam.semester.timezone);
        case GameRoundPhase.InputSheet:
          return this.getFormattedDate(currentRoundTiming.electionsDeadline, this.currentSelectedTeam.semester.timezone);
        case GameRoundPhase.CalcSheets:
          return this.getFormattedDate(currentRoundTiming.secondSubmissionDeadline, this.currentSelectedTeam.semester.timezone);
        case GameRoundPhase.Graded:
        case GameRoundPhase.GameOver:
          return this.getFormattedDate(currentRoundTiming.gradeViewingDeadline, this.currentSelectedTeam.semester.timezone);
        default:
          return '';
      }
    },
    getPastDeadline(roundNum) {
      const roundTimings = JSON.parse(JSON.stringify(this.currentSelectedTeam.semester.roundTimings));
      const roundTiming = roundTimings[roundNum - 1];
      const marketEndFormatted = this.getFormattedDate(roundTiming.electionsDeadline, this.currentSelectedTeam.semester.timezone);
      return marketEndFormatted;
    },
    async logout() {
      if(this.adminUserToken) {
        await this.setAdminUserToken(null);
        await this.configureUserType(UserTypeEnum.Admin);
        await this.configureUserEmail(null);
        if(this.getUserType === UserTypeEnum.Admin) {
          this.$router.push({
              name: 'AdminHome'
          });
        }
      } else {
        localStorage.clear();
        sessionStorage.clear();
        this.resetVuexState();
        this.$router.push({ 
          name: 'Login'
        });
      }
    },
    calculateTeamNumCorrect(roundsCompleted) {
      let numCorrect = 0;
      for(let i = 0; i < roundsCompleted; i++) {
        numCorrect+= this.rounds[i].combinedNumCorrect;
      }
      if(!isNaN(numCorrect)) {
        return numCorrect;
      } else {
        return 0;
      }
    },
    calculateTeamTotalPoints(roundsCompleted) {
      let totalQuestions = 0;
      for(let i = 0; i < roundsCompleted; i++) {
        totalQuestions+= this.rounds[i].combinedTotal;
      }
      if(!isNaN(totalQuestions)) {
        return totalQuestions;
      } else {
        return 0;
      }
    },
    selectTeam(team) {
      this.currentSelectedTeam = team;
      this.isTeamMenuVisible = false;
      this.calledFromTeamChange = true;
    },
    async getTeamInfo() {
      try {
        this.teamInfo = await this.fetchTeamInfo({ semesterId: this.$route.params.semesterId });
      } catch(err) {
        this.notifyMessage = "Error fetching team info";
        return err;
      }
    },
    isStudentOnTeam(team) {
      return team.students.find(student => student.id === this.selectedStudent.id);
    },
    selectStudent(student, team) {
      this.selectedStudent = student;
      this.oldTeam = team;
    },
    async moveStudentToSelectedTeam(newTeamId) {
      try {
        await this.switchTeams({
          semesterId: this.$route.params.semesterId,
          studentToMoveId: this.selectedStudent.id,
          oldTeamId: this.oldTeam.id,
          newTeamId
        });

        await this.getTeamInfo();
        this.selectedStudent = null;
      } catch(err) {
        if(err.graphQLErrors && err.graphQLErrors.length) {
          const errorMessage = err.graphQLErrors[0].message;
          this.notifyMessage = errorMessage;
        } else {
          this.notifyMessage = "Error switching teams";
        }
        return err;
      }
    },
    openRemoveStudentDialog(student, team) {
      this.selectStudent(student, team);
      this.showRemoveStudentModal = true;
    },
    cancelRemoveStudent() {
      this.selectedStudent = null;
      this.oldTeam = null;
      this.showRemoveStudentModal = false;
    },
    async removeStudentFromGame() {
      if(this.adminUserToken) {
        try {
          this.teamInfo = await this.removeStudentFromTeam({
            studentId: this.selectedStudent.id,
            teamId: this.oldTeam.id
          });

          this.selectedStudent = null;
          this.showRemoveStudentModal = false;
        } catch(err) {
          if(err.graphQLErrors && err.graphQLErrors.length) {
            const errorMessage = err.graphQLErrors[0].message;
            this.notifyMessage = errorMessage;
          } else {
            this.notifyMessage = "Error removing student from game";
          }
          return err;
        }
      }
    }
  },
  async created() {
    if(this.showScoreboardOnLoad) {
      this.isScoreboardVisible = true;
      this.$emit(`update:is-menu-open`, true);
    }
    this.teamId = this.$route.params.teamId;
    await this.initGame({
        semesterId: this.$route.params.semesterId,
        teamId: this.teamId
    });
    // await this.getUnreadMessages(this.teamId);
    this.currentSelectedTeam = this.getTeam;
    if(this.getUserType === UserTypeEnum.Professor) {
      for (let team of this.getProfessorTeams) {
        if (team.id === this.teamId) {
          this.formerTeam = team;
          this.currentSelectedTeam = this.formerTeam
        }
      }
      this.selectableProfessorTeams = [...this.getProfessorTeams].sort((a, b) => (a.name > b.name) ? 1 : -1);
    }
    this.updateRounds();

    if(!this.activeRoundId){
      const gameState = this.getCurrentGameState;
      this.currentActiveRoundId = gameState.roundId;
    }

    this.adminUserToken = sessionStorage.getItem('accountonomics.adminUserToken');
    this.isPageReady = true;
  }
}
</script>

<style scoped>

  strong {
    color: var(--text-primary);
  }
  .dark-mode {
    height: 64px;
    font-size: 20px;
    line-height: 65px;
  }
  .round-nav-container {
    height: 64px;
    width: 100%;
    position: absolute;
    z-index: 100;
    top: 0;
  }

  .menu-open-z-index {
    z-index: 200;
  }

  .md-toolbar {
    height: 64px;
    padding: 0;
    overflow: hidden;
    border-bottom: 1px solid white;
  }

  .scoreboard {
    background-color: var(--round-menu-color);
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 64px;
  }

  .message-center {
    background-color: var(--round-menu-color);
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 64px;
  }

  .glossary {
    background-color: var(--round-menu-color);
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 64px;
  }

  .round-nav {
    padding: 10px 16px 10px 16px;
    background-color: var(--round-menu-color);
    color: var(--text-primary);
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 64px;
  }

  .team-nav {
    background-color: var(--round-menu-color);
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 64px;
    color: var(--text-primary);
  }

  .nav-color {
    background-color: var(--nav-bar-color) !important;
  }
  
  .disabled {
    color: var(--faded-text);
    pointer-events: none;
    cursor: default;
  }
  
  .round-title {
    font-size: 28px;
    padding-bottom: 6px;
  }
  
  .round-link {
    padding: 5px 0 5px 0;
  }

  .nav-title {
    color: var(--text-top-nav-text);
    font-size: 22px;
    font-weight: bold;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--nav-bar-color);
    margin: 1em 0;
    padding: 0;
    width: 200%;
  }

  .purp {
    color: purple;
  }
  .nav-wrapper {
    display: flex;
  }

  .nav-left {
    flex: 0 0 65%;
  }

  .nav-right {
    flex: 1;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .nav-team-name-title {
    height: 64px;
    font-size: 20px;
    line-height: 65px;
  }

  .nav-team-name-title-long {
    height: 64px;
    font-size: 20px;
    line-height: 65px;
  }

  .nav-round-title {
    height: 64px;
    font-size: 20px;
    line-height: 60px;
  }

  .tab-active {
    color: black;
    background-color: var(--top-nav-activity-bg);
    border-left: 1px solid white;
  }

  .tab-inactive {
    color: var(--text-secondary);
    background-color: var(--nav-bar-color);
    border-left: 1px solid white;
  }

  .deadline-title {
    padding-bottom: 12px;
  }

  .nav-title-left-padding {
    padding-left: 16px;
  }

  .team-name-section {
    padding-top: 70px;
    /* padding-bottom: 70px; */
  }

  .team-menu-team-title {
    font-size: 28px;
  }

  .team-menu-pts {
    font-size: 36px;
    font-weight: bold;
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .team-cell-section {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    border-top: 1px solid var(--moon-color);
    border-left: 1px solid var(--moon-color);
    border-right: 1px solid var(--moon-color);
  }

  .team-menu-cell {
    border-bottom: 1px var(--moon-color) solid;
    border-left: 1px solid var(--moon-color);
    border-right: 1px solid var(--moon-color);
  }

  .team-menu-cell-item {
    padding-top: 8px;
    padding-bottom: 8px;
  } 

  .nav-round-title-left {
    text-align: right;
    padding-right: 5px;
  }

  .nav-round-title-right {
    text-align: left;
  }

  .team-select-cell {
    padding-left: 16px;
    text-align: left;
  }

  .student-select-cell {
    padding-left: 32px;
    text-align: left;
  }

  .total-round-score {
    font-size: 25px;
    float: right;
    margin-right: 49%;
  }

  .round-score {
    float: right;
    margin-right: 50%;
  }

  .selected {
    background-color: var(--team-selected-bg-color);
    color: var(--text-secondary);
  }

  .general-ledger-modal {
    width: 70vw;
    z-index: 2000;
  }

  .remove-student-modal {
    overflow-y: scroll;
    overflow-x: scroll;
    width: 70vw;
    z-index: 2000;
  }

  .modal-title {
      text-align: center;
  }

  .message-center-title {
    height: 64px;
    font-size: 20px;
    line-height: 65px;
  }

  .glossary-title {
    height: 64px;
    font-size: 20px;
    line-height: 65px;
  }

  .dark-mode-title {
    height: 64px;
    font-size: 20px;
    line-height: 65px;
  }

  .scoreboard-title {
    height: 64px;
    font-size: 20px;
    line-height: 65px;
  }

  .financial-sheet-link {
    padding-left: 30px;
  }

  .tooltip {
    z-index: 1000;
  }

  .icon-group-left {
    padding-right: 10px;
  }

  .far.fa-list-alt.light {
    color: white;
    cursor: pointer;
  }

  .far.fa-bell.light {
    color: white;
    cursor: pointer;
  }

  .far.fa-moon.light {
    color: white;
    cursor: pointer;
  }

  .hidden {
    display: none;
  }

  .custom-badge {
    top: -20px !important;
    left: 32px !important;
  }

  @media screen and (max-width: 600px) {
    .nav-round-title {
      line-height: 32px;
    }

    .nav-round-title-left {
      line-height: 45px;
      text-align: center;
      padding-right: 0;
    }

    .nav-round-title-right {
      line-height: 0;
      text-align: center;
    }

    .total-round-score {
      margin-right: 0;
    }

    .round-score {
      margin-right: 0;
    }

  }

  @media screen and (max-width: 800px) {
    .nav-team-name-title-long {
      line-height: unset;
      display: table-cell;
      vertical-align: middle;
    }
  }
</style>
