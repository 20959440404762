<template>
  <div class="container">
    <div class="main-box">
        <div v-if="errorMessage" class="error">{{errorMessage}}</div>
        <h1 class="text-center">Manage Round Timings</h1>
        <div v-if="showFormSuccess" class="alert-success">
            Successfully applied autobot schedule. Remember to Save Round Timings in order to persist the change
            <span @click="showFormSuccess = false"><md-icon class="fa fa-times-circle light link"></md-icon></span>
        </div>
        <h2>Game: {{semester.name}}</h2>
        <md-button v-if="isAdmin" @click="confirmSavePressed()" class="md-raised btn-primary">Save Round Timings</md-button>
        <div v-for="(roundTiming, index) in newRoundTimingsInput" :key="roundTiming.id" class="md-layout">
            <div class="md-layout-item round-timing-container">
                <h2>Round {{index + 1}}</h2>
                <md-button v-if="isAdmin" @click="autoBotPressed(index)" class="md-raised btn-secondary">Enter AutoBot Mode</md-button>
                <div>
                    <h3>Elections Open</h3>
                    <div v-if="showRoundDateInput[index]['electionsOpen']">
                        <div class="datepicker-container md-layout">
                            <md-datepicker :ref="index + '-datepicker-electionsOpen'" :md-open-on-focus="false" class="custom-datepicker md-layout-item md-size-33" v-model="startOfDayTimings[index].electionsOpen" @input="checkIfValueChanged('electionsOpen', index)" :md-disabled-dates="disabledDates[index].electionsOpen" md-immediately/>
                            <input :ref="index + '-electionsOpen'" class="date-input md-layout-item md-size-66" type="text" :value="parseDate(startOfDayTimings[index].electionsOpen)" @blur="validateDateInput('electionsOpen', index)" />
                        </div>
                        <time-picker class="timepicker-container" :selected-hour.sync="roundTimingsHoursMinutes[index].electionsOpen.hour" :selected-minute.sync="roundTimingsHoursMinutes[index].electionsOpen.minute"></time-picker>
                    </div>
                    <div v-if="isAdmin"><a class="link" @click="showEditDate('electionsOpen', index)">{{convertToLocalDate(roundTiming.electionsOpen)}}</a></div>
                    <div v-else>{{convertToLocalDate(roundTiming.electionsOpen)}}</div>
                </div>
                <div>
                    <h3>Elections Deadline</h3>
                    <div v-if="showRoundDateInput[index]['electionsDeadline']">
                        <div class="datepicker-container md-layout">
                            <md-datepicker :ref="index + '-datepicker-electionsDeadline'" :md-open-on-focus="false" class="custom-datepicker md-layout-item md-size-33" v-model="startOfDayTimings[index].electionsDeadline" @input="checkIfValueChanged('electionsDeadline', index)" :md-disabled-dates="disabledDates[index]['electionsDeadline']" md-immediately/>
                            <input :ref="index + '-electionsDeadline'" class="date-input md-layout-item md-size-66" type="text" :value="parseDate(startOfDayTimings[index].electionsDeadline)" @blur="validateDateInput('electionsDeadline', index)" />
                        </div>
                        <time-picker class="timepicker-container" :selected-hour.sync="roundTimingsHoursMinutes[index].electionsDeadline.hour" :selected-minute.sync="roundTimingsHoursMinutes[index].electionsDeadline.minute"></time-picker>
                    </div>
                    <div v-if="isAdmin"><a class="link" @click="showEditDate('electionsDeadline', index)">{{convertToLocalDate(roundTiming.electionsDeadline)}}</a></div>
                    <div v-else>{{convertToLocalDate(roundTiming.electionsDeadline)}}</div>
                </div>
                <div>
                    <h3>Financials Due</h3>
                    <div v-if="showRoundDateInput[index]['secondSubmissionDeadline']">
                        <div class="datepicker-container md-layout">
                            <md-datepicker :ref="index + '-datepicker-secondSubmissionDeadline'" :md-open-on-focus="false" class="custom-datepicker md-layout-item md-size-33" v-model="startOfDayTimings[index].secondSubmissionDeadline" @input="checkIfValueChanged('secondSubmissionDeadline', index)" :md-disabled-dates="disabledDates[index]['secondSubmissionDeadline']" md-immediately/>
                            <input :ref="index + '-secondSubmissionDeadline'" class="date-input md-layout-item md-size-66" type="text" :value="parseDate(startOfDayTimings[index].secondSubmissionDeadline)" @blur="validateDateInput('secondSubmissionDeadline', index)" />
                        </div>
                        <time-picker class="timepicker-container" :selected-hour.sync="roundTimingsHoursMinutes[index].secondSubmissionDeadline.hour" :selected-minute.sync="roundTimingsHoursMinutes[index].secondSubmissionDeadline.minute"></time-picker>
                    </div>
                    <div v-if="isAdmin"><a class="link" @click="showEditDate('secondSubmissionDeadline', index)">{{convertToLocalDate(roundTiming.secondSubmissionDeadline)}}</a></div>
                    <div v-else>{{convertToLocalDate(roundTiming.secondSubmissionDeadline)}}</div>
                </div>
            </div>
        </div>
        <md-button v-if="isAdmin" @click="confirmSavePressed()" class="md-raised btn-primary">Save Round Timings</md-button>
    </div>
    <div v-if="isAutoBotMode">
        <md-dialog class="modal" :md-active="isAutoBotMode">
            <md-dialog-title class="modal-title">AutoBot Round Timings for Round {{autoBotIndex + 1}}</md-dialog-title>

            <md-dialog-content>
                <div v-if="errorMessage" class="error">{{errorMessage}}</div>
                <div class="modal-content">Select new round timings</div>
                <div class="md-layout-item">
                    <div>
                        <h3>Elections Open</h3>
                        <div class="datepicker-container md-layout">
                            <md-datepicker :ref="'autoBot-' + autoBotIndex + '-datepicker-electionsOpen'" :md-open-on-focus="false" class="custom-datepicker md-layout-item md-size-33" v-model="startOfDayTimings[autoBotIndex].electionsOpen" @input="checkIfValueChanged('electionsOpen', autoBotIndex)" :md-disabled-dates="autoBotDisabledDates[autoBotIndex].electionsOpen" md-immediately/>
                            <input :ref="'autoBot-' + autoBotIndex + '-electionsOpen'" class="date-input md-layout-item md-size-66" type="text" :value="parseDate(startOfDayTimings[autoBotIndex].electionsOpen)" @blur="validateDateInput('electionsOpen', autoBotIndex)" />
                        </div>
                        <time-picker class="timepicker-container" :selected-hour.sync="roundTimingsHoursMinutes[autoBotIndex].electionsOpen.hour" :selected-minute.sync="roundTimingsHoursMinutes[autoBotIndex].electionsOpen.minute"></time-picker>
                    </div>
                    <div>
                        <h3>Elections Deadline</h3>
                        <div class="datepicker-container md-layout">
                            <md-datepicker :ref="'autoBot-' + autoBotIndex + '-datepicker-electionsDeadline'" :md-open-on-focus="false" class="custom-datepicker md-layout-item md-size-33" v-model="startOfDayTimings[autoBotIndex].electionsDeadline" @input="checkIfValueChanged('electionsDeadline', autoBotIndex)" :md-disabled-dates="autoBotDisabledDates[autoBotIndex]['electionsDeadline']" md-immediately/>
                            <input :ref="'autoBot-' + autoBotIndex + '-electionsDeadline'" class="date-input md-layout-item md-size-66" type="text" :value="parseDate(startOfDayTimings[autoBotIndex].electionsDeadline)" @blur="validateDateInput('electionsDeadline', autoBotIndex)" />
                        </div>
                        <time-picker class="timepicker-container" :selected-hour.sync="roundTimingsHoursMinutes[autoBotIndex].electionsDeadline.hour" :selected-minute.sync="roundTimingsHoursMinutes[autoBotIndex].electionsDeadline.minute"></time-picker>
                    </div>
                    <div>
                        <h3>Financials Due</h3>
                        <div class="datepicker-container md-layout">
                            <md-datepicker :ref="'autoBot-' + autoBotIndex + '-datepicker-secondSubmissionDeadline'" :md-open-on-focus="false" class="custom-datepicker md-layout-item md-size-33" v-model="startOfDayTimings[autoBotIndex].secondSubmissionDeadline" @input="checkIfValueChanged('secondSubmissionDeadline', autoBotIndex)" :md-disabled-dates="autoBotDisabledDates[autoBotIndex]['secondSubmissionDeadline']" md-immediately/>
                            <input :ref="'autoBot-' + autoBotIndex + '-secondSubmissionDeadline'" class="date-input md-layout-item md-size-66" type="text" :value="parseDate(startOfDayTimings[autoBotIndex].secondSubmissionDeadline)" @blur="validateDateInput('secondSubmissionDeadline', autoBotIndex)" />
                        </div>
                        <time-picker class="timepicker-container" :selected-hour.sync="roundTimingsHoursMinutes[autoBotIndex].secondSubmissionDeadline.hour" :selected-minute.sync="roundTimingsHoursMinutes[autoBotIndex].secondSubmissionDeadline.minute"></time-picker>
                    </div>
                    <div>
                        <h3>Next Round Start</h3>
                        <div class="datepicker-container md-layout">
                            <md-datepicker :ref="'autoBot-' + autoBotIndex + '-datepicker-nextRoundStart'" :md-open-on-focus="false" class="custom-datepicker md-layout-item md-size-33" v-model="startOfDayTimings[autoBotIndex].nextRoundStart" @input="checkIfValueChanged('nextRoundStart', autoBotIndex)" :md-disabled-dates="autoBotDisabledDates[autoBotIndex]['nextRoundStart']" md-immediately/>
                            <input :ref="'autoBot-' + autoBotIndex + '-nextRoundStart'" class="date-input md-layout-item md-size-66" type="text" :value="parseDate(startOfDayTimings[autoBotIndex].nextRoundStart)" @blur="validateDateInput('nextRoundStart', autoBotIndex)" />
                        </div>
                        <time-picker class="timepicker-container" :selected-hour.sync="roundTimingsHoursMinutes[autoBotIndex].nextRoundStart.hour" :selected-minute.sync="roundTimingsHoursMinutes[autoBotIndex].nextRoundStart.minute"></time-picker>
                    </div>
                </div>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button @click="autoGenerateRoundTimings" class="md-raised btn-primary">Apply this Schedule to the rest of the Game</md-button>
                <md-button class="md-raised btn-warning" @click="cancelAutoGenerateRoundTimings">Cancel</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
    <md-dialog class="modal" :md-active="showConfirmSaveModal">
        <md-dialog-title class="modal-title">Are you sure you want to overwrite round timings?</md-dialog-title>
        <md-dialog-content v-if="isSemesterPaused">
            The semester is currently paused, click <a class="link" @click="unpauseSemester()">here</a> to unpause it
        </md-dialog-content>
        <md-dialog-content v-if="pastRoundToResetTo > 0">
            You are attempting to REPLAY one or more rounds in this game.  The game data from round {{pastRoundToResetTo}} through your current round will be deleted.  Are you sure you want to proceed?
        </md-dialog-content>
        <md-dialog-content>
            <div v-if="errorMessage" class="error">{{errorMessage}}</div>
            <md-button @click="validateAndSaveRoundTimings()" class="md-raised btn-primary">Ok</md-button>
            <md-button class="md-raised btn-warning" @click="showConfirmSaveModal = false, errorMessage = null">Cancel</md-button>
        </md-dialog-content>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { DateTime } from "luxon";
import TimePicker from './TimePicker.vue';
export default {
    name: 'ManageRoundTimings',
    components: {
        'time-picker': TimePicker
    },
    data() {
        return {
            attrs: {
                currentRoundTimingsInSeconds: this.roundTimingsInSeconds,
            },
            semesterId: null,
            courseId: null,
            newRoundTimingsInput: [],
            timezone: null,
            disabledDates: null,
            autoBotDisabledDates: null,
            showRoundDateInput: null,
            isAutoBotMode: false,
            startOfDayTimings: null,
            roundTimingsHoursMinutes: [],
            autoBotIndex: null,
            errorMessage: null,
            showConfirmSaveModal: false,
            showFormSuccess: false,
            isSemesterPaused: false,
            pastRoundToResetTo: null,
            originalRoundTimings: []
        }
    },
    props: {
        semester: Object,
        course: Object,
        isAdmin: Boolean,
        roundTimingsInSeconds: Array,
        successCb: Function
    },
    computed: {
        ...mapGetters([
            'getCourseList'
        ]),
        currentRoundTimingsInSeconds: {
            get() {
                return this.attrs.currentRoundTimingsInSeconds;
            },
            set(value) {
                this.attrs.currentRoundTimingsInSeconds = value;
                this.$emit(`update:round-timings-in-seconds`, value);
            }
        }
    },
    watch: {
        roundTimingsHoursMinutes: {
            handler(val) {
                if(val.length) {
                    for(let [index, roundTiming] of val.entries()) {
                        const electionsOpen = roundTiming.electionsOpen;
                        const electionsDeadline = roundTiming.electionsDeadline;
                        const secondSubmissionDeadline = roundTiming.secondSubmissionDeadline;
                        const gradeViewingDeadline = roundTiming.gradeViewingDeadline;
                        const nextRoundStart = roundTiming.nextRoundStart;
                        if(electionsOpen) {
                            this.checkIfValueChanged('electionsOpen', index)
                        }
                        if(electionsDeadline) {
                            this.checkIfValueChanged('electionsDeadline', index)
                        }
                        if(secondSubmissionDeadline) {
                            this.checkIfValueChanged('secondSubmissionDeadline', index)
                        }
                        if(gradeViewingDeadline) {
                            this.checkIfValueChanged('gradeViewingDeadline', index)
                        }
                        if(nextRoundStart) {
                            this.checkIfValueChanged('nextRoundStart', index)
                        }
                    }
                }
            },
            deep: true
        },
        isAutoBotMode(val) {
            if(val === true) {
                this.$nextTick(() => {
                    const electionsOpenRef = `autoBot-${this.autoBotIndex}-datepicker-electionsOpen`;
                    if(this.$refs[electionsOpenRef]) {
                        this.$refs[electionsOpenRef].$el.children[1].style.display = "none";
                        this.$refs[electionsOpenRef].$el.children[2].style.display = "none";
                    }
                    const electionsDeadlineRef = `autoBot-${this.autoBotIndex}-datepicker-electionsDeadline`;
                    if(this.$refs[electionsDeadlineRef]) {
                        this.$refs[electionsDeadlineRef].$el.children[1].style.display = "none";
                        this.$refs[electionsDeadlineRef].$el.children[2].style.display = "none";
                    }
                    const secondSubmissionDeadlineRef = `autoBot-${this.autoBotIndex}-datepicker-secondSubmissionDeadline`;
                    if(this.$refs[secondSubmissionDeadlineRef]) {
                        this.$refs[secondSubmissionDeadlineRef].$el.children[1].style.display = "none";
                        this.$refs[secondSubmissionDeadlineRef].$el.children[2].style.display = "none";
                    }
                    const nextRoundStartRef = `autoBot-${this.autoBotIndex}-datepicker-nextRoundStart`;
                    if(this.$refs[nextRoundStartRef]) {
                        this.$refs[nextRoundStartRef].$el.children[1].style.display = "none";
                        this.$refs[nextRoundStartRef].$el.children[2].style.display = "none";
                    }
                });
            }
        }
	},
    methods: {
        ...mapActions(['autoScheduleRoundTimings', 'validateRoundTimings', 'toggleSemesterIsPaused']),
        async autoGenerateRoundTimings() {
            const roundToBeginGeneratingNewTimings = this.autoBotIndex + 1;
            const passedInRoundTiming = {
                electionsOpen: this.newRoundTimingsInput[this.autoBotIndex].electionsOpen,
                electionsDeadline: this.newRoundTimingsInput[this.autoBotIndex].electionsDeadline,
                secondSubmissionDeadline: this.newRoundTimingsInput[this.autoBotIndex].secondSubmissionDeadline,
                gradeViewingDeadline: Math.round(this.newRoundTimingsInput[this.autoBotIndex].nextRoundStart - 1)
            }
            try {
                const autoScheduledRoundTimings = await this.autoScheduleRoundTimings({
                    semesterId: this.semesterId,
                    courseId: this.courseId,
                    roundToBeginGeneratingNewTimings,
                    passedInRoundTiming,
                    timezone: this.timezone
                });
                if(autoScheduledRoundTimings) {
                    this.currentRoundTimingsInSeconds = autoScheduledRoundTimings.map((roundTiming) => {
                        return {
                            electionsOpen: roundTiming.electionsOpen,
                            electionsDeadline: roundTiming.electionsDeadline,
                            secondSubmissionDeadline: roundTiming.secondSubmissionDeadline,
                            gradeViewingDeadline: roundTiming.gradeViewingDeadline
                        }
                    });
                    this.initRoundTimingInput();
                    await this.validateAndSaveRoundTimings();
                }
            } catch(err) {
                if(err.graphQLErrors && err.graphQLErrors.length) {
					this.errorMessage = err.graphQLErrors[0].message;
				} else {
					this.errorMessage = "Something went wrong. Please try again";
				}
				return err;
            }
        },
        autoBotPressed(index) {
            this.autoBotIndex = index;
            this.isAutoBotMode = true;
        },
        confirmSavePressed() {
            this.pastRoundToResetTo = this.getRoundToResetTo(this.newRoundTimingsInput);
            this.showConfirmSaveModal = true;
        },
        cancelAutoGenerateRoundTimings() {
            this.initRoundTimingInput();
            this.errorMessage = null;
            this.isAutoBotMode = false;
        },
      convertToLocalDate(timestamp) {
        return DateTime.fromSeconds(timestamp).toLocaleString({
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        });
      },
        parseDate(timestamp) {
            return DateTime.fromMillis(timestamp).toFormat('yyyy-MM-dd');
        },
        showEditDate(property, index) {
            this.showRoundDateInput[index][property] = !this.showRoundDateInput[index][property];
            this.$nextTick(() => {
                const refName = `${index}-datepicker-${property}`;
                if(this.$refs[refName] && this.$refs[refName].length) {
                    this.$refs[refName][0].$el.children[1].style.display = "none";
                    this.$refs[refName][0].$el.children[2].style.display = "none";
                }
            });
        },
        checkIfValueChanged(property, index) {
            const startOfDayDate = DateTime.fromMillis(this.startOfDayTimings[index][property]);
            // TODO: Remove this when DST goes away
            const adjustDST = this.getDSTHourAdjustment(startOfDayDate, this.roundTimingsHoursMinutes[index][property].hour);
            this.newRoundTimingsInput[index][property] = DateTime.fromMillis(this.startOfDayTimings[index][property]).plus({ hours: this.roundTimingsHoursMinutes[index][property].hour + adjustDST, minutes: this.roundTimingsHoursMinutes[index][property].minute }).toSeconds();
        },
        getDSTHourAdjustment(dateTime, desiredHour) {
          if(dateTime.month === 11 && dateTime.weekday === 7 && desiredHour >= 2 && dateTime.minus({days: 7}).month !== 11) {
            // first sunday in November
            // fall back (one more hour in the day), so add 1
            return 1;
          }
          if(dateTime.month === 11 && dateTime.weekday === 7 && desiredHour >= 2 && dateTime.minus({days: 7}).month === 3 && dateTime.minus({days: 14}).month !== 3) {
            // second sunday in March
            // spring forward (one less hour in the day), so subtract 1
            return -1;
          }
          return 0;
        },
        validateDateInput(property, index) {
            const refName = this.isAutoBotMode ? `autoBot-${index}-${property}` : `${index}-${property}`;
            const refLookUp = this.isAutoBotMode ? this.$refs[refName] : this.$refs[refName][0];
            const inputValue = refLookUp.value;
            const inputDate = DateTime.fromFormat(inputValue, 'yyyy-MM-dd');
            let isDateDisabled = false;
            if(inputDate.isValid) {
                const startOfDayAsJSDate = inputDate.toJSDate();
                if(this.isAutoBotMode) {
                    isDateDisabled = this.autoBotDisabledDates[index][property] ? this.autoBotDisabledDates[index][property](startOfDayAsJSDate) : false;
                } else {
                    isDateDisabled = this.disabledDates[index][property] ? this.disabledDates[index][property](startOfDayAsJSDate) : false;
                }
            }

            if(!inputDate.isValid || isDateDisabled) {
                this.startOfDayTimings[index][property] = DateTime.fromSeconds(this.newRoundTimingsInput[index][property]).startOf('day').toMillis();
            } else {
                this.startOfDayTimings[index][property] = inputDate.startOf('day').toMillis();
            }
            const startOfDayDate = DateTime.fromMillis(this.startOfDayTimings[index][property]);
            const adjustDST = this.getDSTHourAdjustment(startOfDayDate, this.roundTimingsHoursMinutes[index][property].hour);
            this.newRoundTimingsInput[index][property] = DateTime.fromMillis(this.startOfDayTimings[index][property]).plus({ hours: this.roundTimingsHoursMinutes[index][property].hour + adjustDST, minutes: this.roundTimingsHoursMinutes[index][property].minute }).toSeconds();
            refLookUp.value = DateTime.fromMillis(this.startOfDayTimings[index][property]).toFormat('yyyy-MM-dd');
        },
        async validateAndSaveRoundTimings() {
            const roundTimings = this.newRoundTimingsInput.map((roundTiming, index) => {
                const nextRoundTiming = this.newRoundTimingsInput[index + 1];
                const gradeViewingDeadline = nextRoundTiming ?
                    Math.round(nextRoundTiming.electionsOpen - 1) :
                    Math.round(roundTiming.secondSubmissionDeadline + 3600); // 1 hour after current round second submission for last round

                return {
                    electionsOpen: roundTiming.electionsOpen,
                    electionsDeadline: roundTiming.electionsDeadline,
                    secondSubmissionDeadline: roundTiming.secondSubmissionDeadline,
                    gradeViewingDeadline
                }
            });
            try {
                const validatedRoundTimings = await this.validateRoundTimings({
                    semesterId: this.semesterId,
                    courseId: this.courseId,
                    roundTimings,
                    timezone: this.timezone
                });
                if(validatedRoundTimings) {
                    this.currentRoundTimingsInSeconds = validatedRoundTimings.map((roundTiming) => {
                        return {
                            electionsOpen: roundTiming.electionsOpen,
                            electionsDeadline: roundTiming.electionsDeadline,
                            secondSubmissionDeadline: roundTiming.secondSubmissionDeadline,
                            gradeViewingDeadline: roundTiming.gradeViewingDeadline
                        }
                    });
                    this.initRoundTimingInput();
                    this.errorMessage = null;
                    this.showConfirmSaveModal = false;
                    if(!this.isAutoBotMode) {
                        await this.successCb();
                    } else {
                        this.isAutoBotMode = false;
                        this.showFormSuccess = true;
                    }
                }
            } catch(err) {
                if(err.graphQLErrors && err.graphQLErrors.length) {
					this.errorMessage = err.graphQLErrors[0].message;
				} else {
					this.errorMessage = "Something went wrong. Please try again";
				}
				return err;
            }
        },
        getRoundToResetTo(newRoundTimings) {
            const nowSeconds = DateTime.local().toSeconds();
            const roundIndexToRestTo = newRoundTimings.findIndex((newRoundTiming, index) => {
                const oldRoundTiming = this.originalRoundTimings[index];
                return oldRoundTiming.electionsOpen < nowSeconds && newRoundTiming.electionsOpen > nowSeconds;
            });
            return roundIndexToRestTo + 1;
        },
        async unpauseSemester() {
            try {
                await this.toggleSemesterIsPaused({
                    semesterId: this.semesterId
                });
                this.isSemesterPaused = false;
            } catch(err) {
                this.errorMessage = "Unable to unpause semester. Please contact the accountonomics support team"
            }
        },
        initRoundTimingInput() {
            this.newRoundTimingsInput = this.currentRoundTimingsInSeconds.map((roundTiming) => {
                return {
                    electionsOpen: roundTiming.electionsOpen,
                    electionsDeadline: roundTiming.electionsDeadline,
                    secondSubmissionDeadline: roundTiming.secondSubmissionDeadline,
                    gradeViewingDeadline: roundTiming.gradeViewingDeadline,
                    nextRoundStart: Math.round(roundTiming.gradeViewingDeadline + 1)
                }
            });
            this.startOfDayTimings = this.newRoundTimingsInput.map((roundTiming) => {
                return {
                    electionsOpen: DateTime.fromSeconds(roundTiming.electionsOpen).startOf('day').toMillis(),
                    electionsDeadline: DateTime.fromSeconds(roundTiming.electionsDeadline).startOf('day').toMillis(),
                    secondSubmissionDeadline: DateTime.fromSeconds(roundTiming.secondSubmissionDeadline).startOf('day').toMillis(),
                    gradeViewingDeadline: DateTime.fromSeconds(roundTiming.gradeViewingDeadline).startOf('day').toMillis(),
                    nextRoundStart: DateTime.fromSeconds(roundTiming.nextRoundStart).startOf('day').toMillis()
                }
            });
            this.disabledDates = this.startOfDayTimings.map((_roundTiming, index) => {
                return {
                    electionsOpen: (date) => {
                        const previousRoundSecondSubmission = index === 0 ? 0 : DateTime.fromSeconds(this.newRoundTimingsInput[index - 1].secondSubmissionDeadline).minus({days: 1}).toMillis();
                        const now = DateTime.local().minus({days: 1}).toMillis();
                        return index === 0 ? DateTime.fromJSDate(date) < now : DateTime.fromJSDate(date) < previousRoundSecondSubmission;
                    },
                    electionsDeadline: (date) => {
                        const electionsOpen = _roundTiming.electionsOpen;
                        const secondSubmissionDeadline = _roundTiming.secondSubmissionDeadline;
                        return DateTime.fromJSDate(date) < electionsOpen || DateTime.fromJSDate(date) > secondSubmissionDeadline;
                    },
                    secondSubmissionDeadline: (date) => {
                        const electionsDeadline = _roundTiming.electionsDeadline;
                        const gradeViewingDeadline = _roundTiming.gradeViewingDeadline;
                        return DateTime.fromJSDate(date) < electionsDeadline || DateTime.fromJSDate(date) > gradeViewingDeadline;
                    },
                    gradeViewingDeadline: (date) => {
                        const nextRoundStart = index < this.newRoundTimingsInput.length - 1 ? Math.round(this.newRoundTimingsInput[index + 1].electionsOpen * 1000) : 0;
                        const secondSubmissionDeadline = _roundTiming.secondSubmissionDeadline;
                        return DateTime.fromJSDate(date) < secondSubmissionDeadline || DateTime.fromJSDate(date) > nextRoundStart;
                    }
                }
            });
            this.autoBotDisabledDates = this.startOfDayTimings.map((_roundTiming, index) => {
                return {
                    electionsOpen: (date) => {
                        const previousRoundSecondSubmission = index === 0 ? 0 : DateTime.fromSeconds(this.newRoundTimingsInput[index - 1].secondSubmissionDeadline).minus({days: 1}).toMillis();
                        const now = DateTime.local().minus({days: 1}).toMillis();
                        return index === 0 ? DateTime.fromJSDate(date) < now : DateTime.fromJSDate(date) < previousRoundSecondSubmission;
                    },
                    electionsDeadline: (date) => {
                        const electionsOpen = _roundTiming.electionsOpen;
                        return DateTime.fromJSDate(date) < electionsOpen;
                    },
                    secondSubmissionDeadline: (date) => {
                        const electionsDeadline = _roundTiming.electionsDeadline;
                        return DateTime.fromJSDate(date) < electionsDeadline;
                    },
                    gradeViewingDeadline: (date) => {
                        const secondSubmissionDeadline = _roundTiming.secondSubmissionDeadline;
                        return DateTime.fromJSDate(date) < secondSubmissionDeadline;
                    },
                    nextRoundStart: (date) => {
                        const secondSubmissionDeadline = _roundTiming.secondSubmissionDeadline;
                        return DateTime.fromJSDate(date) < secondSubmissionDeadline;
                    }
                }
            });
            this.newRoundTimingsInput.map((_roundTiming, index) => {
                const value = {
                    electionsOpen: {
                        hour: DateTime.fromSeconds(_roundTiming.electionsOpen).hour,
                        minute: DateTime.fromSeconds(_roundTiming.electionsOpen).minute
                    },
                    electionsDeadline: {
                        hour: DateTime.fromSeconds(_roundTiming.electionsDeadline).hour,
                        minute: DateTime.fromSeconds(_roundTiming.electionsDeadline).minute
                    },
                    secondSubmissionDeadline: {
                        hour: DateTime.fromSeconds(_roundTiming.secondSubmissionDeadline).hour,
                        minute: DateTime.fromSeconds(_roundTiming.secondSubmissionDeadline).minute
                    },
                    gradeViewingDeadline: {
                        hour: DateTime.fromSeconds(_roundTiming.gradeViewingDeadline).hour,
                        minute: DateTime.fromSeconds(_roundTiming.gradeViewingDeadline).minute
                    },
                    nextRoundStart: {
                        hour: DateTime.fromSeconds(_roundTiming.nextRoundStart).hour,
                        minute: DateTime.fromSeconds(_roundTiming.nextRoundStart).minute
                    }
                }
                this.$set(this.roundTimingsHoursMinutes, index, value);
            });
            this.showRoundDateInput = this.newRoundTimingsInput.map((_) => {
                return {
                    electionsOpen: false,
                    electionsDeadline: false,
                    secondSubmissionDeadline: false,
                    gradeViewingDeadline: false,
                    nextRoundStart: false
                }
            });
        }
    },
    created() {
        if(this.semester) {
            this.semesterId = this.semester.id;
            this.currentRoundTimingsInSeconds = this.semester.roundTimings;
            this.timezone = this.semester.timezone;
            this.isSemesterPaused = this.semester.isPaused;
        }
        this.initRoundTimingInput();
        this.originalRoundTimings = JSON.parse(JSON.stringify(this.newRoundTimingsInput));
    }
}
</script>

<style scoped>
    h1 {
        padding: 1em;
    }
    .container {
        margin: 1em;
    }
    .main-box {
        background-color: var(--main-box-color);
    }
    .text-center {
        text-align: center;
    }
    .btn-group {
        padding-bottom: 1em;
    }
    .new-semester-form-container {
        padding: 1em;
    }
    .md-card {
        width: 320px;
        margin: 4px;
        display: inline-block;
        vertical-align: top;
    }
    .highlightSelected {
        background-color: #448aff;
    }
    .error {
        color: #ff1744;
    }
    .form-error {
        display: block!important;
        left: 0;
        height: 20px;
        position: absolute;
        bottom: -22px;
        font-size: 12px;
        transition: .3s cubic-bezier(.4,0,.2,1);
    }
    .round-timing-container {
        border-bottom: 1px solid gray;
        padding-bottom: 15px;
    }
    .datepicker-container {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        justify-content: center;
    }
    .timepicker-container {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }
    .modal {
        overflow-y: auto;
        width: 70vw;
        text-align: center;
    }
    .error {
        color: var(--warning-text-color);
    }
    .date-input {
        display: inline-block;
        text-align: center;
        background: transparent;
        border: none;
        border-bottom: 1px solid #000000;
    }
    input.date-input:focus-visible {
        background: transparent;
        border: none;
        outline: 0;
        border-bottom: 1px solid #000000;
    }
    input.date-input:focus {
        border-bottom: 1px solid #448aff;
    }
    .custom-datepicker {
        max-width: 50px;
    }
    .md-field:after, .md-field:before {
        display: none !important;
    }
</style>
