import { FeedbackClosedTracker } from "@/types/types";


const state: FeedbackClosedTracker = {
  feedbackClosed: localStorage.getItem("feedbackClosed") === "true" ? true : false,
};

const getters = {
    feedbackClosed: (state: { feedbackClosed: boolean; }) => state.feedbackClosed,
}

const actions = {
    toggleFeedbackClosed({ commit }: { commit: any }) {
        commit("toggleFeedbackClosed");
    }
};

const mutations = {
    toggleFeedbackClosed(state: { feedbackClosed: boolean; }) {
        state.feedbackClosed = !state.feedbackClosed;
        localStorage.setItem("feedbackClosed", state.feedbackClosed.toString());
    }
};

export default {
  state,
  getters,
  mutations,
  actions,
};