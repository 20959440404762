<template>
    <div class="message-center-container">
        <h1>Message Center</h1>
        <div class="message-center">
            <div class="filter-bar">
                <md-checkbox class="md-primary" v-model="shownFilters" value="AUDIT_LOG">Show Audit Logs</md-checkbox>
                <md-checkbox class="md-primary" v-model="shownFilters" value="GAMEPLAY">Show Gameplay Messages</md-checkbox>
            </div>
            <div class="message game-message" v-for="message in filteredMessages" :key="message.id">
                <span :class="[{'admin-message': message.messageType === 'MANUAL_FROM_ADMIN'}, {'professor-message': message.messageType === 'MANUAL_FROM_PROFESSOR'}]">[{{getFormattedDate(message.timeSent, team.semester.timezone)}}] {{getSenderName(message)}}: {{message.text}}</span>
            </div>
        </div>
    </div>
</template>

<script>
// import GlossaryViewport from "./GlossaryViewport";
import { mapGetters, mapActions } from 'vuex';
import { UserTypeEnum } from "temporary-api-types/apiTypes";

export default {
    name: 'MessageCenter',
    data() {
        return {
            attrs: {
                currentNumUncheckedMessages: this.numUncheckedMessages
            },
            messages: [],
            filteredMessages: [],
            UserTypeEnum,
            shownFilters: [
                "MANUAL_FROM_PROFESSOR",
                "MANUAL_FROM_ADMIN",
                "AUDIT_LOG",
                "GAMEPLAY",
                "REMINDER"
            ]
        }
    },
    computed: {
        ...mapGetters([
            'getFormattedDate',
            'getUserType'
        ]),
        currentNumUncheckedMessages: {
            get() {
                return this.attrs.currentNumUncheckedMessages;
            },
            set(value) {
                this.attrs.currentNumUncheckedMessages = value;
                this.$emit(`update:num-unchecked-messages`, value);
            }
        }
    },
    props: {
        team: {
            type: Object
        },
        numUncheckedMessages: {
            type: Number
        },
        roundConfigId: {
            type: String
        }
    },
    watch: {
        shownFilters(val) {
            this.filteredMessages = JSON.parse(JSON.stringify(this.messages)).filter((message) => {
                return val.includes(message.messageType)
            });
        }
    },
    methods: {
        ...mapActions([
            'updateLastNotificationTimestamp',
            'fetchGameMessages'
        ]),
        getSenderName(message) {
            switch (message.messageType) {
                case "MANUAL_FROM_PROFESSOR":
                    return `Professor ${message.sender.lastname}`;
                case "MANUAL_FROM_ADMIN":
                    return "Admin";
                case "AUDIT_LOG":
                    return "System";
                case "GAMEPLAY":
                    return "Game";
                case "REMINDER":
                    return "System";
                default:
                    return "";
            }
        }
    },
    async created() {
        if(this.getUserType === UserTypeEnum.Student) {
            await this.updateLastNotificationTimestamp({
                teamId: this.team.id
            });
            this.currentNumUncheckedMessages = 0;
        }

        this.messages = await this.fetchGameMessages({
            teamId: this.team.id
        });

        this.filteredMessages = JSON.parse(JSON.stringify(this.messages));
    }
}
</script>

<style scoped>

    h1, h2, h3 {
        color: var(--text-primary);
    }
    .message-center-container {
        height: 100%;
        background-color: var(--main-bg-color);
    }

    .message-center {
        height: 70%;
        width: 80%;
        background-color: rgb(40, 40, 40);
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        border: 3px solid var(--border-color);
        border-radius: 4px;
        overflow-y: auto;
        box-shadow: 15px 15px 5px var();
    }

    .message {
        text-align: left;
    }

    .admin-message {
        color: #00F0FF;
    }

    .professor-message {
        color: #00FF23;
    }

    .game-message {
        color: #FFBF00;
    }

    .filter-bar {
        height: 50px;
        width: 100%;
        background-color: var(--inner-card-secondary);
        margin-bottom: 10px;
    }
</style>
