<template>
    <div class="financial-ratios-table">
        <md-table md-card v-model="financialRatiosData">
            <md-table-row class="main" slot="md-table-row" slot-scope="{ item }">
                <md-table-cell v-if="!teamId" class="financial-ratios-table-cell" md-label="Team">{{ item.team.name }}</md-table-cell>
                <md-table-cell v-if="isStockConfigured()" class="financial-ratios-table-cell" md-label="EPS">{{ item.earningsPerShare === null ? 'n/a' : item.earningsPerShare.toFixed(2) }}</md-table-cell>
                <md-table-cell v-if="isStockConfigured()" class="financial-ratios-table-cell" md-label="PE">{{ item.priceEarnings === null ? 'n/a' : item.priceEarnings.toFixed(2) }}</md-table-cell>
                <md-table-cell class="financial-ratios-table-cell" md-label="D/E">{{ item.debtToEquity === null ? 'n/a' : item.debtToEquity.toFixed(2) }}</md-table-cell>
                <md-table-cell class="financial-ratios-table-cell" md-label="ROE">{{ item.returnOnEquity === null ? 'n/a' : item.returnOnEquity.toFixed(2) }}</md-table-cell>
                <md-table-cell class="financial-ratios-table-cell" md-label="ROA">{{ item.returnOnAssets === null ? 'n/a' : item.returnOnAssets.toFixed(2) }}</md-table-cell>
            </md-table-row>
        </md-table>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Vue from 'vue'
import { MdTable } from 'vue-material/dist/components'
import { MdRipple } from 'vue-material/dist/components'
Vue.use(MdTable);
Vue.use(MdRipple);

export default {
    name: 'FinancialRatiosTable',
    data() {
        return {
            financialRatiosData: []
        }
    },
    computed: {
        ...mapGetters([
            'getTeam'
        ]),
    },
    props: {
        semesterId: {
            type: String
        },
        roundConfigId: {
            type: String
        },
        teamId: {
            type: String
        }
    },
    watch: {
        async roundConfigId(val) {
            if(val) {
                await this.init();
            }
        },
        async teamId(val) {
            if(val) {
                await this.init();
            }
        }
    },
    methods: {
        ...mapActions([
            'fetchFinancialRatios'
        ]),
        isStockConfigured() {
            const gameConfig = this.getTeam.semester.gameConfig;
            const courseRounds = this.getTeam.semester.course.rounds;
            const roundIds = courseRounds.map(round => round.id);
            const hasStockTradingBeenEnabled = courseRounds.filter(round => roundIds.indexOf(round.id) <= roundIds.indexOf(this.roundConfigId))
                .reduce((_, round) => {
                    return round.isStockTradingEnabled;
                }, true);
            return gameConfig.stockConfig.initialOfferingNumShares > 0 || hasStockTradingBeenEnabled;
        },
        async init() {
            this.financialRatiosData = await this.fetchFinancialRatios({
                semesterId: this.semesterId,
                roundConfigId: this.roundConfigId,
                teamId: this.teamId
            });
        }
    },
    async created() {
        await this.init();
    }
}
</script>

<style scoped>

.md-title {
    color: var(--text-primary);
}

.financial-ratios-table-cell {
    text-align: left;
    background-color: var(--scoreboard-item-bg);
    color: var(--text-primary);
}
.md-toolbar.md-theme-default {
    background-color: var(--scoreboard-item-bg);
    width: 100%;
    color: var(--text-primary);
}
.md-card.md-theme-default {
    background-color: var(--scoreboard-item-bg);
    color: var(--text-primary);
}
</style>
