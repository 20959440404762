import gql from 'graphql-tag';
import { apolloClient } from '../../main';
import { SemesterVuexState } from "@/types/types";
import { Semester, SemesterInput, UserTypeEnum } from "temporary-api-types";

const state: SemesterVuexState = {
  semesterList: [],
  gameLabels: []
};

const getters = {
  getSemesterList: (state: SemesterVuexState): Semester[] => state.semesterList,
  getGameLabels: (state: SemesterVuexState) => state.gameLabels,
};

const actions = {
  async addSemester({commit}: any, payload:  SemesterInput) {
    try {
      const response = await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
        mutation CreateSemester($semesterInput: SemesterInput!) {
          createSemester(input: $semesterInput) {
            id,
            name,
            year,
            season,
            startTime,
            endTime,
            numTeams,
            numStudentsPerTeam,
            onboardCode,
            useFlowMode,
            timezone,
            teams { students { email } },
            roundTimings { roundConfigId, electionsOpen, electionsDeadline, secondSubmissionDeadline, gradeViewingDeadline, hasCalculatedMarket, hasGraded },
            currentRoundIndex,
            roundNum,
            electionsDue,
            financialsDue,
            currentGamePhase
            isActive,
            isArchived,
            teamSalesReturns { teamId, roundConfigId, assignedSalesReturnsPercentage }
          }
        }
        `,
        variables: {
          semesterInput: payload
        }
      });

      return commit('addSemesterToCache', response.data.createSemester);
    } catch(err) {
      return Promise.reject(err);
    }
  },

  fetchSemesterList({ dispatch, rootGetters }: any) {
    const userType = rootGetters.getUserType;
    if(userType === UserTypeEnum.Professor || userType === UserTypeEnum.Admin) {
      return dispatch('fetchProfessorSemesterList');
    } else if(userType === UserTypeEnum.Student) {
      return dispatch('fetchStudentSemesterList');
    }
  },

  async moveFlowModeGameForward({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation MoveFlowMode($semesterId: ID!) {
            moveFlowMode(semesterId: $semesterId)
          }
        `,
        variables: {
          semesterId: payload.semesterId
        }
      });

      return 'success';
    } catch(err) {
      return Promise.reject(err);
    }
  },

  async fetchProfessorSemesterList({ commit }: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query ProfessorSemesters {
            professorSemesters {
              id,
              course { 
                id, 
                rounds { id } 
              },
              name,
              year,
              season,
              startTime,
              endTime,
              numTeams,
              numStudentsPerTeam,
              onboardCode,
              useFlowMode,
              timezone,
              teams { 
                students { email },
                name, 
                id,
                semester {
                  id,
                  course { 
                    id, 
                    rounds { id } 
                  },
                  roundTimings { 
                    roundConfigId, 
                    electionsOpen, 
                    electionsDeadline, 
                    secondSubmissionDeadline, 
                    gradeViewingDeadline, 
                    hasCalculatedMarket, 
                    hasGraded 
                  }
                } 
              },
              roundTimings { 
                roundConfigId, 
                electionsOpen, 
                electionsDeadline, 
                secondSubmissionDeadline, 
                gradeViewingDeadline, 
                hasCalculatedMarket, 
                hasGraded 
              },
              currentRoundIndex,
              roundNum,
              electionsDue,
              financialsDue,
              currentGamePhase
              isActive,
              isArchived,
              teamSalesReturns { 
                teamId, 
                roundConfigId, 
                assignedSalesReturnsPercentage 
              },
              isPaused,
              professors {
                id,
                email,
                firstname,
                lastname
              }
            }
          }
        `
      });

      commit('setSemesterList', response.data.professorSemesters);
    } catch(err) {
      return Promise.reject(err);
    }
  },

  async fetchStudentSemesterList({ commit }: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query StudentSemesters {
            studentSemesters {
              id,
              name,
              year,
              season,
              startTime,
              endTime,
              useFlowMode,
              timezone,
              teams { 
                students { email },
                name, 
                id,
                # semester {
                #   id,
                #   course { 
                #     id, 
                #     rounds { id } 
                #   },
                # } 
              },
              roundTimings { 
                roundConfigId, 
                electionsOpen, 
                electionsDeadline, 
                secondSubmissionDeadline, 
                gradeViewingDeadline, 
                hasCalculatedMarket, 
                hasGraded 
              },
              roundNum,
              electionsDue,
              financialsDue,
              currentGamePhase,
              isActive,
              isArchived,
              isPaused
            }
          }
        `
      });

      commit('setSemesterList', response.data.studentSemesters);
    } catch(err) {
      return Promise.reject(err);
    }
  },

  async getGeneralLedgerEntries({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query GeneralLedger($semesterId: ID!) {
            generalLedger(semesterId: $semesterId) {
              id,
              roundNum,
              teamName,
              amount,
              account
            }
          }
        `,
        variables: {
          semesterId: payload.semesterId
        }
      });

      return response.data.generalLedger;
    } catch(err) {
      return Promise.reject(err);
    }
  },

  async getTeamsInfo({ commit }: any, payload: any) {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query Q($semesterId: ID!) {
            getTeamsOverviewTable(semesterId: $semesterId)
          }
        `,
        variables: {
          semesterId: payload.semesterId
        }
      });

      // Don't ask why this is the case. Somehow this is the correct property path
      return JSON.parse(response.data.getTeamsOverviewTable).table;
  },

  async editSemester({ commit }: any, payload: SemesterInput) {
    const response = await apolloClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
      mutation EditSemester($semesterInput: SemesterInput!) {
        editSemester(input: $semesterInput) {
          id,
          course { id, rounds { id } },
          name,
          year,
          season,
          startTime,
          endTime,
          numTeams,
          numStudentsPerTeam,
          onboardCode,
          useFlowMode,
          teams { students { email }, name, id },
          roundTimings { roundConfigId, electionsOpen, electionsDeadline, secondSubmissionDeadline, gradeViewingDeadline, hasCalculatedMarket, hasGraded },
          isActive,
          isArchived,
          teamSalesReturns { teamId, roundConfigId, assignedSalesReturnsPercentage },
          isPaused
        }
      }
      `,
      variables: {
        semesterInput: payload
      }
    });

    return response.data.editSemester;
  },
  async fetchTeamInfo({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query TeamInfo($semesterId: ID!) {
            teamInfo(semesterId: $semesterId) {
              id,
              name,
              semester {
                numStudentsPerTeam
              },
              students {
                id,
                email,
                firstname,
                lastname
              }
            }
          }
        `,
        variables: {
          semesterId: payload.semesterId
        }
      });

      return response.data.teamInfo;
    } catch(err) {
      return Promise.reject(err);
    }
  },
  async removeStudentFromTeam({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation RemoveStudentFromTeam($studentId: ID!, $teamId: ID!) {
            removeStudentFromTeam(studentId: $studentId, teamId: $teamId) {
              id,
              name,
              semester {
                numStudentsPerTeam
              },
              students {
                id,
                email,
                firstname,
                lastname,
              }
            }
          }
        `,
        variables: {
          studentId: payload.studentId,
          teamId: payload.teamId
        }
      });

      return response.data.removeStudentFromTeam;
    } catch(err) {
      return Promise.reject(err);
    }
  },
  async switchTeams({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation SwitchTeams($semesterId: ID!, $studentToMoveId: ID!, $oldTeamId: ID!, $newTeamId: ID!) {
            switchTeams(semesterId: $semesterId, studentToMoveId: $studentToMoveId, oldTeamId: $oldTeamId, newTeamId: $newTeamId) {
              id,
              name,
              semester {
                numStudentsPerTeam
              },
              students {
                id,
                email,
                firstname,
                lastname,
              }
            }
          }
        `,
        variables: {
          semesterId: payload.semesterId,
          studentToMoveId: payload.studentToMoveId,
          oldTeamId: payload.oldTeamId,
          newTeamId: payload.newTeamId
        }
      });

      return response.data.switchTeams;
    } catch(err) {
      return Promise.reject(err);
    }
  },
  async toggleSemesterIsPaused({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation ToggleSemesterIsPaused($semesterId: ID!) {
            toggleSemesterIsPaused(semesterId: $semesterId) {
              id,
              course { id, rounds { id } },
              name,
              year,
              season,
              startTime,
              endTime,
              numTeams,
              numStudentsPerTeam,
              onboardCode,
              useFlowMode,
              teams { students { email }, name, id },
              roundTimings { roundConfigId, electionsOpen, electionsDeadline, secondSubmissionDeadline, gradeViewingDeadline, hasCalculatedMarket, hasGraded },
              isActive,
              isArchived,
              teamSalesReturns { teamId, roundConfigId, assignedSalesReturnsPercentage },
              isPaused
            }
          }
        `,
        variables: {
          semesterId: payload.semesterId
        }
      });

      return response.data.toggleSemesterIsPaused;
    } catch(err) {
      return Promise.reject(err);
    }
  },
  async fetchCustomLabels({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query GetCustomLabels($semesterId: ID!) {
            getCustomLabels(semesterId: $semesterId) {
              key,
              value,
              group
            }
          }
        `,
        variables: {
          semesterId: payload.semesterId
        }
      });

      commit('setGameLabels', response.data.getCustomLabels);
    } catch(err) {
      return Promise.reject(err);
    }
  },
};

const mutations = {
  setSemesterList(state: SemesterVuexState, semesterList: Semester[]) {
    state.semesterList = semesterList;
  },
  setGameLabels(state: SemesterVuexState, gameLabels: []) {
    state.gameLabels = gameLabels;
  },
  addSemesterToCache: (state: SemesterVuexState, semesterToAdd: Semester) => state.semesterList.push(semesterToAdd),
  resetSemesterState(state: SemesterVuexState) {
    state.semesterList = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
