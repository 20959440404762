import { render, staticRenderFns } from "./Confirm.vue?vue&type=template&id=6dfc6af2&scoped=true&"
import script from "./Confirm.vue?vue&type=script&lang=js&"
export * from "./Confirm.vue?vue&type=script&lang=js&"
import style0 from "./Confirm.vue?vue&type=style&index=0&id=6dfc6af2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dfc6af2",
  null
  
)

export default component.exports