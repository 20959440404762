<template>
    <div>
        <h1>{{message}}</h1>
        <h3>You may now close this browser tab</h3>
        <div class="home-button-container">
            <a href="https://play.cosmic118.com">
                <div class="home-button">
                    <h1>Go to Accountonomics Home</h1>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotifyMessage',
    props: {
        message: String
    }
}
</script>

<style scoped>
.home-button-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.home-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100px;
    border: 4px solid #000000;
    border-radius: 10px;
    cursor: pointer;
}
</style>