<template>
  <div id="clippyHelper" ref="clippyHelper">
    <div class="container">
      <div class="chat-bubble">
        <p>{{ message.tip }}</p>
        <p>- {{ message.category }}</p>
      </div>
      <img class="tint" src="../assets/clippyog.png" alt="very helpful guy">
    </div>
  </div>
</template>

<script setup>

import { ref, onMounted } from 'vue';

const messages = [
  {
    "tip": "Understand the difference between assets, liabilities, and equity.",
    "category": "Accounting Basics"
  },
  {
    "tip": "Learn to read and interpret financial statements.",
    "category": "Financial Statements"
  },
  {
    "tip" : "Take a mental or physical note of what you buy and sell each round.",
    "category": "Note Taking"
  },
  {
    "tip": "Keep accurate records of all financial transactions.",
    "category": "Record Keeping"
  },
  {
    "tip": "Do not mindlessly operate your business without a plan.",
    "category" : "Planning"
  },
  {
    "tip": "Stay organized and keep track of important deadlines.",
    "category": "Organization"
  },
  {
    "tip": "Develop a basic understanding of tax laws and regulations.",
    "category": "Taxation"
  },
  {
    "tip": "Track your expenses and income on a regular basis.",
    "category": "Expense Tracking"
  },
  {
    "tip": "Get familiar with accounting terminology in the Glossary Center.",
    "category": "Terminology"
  },
  {
    "tip": "Learn the basics of double-entry accounting.",
    "category": "Double-Entry Accounting"
  },
  {
    "tip": "Understand the importance of cash flow management.",
    "category": "Cash Flow"
  },
  {
    "tip": "Get professional help when needed (Email or talk to your professor!).",
    "category": "Professional Help"
  },
  {
    "tip": "Know your financial ratios and what they mean.",
    "category": "Financial Ratios"
  },
  {
    "tip": "Learn how to prepare financial forecasts and projections.",
    "category": "Financial Projections"
  },
  {
    "tip": "Understand the concept of depreciation.",
    "category": "Depreciation"
  },
  {
    "tip": "Keep accurate inventory records.",
    "category": "Inventory"
  },
  {
    "tip": "Regularly review your financial statements.",
    "category": "Financial Review"
  },
  {
    "tip": "Understand how to play the demand curve.",
    "category": "Demand Curve"
  }
]

const getRandomMessage = () => {
  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex];
}

const clippyHelper = ref(null);
const message = ref({ tip: '', category: '' });
let cooldownTimeout = null;

onMounted(() => {
  // When clippy helper is mouseovered, make him looking
  // clippyHelper.value.addEventListener('mouseover', () => {
  //   document.getElementById("static").src = "https://cdn.discordapp.com/attachments/845116436727988255/1097971504521682954/looking.png"
  // });
  // If clippy helper is clicked, make him thinking, and get+show a random message...
  clippyHelper.value.addEventListener('click', () => {
      message.value = getRandomMessage();
      showChatBubble();
      // document.getElementById("static").src = "https://cdn.discordapp.com/attachments/845116436727988255/1097970741548425326/thinking.gif"
  });
  // Replace to static if not mouseovered AND not active
  // clippyHelper.value.addEventListener('mouseout', () => {
  //   if (!clippyHelper.value.classList.contains('active')) {
  //     document.getElementById("static").src = "https://cdn.discordapp.com/attachments/845116436727988255/1097970741275807865/static.png"
  //   }
  // });
});

// Display a chat bubble
const showChatBubble = () => {
  clippyHelper.value.classList.add('active');
  clearTimeout(cooldownTimeout);
  cooldownTimeout = setTimeout(() => {
    if (!clippyHelper.value) return;
    clippyHelper.value.classList.remove('active');
  }, 3000);
}
</script>

<style>

#clippyHelper {
  transform: translate(0, -82%) scale(0.7);
  top: 0;
  right: 0;
  position: absolute;
}

#clippyHelper .container {
  position: relative;
}

#clippyHelper img {
  margin-left: 0px;
  height: 20vh;
  transform-origin: bottom;
  transition: transform 0.1s ease-out;
}

#clippyHelper img:hover {
  transform: scale(1.05);
}

#clippyHelper img:active {
  transform: scale(0.98);
}

#clippyHelper .chat-bubble {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  text-align: left;
  padding-left: 10px;
  background-color: #b37dd9;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  overflow: hidden;
  width: 300px;
  height: 100px;
  position: absolute;
  margin-left: -300px;
  margin-top: -75px;
  top: 0;
  z-index: 11;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

#clippyHelper.active .chat-bubble {
  pointer-events: all;
  opacity: 1;
}

#clippyHelper .chat-bubble p {
  font-size: 1.2em;
}

/* make font smaller for category */
#clippyHelper p.chat-bubble-category {
  font-size: 0.8em;
}

</style>
