<template>
    <div v-if="loading">
        <h1>Loading...</h1>
    </div>
    <canvas v-else ref="stockMarket"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import { mapActions, mapGetters } from 'vuex';

function average(arr) {
  let s = 0;
  for (const v of arr) {
    s += v;
  }
  return s / arr.length;
}

export default {
  methods: {
    ...mapActions(['fetchStockTickerData']),
  },
  computed: {
      ...mapGetters([
          'getStockTickerData'
      ]),
  },
  data() {
    return {
      loading: false
    }
  },
  props: ['scoreboardData'],
  async created() {

    const d = this.scoreboardData;
    if (d.rounds.length === 0) {
      console.warn("StockMarket was mounted but the round length was 0. Rendering nothing...")
      return;
    }

    (async () => {
      // Skip last round since no data exists
      const stockTickerData = await Promise.all(Array.from({ length: d.rounds.length }).map((_, round) => {
        return this.fetchStockTickerData({
          semesterId: this.$route.params.semesterId,
          roundConfigId: d.rounds[round].roundId,
        }).then(() => this.getStockTickerData);
      }));

      // Mapped by <id>: { label, data, borderColor }
      const teamData = {};

      const _team = d.rounds[0].teamDatas[0]
      const _stockEntry = stockTickerData[0].find(t => t.team.id === _team.teamId);

      // Terrible solution to get the starting price
      const base = _stockEntry.stockPrice - _stockEntry.changeFromPreviousRound

      for (const team of d.rounds[0].teamDatas) {
        teamData[team.teamId] = {
          label: team.teamName,
          // not much efficient, but this is the least of our concerns
          data: [base, ...d.rounds.map((_, ri) => stockTickerData[ri].find(t => t.team.id === team.teamId).stockPrice)],
          borderColor: team.teamColorCode,
        }
      }

      const stockIndex = {
        label: "Index",
        data: [base, ...d.rounds.map((_, ri) => average(stockTickerData[ri].map(t => t.stockPrice)))]
      }

      new Chart(this.$refs.stockMarket, {
        type: 'line',
        data: {
          labels: Array.from({ length: d.numTotalRounds }).map((_, round) => round === 0 ? `IPO` : `Round ${round}`),
          datasets: [...Object.values(teamData), stockIndex],
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              type: 'linear',
              grace: '5%',
              ticks: {
                // color: 'black',
              }
            },
            x: {
              ticks: {
                // color: 'black',
              }
            }
          },
          plugins: {
            legend: {
              labels: {
                // color: 'black',
              }
            }
          }
        }
      });
    })();

    this.loading = false;
  }
}
</script>

<style scoped>

h1 {
  color: var(--text-primary);
}
</style>
