import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import { store } from './store/store'
import ApolloClient from 'apollo-client'
import { createHttpLink, HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";

Vue.use(Router);
Vue.config.productionTip = false;

import AdminHome from './components/AdminHome.vue';
import AdminInitiatedOnboard from './components/AdminInitiatedOnboard.vue';
import ExistingUserLicenseOnboard from './components/ExistingUserLicenseOnboard.vue';
import Game from './components/Game.vue';
import Semester from './components/Semester.vue';
import NewSemester from './components/NewSemester.vue';
import Onboard from './components/Onboard.vue';
import PlainTextCourseTemplate from './components/PlainTextCourseTemplate.vue';
import Login from './components/Login.vue';
import Maintenance from './components/Maintenance.vue';
import NotifyMessage from './components/NotifyMessage.vue';
import Confirm from './components/Confirm.vue';
import ResetPassword from './components/ResetPassword.vue';
import JournalEntriesAndTAccounts from './components/JournalEntriesAndTAccounts.vue';
import gql from 'graphql-tag';

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/adminHome',
      name: 'AdminHome',
      component: AdminHome
    },
    {
      path: '/adminInitiatedOnboard',
      name: 'AdminInitiatedOnboard',
      component: AdminInitiatedOnboard,
      props: true
    },
    {
      path: '/existingUserLicenseOnboard',
      name: 'ExistingUserLicenseOnboard',
      component: ExistingUserLicenseOnboard,
      props: true
    },
    {
      path: '/Game/:semesterId/:teamId',
      name: 'GameIndex',
      component: Game,
      props: true
    },
    {
      path: '/Game/:semesterId/:teamId/:routeDetail',
      name: 'Game',
      component: Game,
      props: true
    },
    {
      path: '/semester',
      name: 'Semester',
      component: Semester
    },
    {
      path: '/semester/new',
      name: 'NewSemester',
      component: NewSemester
    },
    {
      path: '/join-game',
      name: 'Onboard',
      component: Onboard,
      props: true
    },
    {
      path: '/courseTemplate',
      name: 'PlainTextCourseTemplate',
      component: PlainTextCourseTemplate
    },
    {
      path: '/',
      name: 'Login',
      component: Login,
      props: true
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: Maintenance
    },
    {
      path: '/notifyMessage',
      name: 'NotifyMessage',
      component: NotifyMessage,
      props: true
    },
    {
      path: '/confirm',
      name: 'Confirm',
      component: Confirm
    },
    {
      path: '/resetPassword',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/journalEntriesAndTAccounts',
      name: 'JournalEntriesAndTAccounts',
      component: JournalEntriesAndTAccounts
    },
    {
      path: '/component-test',
      name: 'ComponentTest',
      component: () => import('./components/ComponentTest.vue')
    }
  ]
});

const getIdToken = async () => {
  const adminUserToken = sessionStorage.getItem('accountonomics.adminUserToken');
  const currentTimestamp = Date.now();
  if(!sessionStorage.getItem('accountonomics.token')) {
    console.error("no token");
  }
  // FIXME: This is very bad for production, but we will never hit production anyway
  // so this is ok
  const timeToMakeRequestMilliSeconds = 1200000000; // value very high. practically disabled
  const tokenExpTime = parseInt(store.getters.getTokenExpTime);
  const refreshToken = store.getters.getRefreshToken.toString();
  const logoutTimestamp = tokenExpTime + timeToMakeRequestMilliSeconds;
  let token;

  if(currentTimestamp > logoutTimestamp) {

    localStorage.clear();
    sessionStorage.clear();
    store.dispatch('resetVuexState');
    router.push({ 
      name: 'Login', 
      params: { 
        notifyMessage: "You have been logged out due to inactivity"
      } 
    });
    return;
  } 

  // timing might be off slightly by the time request is fufilled and is trying to make request with old token
  if(currentTimestamp > (tokenExpTime - 30000)) {
    await store.dispatch('refreshIdToken', {refreshToken: refreshToken});
  }

  token = adminUserToken ? adminUserToken : store.getters.getToken;
  return token;
}

const httpLink = createHttpLink({ uri: process.env.VUE_APP_API_URL });
const middlewareLink = setContext(async () => {
  return {
    headers: {
      authorization: await getIdToken(),
      client: store.getters.getUserType
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const adminUserToken = sessionStorage.getItem('accountonomics.adminUserToken');
  if(adminUserToken && networkError) {
    sessionStorage.removeItem('accountonomics.adminUserToken');

    router.push({ 
      name: 'AdminHome'
    });
  }

  if(graphQLErrors) {
    if(router.currentRoute.name !== "Login") {
      let notifyMessage;

      if(graphQLErrors[0].message === "user license is revoked, no repurchase") {
        notifyMessage = "This license has been revoked and flagged. Please contact Accountonomics support for more details.";
      } 
      if(graphQLErrors[0].message === "user license is revoked" || graphQLErrors[0].message === "no licenses found") {
        notifyMessage = "Invalid license. Please try purchasing a new one";
      }
      if(graphQLErrors[0].message === "invalid permissions") {
        notifyMessage = "Invalid permissions.";
      } 
      if(notifyMessage) {
        router.push({
          name: 'Login',
          params: {
            notifyMessage: notifyMessage
          }
        });
      }
    }
  }
});
 
const links = ApolloLink.from([middlewareLink, errorLink, httpLink]);

export const apolloClient = new ApolloClient({
  link: links,
  cache: new InMemoryCache()
});

Vue.filter('toCurrency', function (value: number) {
  if(typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
  });
  const formattedValue = formatter.format(value);
  if(formattedValue.substr(0, 1) === '-'){
    return `(${formattedValue.substring(1, formattedValue.length)})`;
  }
  return formattedValue;
});

Vue.filter('pretty', function (value: string) {
  return JSON.stringify(JSON.parse(value), null, 2);
});

const url = process.env.VUE_APP_LAMBDA_URL
const checkMaintenanceMode = async() => {
  if (/** SKIP MAINTENANCE MODE */ true) return { text: "Fuck yourself" };
  try {
    const response = await fetch(`${url}/status`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
          'Content-Type': 'application/json',
          'Access-Control-Request-Method': 'GET'
      }
    });

    const responseJson = await response.json();
    if(response.ok) {
      return responseJson;
    } else {
      return Promise.reject(responseJson);
    }
  } catch(err) {
    return Promise.reject(err);
  }
}

router.beforeEach(async (to, from, next) => {
  if(to.name !== 'Maintenance') {
    const response = await checkMaintenanceMode();
    if(response.text === "NOT_READY") {
      next({ name: 'Maintenance' });
    }
  }
  next();
});

new Vue({
  store: store,
  router,
  render: h => h(App),
}).$mount('#app');
