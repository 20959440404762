<template>
    <div class="message-form-container">
        <div>
            <md-field>
                <label>Type your message here.</label>
                <md-textarea v-model="messageText"></md-textarea>
            </md-field>
        </div>
        <div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-15">
            </div>
            <div v-if="isAdmin" class="md-layout-item md-size-25 text-center">
                Professors
            </div>
            <div v-if="(isAdmin && professorSelection && professorSelection.name !== 'All Professors') || !isAdmin" class="md-layout-item md-size-25 text-center">
                Games
            </div>
            <div v-if="semesterSelection && semesterSelection.name !== 'All Games'" class="md-layout-item md-size-25 text-center">
                Teams
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-15">
                Send To:
            </div>
            <div v-if="isAdmin" class="md-layout-item md-size-25">
                <div class="custom-select-wrapper">
                    <select class="custom-select" name="basic-dropdown" v-model="professorSelection">
                        <option v-for="professorDropdownOption in professorDropdownOptions" v-bind:key="professorDropdownOption.id" :value="professorDropdownOption">{{professorDropdownOption.name}}</option>
                    </select>
                </div>
            </div>
            <div v-if="(isAdmin && professorSelection && professorSelection.name !== 'All Professors') || !isAdmin" class="md-layout-item md-size-25">
                <div class="custom-select-wrapper">
                    <select class="custom-select" name="basic-dropdown" v-model="semesterSelection">
                        <option v-for="semesterDropdownOption in filteredSemesterDropdownOptions" v-bind:key="semesterDropdownOption.id" :value="semesterDropdownOption">{{semesterDropdownOption.name}}</option>
                    </select>
                </div>
            </div>
            <div v-if="semesterSelection && semesterSelection.name !== 'All Games'" class="md-layout-item md-size-25">
                <div class="custom-select-wrapper">
                    <select class="custom-select" name="basic-dropdown" v-model="teamSelection">
                        <option v-for="filteredTeamDropdownOption in filteredTeamDropdownOptions" v-bind:key="filteredTeamDropdownOption.id" :value="filteredTeamDropdownOption">{{filteredTeamDropdownOption.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-size-15 priority-label">
                Priority: 
            </div>
            <div class="md-layout-item md-size-25">
                <md-radio class="md-primary" v-for="priorityType in priorityTypes" :key="priorityType.property" v-model="selectedPriorityType" :value="priorityType">{{priorityType.text}}</md-radio>
            </div>
        </div>
        <div class="btn-container md-layout">
            <div class="md-layout-item"></div>
            <md-button :disabled="httpWait" @click="sendMessage()" class="md-raised btn-primary" :class="{ 'btn-disabled' : httpWait }">
                Send Message
                <md-progress-spinner v-if="httpWait" class="btn-spin" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            </md-button>
            <div class="md-layout-item"></div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item"></div>
            <div class="md-layout-item server-error" v-if="serverError">{{serverError}}</div>
            <div class="md-layout-item"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {GameRoundPhase} from "temporary-api-types/apiTypes";

export default {
    name: 'MessageForm',
    data() {
        return {
            messageText: "",
            professorSelection: null,
            semesterSelection: null,
            teamSelection: null,
            professorDropdownOptions: [],
            semesterDropdownOptions: [],
            teamDropdownOptions: [],
            filteredSemesterDropdownOptions: [],
            filteredTeamDropdownOptions: [],
            priorityTypes: [
                {
                    property: "APP_ONLY",
                    text: "App Only"
                },
                {
                    property: "REGULAR",
                    text: "Regular"
                },
                {
                    property: "CRITICAL",
                    text: "Critical"
                }
            ],
            selectedPriorityType: null,
            httpWait: false,
            serverError: null
        }
    },
    computed: {
        ...mapGetters([
            'getFormattedDate'
        ])
    },
    props: {
        isAdmin: {
            type: Boolean
        },
        semesters: {
            type: Array
        },
        successCb: {
            type: Function
        },
        alertMessage: {
            type: String
        }
    },
    watch: {
        professorSelection(val) {
            if(val && val.id && this.isAdmin) {
                this.filteredSemesterDropdownOptions = JSON.parse(JSON.stringify(this.semesterDropdownOptions)).filter((semester, index) => {
                    return index === 0 || semester.professorIds.includes(val.id)
                });
                this.semesterSelection = this.filteredSemesterDropdownOptions[0];
            }
        },
        semesterSelection(val) {
            if(val && val.teamIds) {
                this.filteredTeamDropdownOptions = JSON.parse(JSON.stringify(this.teamDropdownOptions)).filter((team, index) => {
                    return index === 0 || val.teamIds.includes(team.id)
                });
                this.teamSelection = this.filteredTeamDropdownOptions[0];
            }
        }
    },
    methods: {
        ...mapActions([
            'sendGameMessage',
            'fetchCurrentGameState'
        ]),
        async sendMessage() {
            this.httpWait = true;
            let teamIds;
            if(this.teamSelection.name === 'All Teams') {
                teamIds = JSON.parse(JSON.stringify(this.filteredTeamDropdownOptions)).filter(team => team.id)
                    .map(team => team.id);
            } else {
                teamIds = [this.teamSelection.id];
            }

            try {
                await this.sendGameMessage({
                    text: this.messageText,
                    teamIds,
                    priority: this.selectedPriorityType.property
                });

                this.$emit("update:alert-message", "Successfully sent message!");

                if(this.successCb) {
                    await this.successCb();
                    this.httpWait = false;
                }

                this.httpWait = false;
                return;
            } catch(err) {
                this.serverError = "Something went wrong. Please try again";
                this.httpWait = false;
                return;
            }
        }
    },
    async created() {
        let professorsList = [];
        let teamsList = [];

        const activeSemesters = this.semesters.filter((semester) => {
            return semester.isFlowMode || semester.currentGamePhase !== GameRoundPhase.GameOver;
        });

        const semestersList = activeSemesters.map((semester) => {
            for(let team of semester.teams) {
                teamsList.push({
                    id: team.id,
                    name: team.name
                })
            }
            for(let professor of semester.professors) {
                if(!professorsList.some(_professor => _professor.id === professor.id)) {
                    professorsList.push({
                        id: professor.id,
                        name: professor.email
                    })
                }
            }
            const teamIds = semester.teams.map(team => team.id);
            const professorIds = semester.professors.map(professor => professor.id);

            return {
                id: semester.id,
                name: semester.name,
                teamIds,
                professorIds
            }
        });

        this.semesterDropdownOptions = [
            {
                name: "All Games"
            }
        ].concat(semestersList);

        this.teamDropdownOptions = [
            {
                name: "All Teams"
            }
        ].concat(teamsList);

        if(this.isAdmin) {
            this.professorDropdownOptions = [
                {
                    name: "All Professors"
                }
            ].concat(professorsList);
            
            this.professorSelection = this.professorDropdownOptions[0];
            this.priorityTypes = this.priorityTypes.concat({
                property: "NUCLEAR",
                text: "Nuclear"
            });
        }

        this.filteredTeamDropdownOptions = JSON.parse(JSON.stringify(this.teamDropdownOptions));
        this.filteredSemesterDropdownOptions = JSON.parse(JSON.stringify(this.semesterDropdownOptions));
        this.semesterSelection = this.filteredSemesterDropdownOptions[0];
        this.teamSelection = this.filteredTeamDropdownOptions[0];
        this.selectedPriorityType = this.priorityTypes[0];
    }
}
</script>

<style scoped>
    .message-form-container {
        margin: 25px;
        overflow-y: scroll;
    }

    .message-form {
        height: 70%;
        width: 80%;
        background-color: darkgray;
        margin-top: 60px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid black;
        overflow-y: scroll;
    }

    .message {
        color: greenyellow;
    }

    .message-input {
        height: 100px
    }

    .text-center {
        text-align: center;
    }

    .custom-select {
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid black;
        width: 100%;
    }

    .custom-select select {
        padding-left: 10px;
        background-color: transparent;
        font-size: 18px;
        outline: none;
        appearance: none;
        cursor: pointer;
    }

    .custom-select-wrapper {
        position: relative;
        user-select: none;
        width: 100%;
    }

    .btn-container {
        padding-top: 50px;
    }

    .priority-label {
        line-height: 4;
    }

    .server-error {
        text-align: center;
        color: var(--warning-text-color);
    }
</style>
