<template>
  <!-- larger WIP -->
    <div id="feedbackForm" ref="feedbackForm">
      <div class="popup">
        <span @click="closePopup" class="close-button">X</span>
        <div class="popup-content">
          <p>STUDENT FEEDBACK REQUESTED! WIN $$$! REPORT BUGS HERE TOO</p>
          <p><a v-bind:href="url" target="_blank">CLICK HERE!</a></p>
        </div>
      </div>
    </div>
    <!-- <div>
      <p>STUDENT FEEDBACK REQUESTED! WIN $! REPORT BUGS HERE TOO</p>
      <p><a v-bind:href="url" target="_blank">CLICK HERE!</a></p>
    </div> -->
</template>

<script setup>
  import { ref, onMounted } from "vue";
  // import Vue from 'vue'
  // import { feedback } from './modules/feedback';

  const feedbackForm = ref(null);
  const url = "https://docs.google.com/forms/d/e/1FAIpQLSd-82kfWSoVtmYZXPATa8Aty_BFP0u7tvXjCU0f0wVrhbgROA/viewform?usp=sf_link";

  const showPopup = () => {
    feedbackForm.value.classList.add("active");
  };
  const closePopup = () => {
      feedbackForm.value.classList.remove("active");
      feedbackForm.value.style.display = "none"; // XXX FIXME TODO this is a hack hudson fix this
      // store usage WIP
      // feedback.dispatch(toggleFeedbackClosed);
  };
  onMounted(() => {
      setTimeout(() => {
          showPopup();
      }, 1500);
  });
</script>
  
<style scoped>

  p {
    font-family: 'Baloo 2';
    font-weight: bold;
    color: var(--text-primary);
  }
  #feedbackForm {
    position: relative;
  }
  .popup {
    border-radius: 4px;
    display: inline-block;
    padding: 10px;
    margin-top: 35px;
    border: 1px solid #ccc;
    background-color: var(--inner-box-color);
    position: relative;
    opacity: 0;
  }
  
  .popup-content {
    padding: 10px;
  }
  
  .close-button {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 18px;
  }

  #feedbackForm .popup {
    pointer-events: none;
    transition: opacity 0.3s ease-in;
  }

  #feedbackForm.active .popup {
    pointer-events: all;
    opacity: 1;
  }

</style>
  