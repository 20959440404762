<template>
    <div v-if="isPageReady && getCurrentJournalEntriesAndTAccounts && getCurrentJournalEntriesAndTAccounts.journalEntries.length" class="journal-entries-and-t-accounts-container">
        <md-dialog class="modal revoke-license-modal" :md-active="showFixedAssetsModal">
            <md-dialog-title class="modal-title">Fixed Assets</md-dialog-title>

            <div class="md-layout" style="overflow-y: scroll">
                <div class="md-layout-item fixed-assets-content">
                  <fixed-assets-ledger :hide-useful-life="true"></fixed-assets-ledger>
                </div>
            </div>

            <md-dialog-actions>
                <md-button class="md-accent" @click="showFixedAssetsModal = false">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
        <div class="page-title">
            <span style="color: var(--text-primary)" v-if="!isCurrentRound">End of </span><span style="color: var(--text-primary)">Round {{roundNum}} Journal Entries</span>
        </div>
        <div class="fixed-assets-button-container">
          <md-button class="fixed-assets-link md-raised btn-primary" @click="showFixedAssetsModal = true">Show Fixed Assets</md-button>
        </div>
        <div v-if="journalEntryFilter" class="filtered-journal-entry-banner">
            <span>These Journal Entries are filtered results from a Line Item</span>
            -
            <span class="link clear-filter-link" @click="currentJournalEntryFilter = null">Clear Filter</span>
        </div>
        <h4>Click on any Journal Entry to see associated T Accounts</h4>
        <div class="journal-entries-container" v-for="journalEntry in filteredJournalEntries" :key="journalEntry.id" :class="{ 'journal-entry-selected' : journalEntry.id === selectedJournalEntryId }">
            <div @click="selectJournalEntry(journalEntry.id)" class="journal-entry-table link md-layout-item" :class="{ 'journal-entry-table-selected' : journalEntry.id === selectedJournalEntryId }">
                <div class="md-layout journal-entry-header" :class="{ 'journal-entry-selected-header' : journalEntry.id === selectedJournalEntryId }">
                    <div class="md-layout-item text-left">{{journalEntry.friendlyName}} #{{journalEntry.miniId}}</div>
                    <div class="md-layout-item journal-entry-amount-column">Debits</div>
                    <div class="md-layout-item journal-entry-amount-column">Credits</div>
                </div>
                <div class="md-layout journal-entry-content" v-for="entry in journalEntry.entries" :key="entry.id">
                    <div class="md-layout-item text-left" :class="{'account-label-credit' : entry.amount < 0 || (entry.amount === 0 && entry.normalBalance === 'CREDIT') }">{{entry.friendlyName}}</div>
                    <div class="md-layout-item text-right journal-entry-amount-column">
                        <span v-if="entry.amount > 0 || (entry.amount === 0 && entry.normalBalance !== 'CREDIT')">{{entry.amount | toCurrency}}</span>
                    </div>
                    <div class="md-layout-item text-right journal-entry-amount-column">
                        <span v-if="entry.amount < 0 || (entry.amount === 0 && entry.normalBalance === 'CREDIT')">{{Math.abs(entry.amount) | toCurrency}}</span>
                    </div>
                </div>
            </div>
            <div v-if="selectedJournalEntryId === journalEntry.id" class="t-accounts-container md-layout-item">
                <h2>T Accounts</h2>
                <div class="t-account-table" v-for="tAccount in displayableTAccounts" :key="tAccount.account">
                    <div class="md-layout">
                        <div class="md-layout-item">{{tAccount.friendlyName}}</div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item">Normal Balance: {{tAccount.normalBalance}}</div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item">Type: {{tAccount.type}}</div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item"></div>
                        <div class="md-layout-item border-bottom">Debits</div>
                        <div class="md-layout-item border-bottom">Credits</div>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item">Beginning</div>
                        <div class="md-layout-item border-right">
                            <span v-if="tAccount.beginningBalance > 0 || (tAccount.beginningBalance === 0 && tAccount.normalBalance !== 'CREDIT')">{{tAccount.beginningBalance | toCurrency}}</span>
                        </div>
                        <div class="md-layout-item">
                            <span v-if="tAccount.beginningBalance < 0 || (tAccount.beginningBalance === 0 && tAccount.normalBalance === 'CREDIT')">{{Math.abs(tAccount.beginningBalance) | toCurrency}}</span>
                        </div>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout" v-for="entry in tAccount.entries" :key="entry.id">
                        <div class="md-layout-item"></div>
                        <div class="md-layout-item border-right" :class="{ 'selected-t-account-entry' : entry.journalEntryId === selectedJournalEntryId && entry.amount > 0 }">
                            <span v-if="entry.amount > 0 || (entry.amount === 0 && entry.normalBalance !== 'CREDIT')">{{entry.amount | toCurrency}}</span>
                        </div>
                        <div class="md-layout-item" :class="{ 'selected-t-account-entry' : entry.journalEntryId === selectedJournalEntryId && entry.amount <= 0 }">
                            <span v-if="entry.amount < 0 || (entry.amount === 0 && entry.normalBalance === 'CREDIT')">{{Math.abs(entry.amount) | toCurrency}}</span>
                        </div>
                        <div class="md-layout-item"></div>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item">Ending</div>
                        <div class="md-layout-item border-right" :class="{ 'border-top' : tAccount.endingBalance > 0 || (tAccount.endingBalance === 0 && tAccount.normalBalance !== 'CREDIT') }">
                            <span v-if="tAccount.endingBalance > 0 || (tAccount.endingBalance === 0 && tAccount.normalBalance !== 'CREDIT')">{{tAccount.endingBalance | toCurrency}}</span>
                        </div>
                        <div class="md-layout-item" :class="{ 'border-top' : tAccount.endingBalance < 0 || (tAccount.endingBalance === 0 && tAccount.normalBalance === 'CREDIT') }">
                            <span v-if="tAccount.endingBalance < 0 || (tAccount.endingBalance === 0 && tAccount.normalBalance === 'CREDIT')">{{Math.abs(tAccount.endingBalance) | toCurrency}}</span>
                        </div>
                        <div class="md-layout-item"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="instructions-container" v-else>
      There are no Journal Entries to display
    </div>
</template>

<script>
import FixedAssetsLedger from './FixedAssetsLedger'
import { mapGetters, mapActions } from 'vuex'
import {
  UserTypeEnum
} from "temporary-api-types/apiTypes";

export default {
    name: 'JournalEntriesAndTAccounts',
    components: {
      FixedAssetsLedger
    },
    data() {
        return {
            attrs: {
                currentJournalEntryFilter: this.journalEntryFilter,
                currentAvailableJournalEntryFilters: this.availableJournalEntryFilters
            },
            teamId: null,
            semesterId: null,
            selectedJournalEntryId: null,
            displayableTAccounts: null,
            isCurrentRound: false,
            roundNum: null,
            isPageReady: false,
            showFixedAssetsModal: false
        }
    },
    computed: {
        ...mapGetters([
            'getCurrentJournalEntriesAndTAccounts',
            'getCurrentCourse',
            'getCurrentGameState',
            'getUserType'
        ]),
        currentJournalEntryFilter: {
            get() {
                return this.attrs.currentJournalEntryFilter;
            },
            set(value) {
                this.attrs.currentJournalEntryFilter = value;
                this.$emit(`update:journal-entry-filter`, value);
            }
        },
        currentAvailableJournalEntryFilters: {
            get() {
                return this.attrs.currentAvailableJournalEntryFilters;
            },
            set(value) {
                this.attrs.currentAvailableJournalEntryFilters = value;
                this.$emit(`update:available-journal-entry-filters`, value);
            }
        }
    },
    props: {
        selectedTeam: {
            type: Object
        },
        activeRoundId: {
            type: String
        },
        journalEntryFilter: {
            type: String
        },
        availableJournalEntryFilters: {
            type: Array
        }
    },
    watch: {
        async activeRoundId(newVal, oldVal) {
            if(newVal && newVal !== oldVal) {
                await this.init();
            }
        },
        selectedJournalEntryId(newVal, oldVal) {
            if(newVal && newVal !== oldVal) {
                this.filterTAccounts();
            }
        },
        journalEntryFilter(val) {
            if(val) {
                const filteredAccountEnums = this.getAccountEnumList(val);
                this.filteredJournalEntries = this.getCurrentJournalEntriesAndTAccounts.journalEntries.filter((journalEntry) => {
                    return !!journalEntry.entries.find((entry) => {
                        return filteredAccountEnums.validAccounts.includes(entry.account)
                    });
                });

                if(filteredAccountEnums.requiredAccounts && filteredAccountEnums.requiredAccounts.length) {
                    this.filteredJournalEntries = this.filteredJournalEntries.filter((journalEntry) => {
                        return !!journalEntry.entries.find((entry) => {
                            return filteredAccountEnums.requiredAccounts.includes(entry.account)
                        });
                    });
                }
            } else {
                this.filteredJournalEntries = this.getCurrentJournalEntriesAndTAccounts.journalEntries;
            }
            if(this.filteredJournalEntries.length) {
                this.selectedJournalEntryId = this.filteredJournalEntries[0].id;
            }
        }
    },
    methods: {
        ...mapActions([
            'fetchJournalEntriesAndTAccounts'
        ]),
        getAccountEnumList(accountName) {
            switch (accountName) {
                // Income Statement
                case "revenue":
                    return {
                        validAccounts: ["REVENUE"],
                        requiredAccounts: []
                    }
                case "returns":
                    return {
                        validAccounts: ["SALES_RETURNS_AND_ALLOWANCES"],
                        requiredAccounts: []
                    }
                case "net":
                    return {
                        validAccounts: ["REVENUE","SALES_RETURNS_AND_ALLOWANCES"],
                        requiredAccounts: []
                    }
                case "costOfGoodsSold":
                    return {
                        validAccounts: ["COGS"],
                        requiredAccounts: []
                    }
                case "grossProfit":
                    return {
                        validAccounts: ["REVENUE","SALES_RETURNS","COGS"],
                        requiredAccounts: []
                    }
                case "adExpense":
                    return {
                        validAccounts: ["ADVERTISING_EXPENSE"],
                        requiredAccounts: []
                    }
                case "depreciationExpense":
                    return {
                        validAccounts: ["DEPRECIATION_EXPENSE"],
                        requiredAccounts: []
                    }
                case "interest":
                    return {
                        validAccounts: ["INTEREST_EXPENSE"],
                        requiredAccounts: []
                    }
                case "badDebtExpense":
                    return {
                        validAccounts: ["BAD_DEBT"],
                        requiredAccounts: []
                    }
                case "gainOnAssetSales":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: []
                    }
                case "incomeTax":
                    return {
                        validAccounts: ["INCOME_SUMMARY"],
                        requiredAccounts: []
                    }
                // Balance Sheet
                case "cash":
                    return {
                        validAccounts: ["CASH"],
                        requiredAccounts: []
                    }
                case "accountsReceivable":
                    return {
                        validAccounts: ["RECEIVABLE"],
                        requiredAccounts: []
                    }
                case "inventory":
                    return {
                        validAccounts: ["INVENTORY"],
                        requiredAccounts: []
                    }
                case "equipment":
                    return {
                        validAccounts: ["EQUIPMENT"],
                        requiredAccounts: []
                    }
                case "equipmentDepreciation":
                    return {
                        validAccounts: ["A_D_EQUIPMENT"],
                        requiredAccounts: []
                    }
                case "furnishings":
                    return {
                        validAccounts: ["FURNISHING"],
                        requiredAccounts: []
                    }
                case "furnishingsDepreciation":
                    return {
                        validAccounts: ["A_D_FURNISHING"],
                        requiredAccounts: []
                    }
                case "buildings":
                    return {
                        validAccounts: ["BUILDING"],
                        requiredAccounts: []
                    }
                case "buildingsDepreciation":
                    return {
                        validAccounts: ["A_D_BUILDING"],
                        requiredAccounts: []
                    }
                case "land":
                    return {
                        validAccounts: ["LAND"],
                        requiredAccounts: []
                    }
                case "loanPayable":
                    return {
                        validAccounts: ["LOAN_PAYABLE"],
                        requiredAccounts: []
                    }
                case "commonStock":
                    return {
                        validAccounts: ["CASH"],
                        requiredAccounts: []
                    }
                case "retainedEarnings":
                    return {
                        validAccounts: ["RETAINED_EARNINGS"],
                        requiredAccounts: []
                    }
                case "bondsPayable":
                    return {
                        validAccounts: ["BONDS_PAYABLE"],
                        requiredAccounts: []
                    }
                case "bondsPremiumOrDiscount":
                    return {
                        validAccounts: ["PREMIUM_ON_BONDS","DISCOUNT_ON_BONDS"],
                        requiredAccounts: []
                    }
                // Cash Flow
                case "operatingActivitiesSales":
                    return {
                        validAccounts: ["REVENUE"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesPurchasedInventory":
                    return {
                        validAccounts: ["INVENTORY","COGS"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesPaidAdvertising":
                    return {
                        validAccounts: ["ADVERTISING_EXPENSE"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesSalesReturns":
                    return {
                        validAccounts: ["SALES_RETURNS_AND_ALLOWANCES"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesIncomeTaxesPaid":
                    return {
                        validAccounts: ["INCOME_TAX_EXPENSE"],
                        requiredAccounts: []
                    }
                case "operatingActivitiesInterestPaid":
                    return {
                        validAccounts: ["INTEREST_EXPENSE"],
                        requiredAccounts: []
                    }
                case "investingActivitiesInflowEquipment":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: ["EQUIPMENT"]
                    }
                case "investingActivitiesInflowFurnishings":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: ["FURNISHING"]
                    }
                case "investingActivitiesInflowBuildings":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: ["BUILDING"]
                    }
                case "investingActivitiesInflowLand":
                    return {
                        validAccounts: ["GAIN_ON_SALE"],
                        requiredAccounts: ["LAND"]
                    }
                case "investingActivitiesOutflowEquipment":
                    return {
                        validAccounts: ["EQUIPMENT"],
                        requiredAccounts: []
                    }
                case "investingActivitiesOutflowFurnishings":
                    return {
                        validAccounts: ["FURNISHING"],
                        requiredAccounts: []
                    }
                case "investingActivitiesOutflowBuildings":
                    return {
                        validAccounts: ["BUILDING"],
                        requiredAccounts: []
                    }
                case "investingActivitiesOutflowLand":
                    return {
                        validAccounts: ["LAND"],
                        requiredAccounts: []
                    }
                case "financingActivitiesCapital":
                    return {
                        validAccounts: ["STOCK"],
                        requiredAccounts: []
                    }
                case "bondBorrowing":
                    return {
                        validAccounts: ["BONDS_PAYABLE"],
                        requiredAccounts: []
                    }
                case "financingActivitiesLoanBorrowing":
                    return {
                        validAccounts: ["LOAN_PAYABLE"],
                        requiredAccounts: []
                    }
                case "bondRepayment":
                    return {
                        validAccounts: ["BONDS_PAYABLE"],
                        requiredAccounts: []
                    }
                case "financingActivitiesLoanRepay":
                    return {
                        validAccounts: ["LOAN_PAYABLE"],
                        requiredAccounts: []
                    }
                default:
                    return {
                        validAccounts: [],
                        requiredAccounts: []
                    }
            }
        },
        getAccountPropsListFromEnum(enumList) {
            let propsObj = {};

            enumList.forEach((enumName) => {
                switch(enumName) {
                    case "GAIN_ON_SALE":
                        propsObj["gainOnAssetSales"] = true;
                        if(enumList.includes("EQUIPMENT")) {
                            propsObj["investingActivitiesInflowEquipment"] = true;
                        }
                        if(enumList.includes("FURNISHING")) {
                            propsObj["investingActivitiesInflowFurnishings"] = true;
                        }
                        if(enumList.includes("BUILDING")) {
                            propsObj["investingActivitiesInflowBuildings"] = true;
                        }
                        if(enumList.includes("LAND")) {
                            propsObj["investingActivitiesInflowLand"] = true;
                        }
                        break;
                    case "REVENUE":
                        propsObj["revenue"] = true;
                        propsObj["net"] = true;
                        propsObj["grossProfit"] = true;
                        propsObj["operatingActivitiesSales"] = true;
                        break;
                    case "SALES_RETURNS_AND_ALLOWANCES":
                        propsObj["returns"] = true;
                        propsObj["net"] = true;
                        propsObj["operatingActivitiesSalesReturns"] = true;
                        break;
                    case "COGS":
                        propsObj["costOfGoodsSold"] = true;
                        propsObj["grossProfit"] = true;
                        propsObj["operatingActivitiesPurchasedInventory"] = true;
                        break;
                    case "SALES_RETURNS":
                        propsObj["grossProfit"] = true;
                        break;
                    case "ADVERTISING_EXPENSE":
                        propsObj["adExpense"] = true;
                        propsObj["operatingActivitiesPaidAdvertising"] = true;
                        break;
                    case "DEPRECIATION_EXPENSE":
                        propsObj["depreciationExpense"] = true;
                        break;
                    case "INTEREST_EXPENSE":
                        propsObj["interest"] = true;
                        propsObj["operatingActivitiesInterestPaid"] = true;
                        break;
                    case "INCOME_SUMMARY":
                        propsObj["incomeTax"] - true;
                        break;
                    case "CASH":
                        propsObj["cash"] = true;
                        propsObj["commonStock"] = true;
                        break;
                    case "INVENTORY":
                        propsObj["inventory"] = true;
                        propsObj["operatingActivitiesPurchasedInventory"] = true;
                        break;
                    case "EQUIPMENT":
                        propsObj["equipment"] = true;
                        propsObj["investingActivitiesOutflowEquipment"] = true;
                        break;
                    case "A_D_EQUIPMENT":
                        propsObj["equipmentDepreciation"] = true;
                        break;
                    case "FURNISHING":
                        propsObj["furnishings"] = true;
                        propsObj["investingActivitiesOutflowFurnishings"] = true;
                        break;
                    case "A_D_FURNISHING":
                        propsObj["furnishingsDepreciation"] = true;
                        break;
                    case "BUILDING":
                        propsObj["buildings"] = true;
                        propsObj["investingActivitiesOutflowBuildings"] = true;
                        break;
                    case "A_D_BUILDING":
                        propsObj["buildingsDepreciation"] = true;
                        break;
                    case "LAND":
                        propsObj["land"] = true;
                        propsObj["investingActivitiesOutflowLand"] = true;
                        break;
                    case "LOAN_PAYABLE":
                        propsObj["loanPayable"] = true;
                        propsObj["financingActivitiesLoanBorrowing"] = true;
                        propsObj["financingActivitiesLoanRepay"] = true;
                        break;
                    case "RETAINED_EARNINGS":
                        propsObj["retainedEarnings"] = true;
                        break;
                    case "BONDS_PAYABLE":
                        propsObj["bondsPayable"] = true;
                        propsObj["bondBorrowing"] = true;
                        propsObj["bondRepayment"] = true;
                        break;
                    case "PREMIUM_ON_BONDS":
                        propsObj["bondsPremiumOrDiscount"] = true;
                        break;
                    case "DISCOUNT_ON_BONDS":
                        propsObj["bondsPremiumOrDiscount"] = true;
                        break;
                    case "INCOME_TAX_EXPENSE":
                        propsObj["operatingActivitiesIncomeTaxesPaid"] = true;
                        break;
                    case "STOCK":
                        propsObj["financingActivitiesCapital"] = true;
                        break;
                    case "RECEIVABLE":
                        propsObj["accountsReceivable"] = true;
                        break;
                    case "BAD_DEBT":
                        propsObj["badDebtExpense"] = true;
                        break;
                    default:
                        break;
                }
            });
            return Object.keys(propsObj);
        },
        filterTAccounts() {
            let selectedJournalEntry = this.getCurrentJournalEntriesAndTAccounts.journalEntries.filter((journalEntry) => {
                return this.selectedJournalEntryId === journalEntry.id
            })[0];

            let selectedAccounts = selectedJournalEntry.entries.map(entry => entry.account);

            this.displayableTAccounts = this.getCurrentJournalEntriesAndTAccounts.tAccounts.filter((tAccount) => {
                return selectedAccounts.includes(tAccount.account)
            });
        },
        selectJournalEntry(id) {
            this.selectedJournalEntryId = id;
        },
        async init() {
            if(this.activeRoundId && this.teamId && this.selectedTeam) {
                const gameConfig = this.selectedTeam.semester.gameConfig;
                const courseRounds = this.getCurrentCourse.rounds;
                const roundIndex = courseRounds.map((_round) => _round.id).indexOf(this.activeRoundId);
                const gameState = this.getCurrentGameState;
                this.isCurrentRound = this.activeRoundId === gameState.roundId;
                this.roundNum = roundIndex + 1;

                if(gameConfig.roundFeatures.journalEntryTAccountConfig[roundIndex] === "OFF" || (gameConfig.roundFeatures.journalEntryTAccountConfig[roundIndex] === "SHOW_FOR_PAST_ROUNDS" && this.isCurrentRound && this.getUserType === UserTypeEnum.Student)) {
                    return;
                } else {
                    await this.fetchJournalEntriesAndTAccounts({
                        roundConfigId: this.activeRoundId,
                        teamId: this.teamId
                    });
                    if(this.getCurrentJournalEntriesAndTAccounts.journalEntries.length) {
                        this.filteredJournalEntries = this.getCurrentJournalEntriesAndTAccounts.journalEntries;
                        this.selectedJournalEntryId = this.filteredJournalEntries[0].id;

                        const enumsList = this.getCurrentJournalEntriesAndTAccounts.journalEntries.flatMap((entry) => {
                            return entry.entries.map(_entry => _entry.account)
                        });

                        const dedupedEnumsList = enumsList.filter((item, pos) => {
                            return enumsList.indexOf(item) == pos;
                        });

                        this.currentAvailableJournalEntryFilters = this.getAccountPropsListFromEnum(dedupedEnumsList);
                    }
                }

                this.isPageReady = true;
            }
        }
    },
    async created() {
        this.teamId = this.$route.params.teamId;
        await this.init();
    }
}
</script>

<style scoped>

    h1, h2, h3, h4 {
        color: var(--text-primary);
    }
    .journal-entries-and-t-accounts-container {
        padding-bottom: 64px;
    }

    .journal-entries-container {
        padding-top: 20px;
    }

    .journal-entry-table {
        border: solid 1px var(--border-color);
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        max-width: 500px;
        background-color: var(--inner-box-color);
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .account-label-credit {
        padding-left: 20px;
    }

    .t-account-table {
        border: solid 1px black;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-right: 20px;
        width: 400px;
        display: inline-block;
        vertical-align: top;
        background-color: #ffffff11;
    }

    .border-top {
        border-top: solid 2px black;
    }

    .border-bottom {
        border-bottom: solid 2px black;
    }

    .border-right {
        border-right: solid 2px black;
    }

    .journal-entry-selected {
        background-color: var(--selected-je-bg);
    }

    .journal-entry-header {
        background-color: var(--table-title-bg-color);
        color: white;
        padding-left: 10px;
        padding-right: 10px;
    }

    .journal-entry-content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .journal-entry-amount-column {
        max-width: 70px;
    }

    .journal-entry-selected-header {
        background-color: var(--success-bg-color);
        color: white;
        padding-left: 10px;
        padding-right: 10px;
    }

    .journal-entry-table-selected {
        border: 2px solid var(--success-bg-color);
        background-color: white;
    }

    .selected-t-account-entry {
        font-weight: bold;
        background-color: var(--table-cell-color);
    }

    .instructions-container {
      margin-top: 60px;
    }

    .page-title {
        font-weight: bold;
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .filtered-journal-entry-banner {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 15px;
        margin-right: 15px;
        border: 2px solid var(--btn-primary-color);
        font-weight: bold;
        background: rgba(67,176,249,0.12) !important;
    }

    .clear-filter-link {
        color: var(--btn-primary-color);
    }

    .clear-filter-link:hover {
        text-decoration: underline;
    }

    .fixed-assets-content {
      margin-left: 15px;
      margin-right: 15px;
    }

    .fixed-assets-button-container {
      position: relative;
      width: 100%;
    }

    .fixed-assets-link {
      position: absolute;
      right: 15px;
    }

    .fixed-assets-link:hover {
      cursor: pointer;
    }
</style>
