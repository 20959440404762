<template>
    <div class="scoreboard-container">
        <div v-if="getScoreboardData && getScoreboardData.rounds.length > 0">
            <stock-ticker v-if="roundConfigId" :active-round-id="roundConfigId"></stock-ticker>
            <div style="height: 1px; background-color: white;"></div>
            <div class="countdown" v-if="shouldShowCountdown">
                <Countdown :deadline="currentDeadline" :countdown-id="'scoreboard'"></Countdown>
                <div class="remaining-label">remaining</div>
            </div>
            <div class="title-and-feedback-container">
                <h1></h1>
                <h1 style="height: fit-content; padding: 5px;">{{ getScoreboardData.semesterName }} Scoreboard</h1>
                <!-- feedback in scoreboard w/ store WIP -->
                <FeedbackForm/>
            </div>
            <h3 style="padding: 10px;">{{ getScoreboardData.numRoundsCompleted }} / {{ getScoreboardData.numTotalRounds }} rounds completed</h3>
            <h2><Dict>Round-by-Round Scoreboard</Dict></h2>
            <div class="graph-container">
                <div class="scoreboard-element">
                    <div class="round-nav-container md-layout">
                        <div class="round-label md-layout-item">
                            <span v-if="previousRoundNum > 0" class="link" @click="selectRound(previousRoundNum)"><md-icon class="fa fa-chevron-left"></md-icon>Round {{ previousRoundNum }}</span>
                        </div>
                        <div class="round-label md-layout-item">
                            Round {{ selectedRoundNum }}
                        </div>
                        <div class="round-label md-layout-item">
                            <span v-if="nextRoundNum <= getScoreboardData.rounds.length" class="link" @click="selectRound(nextRoundNum)">Round {{ nextRoundNum }}<md-icon class="fa fa-chevron-right"></md-icon></span>
                        </div>
                    </div>
                    <bar-graph :data="roundByRoundData" :options="roundByRoundOptions"></bar-graph>
                </div>
            </div>
            <h2><Dict>Cumulative Scoreboard</Dict></h2>
            <div class="graph-container">
                <div class="scoreboard-element">
                    <bar-graph :data="cumulativeData" :options="cumulativeOptions"></bar-graph>
                </div>
            </div>
            <div class="sales-and-stock">
                <div class="product-graph-row" style="height: 100%;">
                    <h2>Round {{ selectedRoundNum }} Sales Totals (Financial Ratios)</h2>
                    <div class="scoreboard-element-sales">
                        <financial-ratios-table class="scoreboard-ratios" v-if="roundConfigId" :semester-id="semesterId" :round-config-id="roundConfigId" :team-id="null"></financial-ratios-table>
                    </div>
                </div>
                <div style="margin-right: 35px; min-width: 100%;">
                    <h2><Dict>Stock Market</Dict></h2>
                    <div class="scoreboard-element" style="min-width: 90%; min-height: 80%; margin-right: 20px;">
                        <StockMarket :scoreboard-data="getScoreboardData"/>
                    </div>
                </div>
            </div>
            <div class="graph-container">
                <div class="md-layout">
                    <!-- TODO: move these into dropdowns to reduce vertical scrolling -->
                    <div class="md-layout-item" v-for="(product, index) in productData" :key="product.productName">
                        <div class="scoreboard-element-product">
                            <div class="product-graph-row">
                                <h2>{{ product.productName }}</h2>
                                <div class="md-layout">
                                    <div class="md-layout-item">
                                        <h3>Sales Quantity (units)</h3>
                                        <pie-chart :data="product.chartDataUnitSales" :options="product.chartOptionsUnitSales"></pie-chart>
                                    </div>
                                    <div class="md-layout-item">
                                        <h3>Sales Amount ($)</h3>
                                        <pie-chart :data="product.chartDataDollarSales" :options="product.chartOptionsDollarSales"></pie-chart>
                                    </div>
                                </div>
                                <team-data-table :team-data="product.teamDataWithTotals"></team-data-table>
                                <div>
                                    <canvas :id="`demand-curve-${index}`"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="no-state-label" v-else>
          <!-- <span v-if="httpWait">Loading scoreboard data. Please wait</span> -->
          <img v-if="httpWait" class="loading-gif" src="../assets/loading.gif">
          <span v-else>There is currently no scoreboard data</span>
          
        </div>
    </div>
</template>

<script>

import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import BarGraph from './BarGraph.vue';
import PieChart from './PieChart.vue';
import TeamDataTable from './TeamDataTable.vue';
import Chart from 'chart.js/auto';
import StockTicker from './StockTicker.vue';
import FinancialRatiosTable from './FinancialRatiosTable.vue';
import { DateTime } from 'luxon';
import Countdown from './Countdown';
import Dict from "./DictionaryGlossary.vue";
import StockMarket from "./StockMarket.vue";
import FeedbackForm from "./FeedbackForm.vue";

export default {
    name: 'Scoreboard',
    components: {
        Dict,
        StockMarket,
        'bar-graph': BarGraph,
        'pie-chart': PieChart,
        'team-data-table': TeamDataTable,
        'stock-ticker': StockTicker,
        'financial-ratios-table': FinancialRatiosTable,
        Countdown,
        FeedbackForm, 
    },
    data() {
        return {
            alertMessage: null,
            scoreboardData: null,
            serverError: null,
            selectedRoundNum: null,
            previousRoundNum: null,
            nextRoundNum: null,
            currentSalesReport: null,
            roundByRoundOptions: {
                title: 'Mouseover for More Details',
                legend: 'none',
                height: '200',
                backgroundColor: 'transparent',
                hAxis: {
                    format: 'currency',
                    title: 'Net Income',
                    minValue: 0,
                }
            },
            roundByRoundData: null,
            cumulativeOptions: {
                title: 'Mouseover for More Details',
                legend: 'none',
                height: '200',
                backgroundColor: 'transparent',
                hAxis: {
                    format: 'currency',
                    title: 'Net Income',
                    minValue: 0,
                },
                isStacked: true
            },
            cumulativeData: null,
            productData: null,
            teamId: null,
            semesterId: null,
            demandCurveChartData: [],
            canvasDrawn: false,
            httpWait: false,
            roundConfigId: null
        }
    },
    computed: {
        ...mapGetters([
            'getScoreboardData',
            'getCurrentCourse',
            'getNextDeadline',
            // 'feedbackClosed'
        ]),
        currentDeadline() {
            return this.getNextDeadline;
        },
        shouldShowCountdown() {
            const nowSeconds = DateTime.local().toSeconds();
            const nextDeadlineSeconds = this.getNextDeadline;
            if (isNaN(nextDeadlineSeconds)) {
                return false;
            }
            // show countdown if time is less than 1 hour
            return nextDeadlineSeconds - nowSeconds <= 3599;
        }
    },
    props: {
        activeRoundId: {
            type: String
        }
    },
    watch: {
        async selectedRoundNum(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                if (this.productData) {
                    this.canvasDrawn = false;
                    for (let i = 0; i < this.productData.length; i++) {
                        const canvas = document.getElementById(`demand-curve-${i}`);
                        const chart = Chart.getChart(canvas);
                        if (chart) {
                            chart.destroy();
                        }
                    }
                }
                await this.init();
            }
        }
    },
    methods: {
        ...mapActions([
            'fetchScoreboardData',
            'fetchSalesReport',
            // 'toggleFeedbackClosed'
        ]),
        formatCurrency(value) {
            if (typeof value !== "number") {
                return value;
            }
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            });
            return formatter.format(value);
        },
        calculateRoundNums() {
            this.previousRoundNum = this.selectedRoundNum - 1;
            this.nextRoundNum = this.selectedRoundNum + 1;
        },
        getTeamDataWithPlaceLabels(orderTeamsList) {
            return orderTeamsList.map((data, index) => {
                switch (index) {
                    case 0:
                        data[data.length - 1] = "First Place";
                        break;
                    case 1:
                        data[data.length - 1] += "Second Place";
                        break;
                    case 2:
                        data[data.length - 1] += "Third Place";
                        break;
                }
                return [...data];
            });
        },
        async init() {
            this.calculateRoundNums();
            if (this.getScoreboardData && this.getScoreboardData.rounds.length > 0) {
                this.initCurrentRoundData();
                this.roundConfigId = this.getScoreboardData.rounds[this.selectedRoundNum - 1].roundId;
                this.currentSalesReport = await this.fetchSalesReport({
                    roundConfigId: this.roundConfigId,
                    teamId: this.teamId,
                    omitCommit: true
                });
                this.initProductData();
            }
        },
        initCurrentRoundData() {
            const currentRoundIndex = this.selectedRoundNum - 1;
            const roundByRoundHeaders = [
                ['Team', 'Net Income', { type: 'string', role: 'tooltip' }, { role: 'style' }, { role: 'annotation' }]
            ];
            let roundByRoundData = this.getScoreboardData.rounds[currentRoundIndex].teamDatas.map((teamData) => {
                return [teamData.teamName, teamData.currentRoundIncome, `${teamData.teamName} ${this.formatCurrency(teamData.currentRoundIncome)}`, teamData.teamColorCode, ""];
            }).sort((a, b) => {
                return b[1] - a[1];
            });
            roundByRoundData = this.getTeamDataWithPlaceLabels(roundByRoundData);
            this.roundByRoundData = roundByRoundHeaders.concat(roundByRoundData);

            const cumulativeHeader = [
                ['Team', 'Total Net Income', { type: 'string', role: 'tooltip' }, { role: 'style' }, 'Round Net Income', { type: 'string', role: 'tooltip' }, { role: 'style' }, { role: 'annotation' }]
            ];
            let cumulativeData = this.getScoreboardData.rounds[currentRoundIndex].teamDatas.map((teamData) => {
                const cumulativeTotal = teamData.previousRoundsTotalNetIncome + teamData.currentRoundIncome;
                return [teamData.teamName, teamData.previousRoundsTotalNetIncome, `${teamData.teamName} Previous Total ${this.formatCurrency(teamData.previousRoundsTotalNetIncome)}`, "default", teamData.currentRoundIncome, `${teamData.teamName} Current Round Total ${this.formatCurrency(teamData.currentRoundIncome)}`, teamData.teamColorCode, "", cumulativeTotal];
                // if ((teamData.previousRoundsTotalNetIncome > 0 && teamData.currentRoundIncome < 0) || (teamData.previousRoundsTotalNetIncome < 0 && teamData.currentRoundIncome > 0)) {
                // return [teamData.teamName, null, `${teamData.teamName} Cumulative Total ${this.formatCurrency(cumulativeTotal)}`, teamData.teamColorCode, cumulativeTotal, "", teamData.teamColorCode, "", cumulativeTotal];
                // } else {
                // }
            }).sort((a, b) => {
                if (b[b.length - 1] === a[a.length - 1]) {
                    return b[4] - a[4];
                }
                return b[b.length - 1] - a[a.length - 1];
            }).map((data) => {
                data.pop();
                return [...data];
            });
            cumulativeData = this.getTeamDataWithPlaceLabels(cumulativeData);
            this.cumulativeData = cumulativeHeader.concat(cumulativeData);
            const roundByRoundNumTeams = this.roundByRoundData.length - 1;
            this.roundByRoundOptions.height = roundByRoundNumTeams * 100;
            // this.roundByRoundOptions.backgroundColor = (localStorage.getItem("isDarkMode") === "true") ? "#577479" : "#ffffff";
            // this.cumulativeOptions.backgroundColor = (localStorage.getItem("isDarkMode") === "true") ? "#577479" : "#ffffff";
            const cumulativeNumTeams = this.cumulativeData.length - 1;
            this.cumulativeOptions.height = cumulativeNumTeams * 100;
        },
        initDemandCurveGraphs() {
            Vue.nextTick(() => {
                if (!this.canvasDrawn) {
                    for (let i = 0; i < this.productData.length; i++) {
                        const ctx = document.getElementById(`demand-curve-${i}`);
                        Chart.register({
                            id: 'adjustLedgendColor',
                            beforeDraw: function (chart) {
                                chart.config.data.datasets.forEach((dataset, i) => {
                                    if (dataset.legendBackgroundColor) {
                                        chart.legend.legendItems[i].fillStyle = dataset.legendBackgroundColor;
                                        chart.legend.legendItems[i].strokeStyle = dataset.legendBorderColor ? dataset.legendBorderColor : dataset.legendBackgroundColor;
                                    }
                                });
                            }
                        });
                        new Chart(ctx, this.demandCurveChartData[i]);
                    }
                    this.canvasDrawn = true;
                }
            });
        },
        initProductData() {
            if (this.currentSalesReport) {
                const salesReport = this.currentSalesReport.reduce((result, report) => {
                    const index = result.findIndex((entry) => {
                        entry.team.id === report.team.id && entry.gameProduct.id === report.gameProduct.id && entry.perceivedPrice === report.perceivedPrice;
                    });
                    if (index !== -1) {
                        result[index].unitsSold += report.unitsSold;
                        result[index].unitsOffered += report.unitsOffered;
                        if (report.isAdvertised) {
                            result[index].unitsAdvertised += report.unitsOffered;
                        }
                    } else {
                        let unitsAdvertised = 0;
                        if (report.isAdvertised) {
                            unitsAdvertised += report.unitsOffered;
                        }
                        result.push({
                            ...report,
                            unitsAdvertised
                        });
                    }
                    return result;
                }, []);
                const combinedSalesReport = this.currentSalesReport.reduce((result, report) => {
                    const index = result.findIndex((entry) => entry.team.id === report.team.id && entry.gameProduct.id === report.gameProduct.id);
                    if (index !== -1) {
                        result[index].unitsSold += report.unitsSold;
                        result[index].unitsOffered += report.unitsOffered;
                        if (report.isAdvertised) {
                            result[index].unitsAdvertised += report.unitsOffered;
                        }
                    } else {
                        let unitsAdvertised = 0;
                        if (report.isAdvertised) {
                            unitsAdvertised += report.unitsOffered;
                        }
                        result.push({
                            ...report,
                            unitsAdvertised
                        });
                    }
                    return result;
                }, []);

                let products = {};
                this.productData = combinedSalesReport.reduce((result, report) => {
                    const data = {
                        teamName: report.team.name,
                        offerPrice: report.offerPrice,
                        unitsOffered: report.unitsOffered,
                        unitsAdvertised: report.unitsAdvertised,
                        unitsSold: report.unitsSold,
                        sales: report.offerPrice * report.unitsSold,
                        teamColorCode: report.team.colorCode
                    }
                    let chartOptionsDollarSales = {
                        legend: 'labeled',
                        pieSliceText: "none",
                        slices: {},
                        backgroundColor: 'transparent'
                    };
                    let chartOptionsUnitSales = {
                        legend: 'labeled',
                        pieSliceText: "none",
                        slices: {},
                        backgroundColor: 'transparent'
                    };
                    const chartDataDollarSales = [
                        ['Team Name', 'Total Dollars Sold', { type: 'string', role: 'tooltip' }]
                    ]
                    const chartDataUnitSales = [
                        ['Team Name', 'Total Units Sold', { type: 'string', role: 'tooltip' }]
                    ]
                    const courseRounds = this.getCurrentCourse.rounds;
                    const currentRoundIndex = this.selectedRoundNum - 1;
                    const activeRoundConfig = courseRounds[currentRoundIndex];
                    const gameProductIndex = activeRoundConfig.products.findIndex((product) => product.gameProductId === report.gameProduct.id);
                    const productTeamChartData = activeRoundConfig.products[gameProductIndex];

                    if (!products[report.gameProduct.id]) {
                        result.push({
                            id: report.gameProduct.id,
                            productName: report.gameProduct.name,
                            teamData: [data],
                            chartDataDollarSales,
                            chartOptionsDollarSales,
                            chartDataUnitSales,
                            chartOptionsUnitSales,
                            productTeamChartData
                        });
                    } else {
                        const index = result.findIndex((product) => product.id === report.gameProduct.id);
                        result[index].teamData.push(data);
                    }
                    products[report.gameProduct.id] = report;
                    return result;
                }, []);

                this.productData = this.productData.map((product) => {
                    const rawTeamData = salesReport.reduce((result, report) => {
                        if (report.gameProduct.id === product.id) {
                            result.push({
                                roundIndex: report.roundIndex,
                                teamName: report.team.name,
                                teamId: report.team.id,
                                offerPrice: report.offerPrice,
                                perceivedPrice: report.perceivedPrice,
                                unitsOffered: report.unitsOffered,
                                unitsAdvertised: report.unitsAdvertised,
                                unitsSold: report.unitsSold,
                                sales: report.offerPrice * report.unitsSold,
                                teamColorCode: report.team.colorCode
                            });
                        }
                        return result;
                    }, []);

                    const sortedTeamData = product.teamData
                        .sort((a, b) => b.sales - a.sales)
                        .map((report, index) => {
                            return {
                                ...report,
                                rank: index + 1
                            };
                        });
                    const teamDataWithTotals = [...sortedTeamData];
                    const totalUnitsSold = sortedTeamData.reduce((result, data) => {
                        return result += data.unitsSold;
                    }, 0);
                    const totalSales = sortedTeamData.reduce((result, data) => {
                        return result += data.sales;
                    }, 0);
                    teamDataWithTotals.push({
                        teamName: "Total",
                        unitsSold: totalUnitsSold,
                        sales: totalSales
                    });
                    return {
                        ...product,
                        rawTeamData,
                        teamData: sortedTeamData,
                        teamDataWithTotals
                    }
                });
                this.productData = this.productData.sort((a, b) => {
                    return a.productName.localeCompare(b.productName);
                });


                let cumulativeOtherDollarSales = {};
                let cumulativeOtherUnitSales = {};
                for (let product of this.productData) {
                    const numTeamsWithSales = product.teamData.length;
                    for (let report of product.teamData) {
                        const dollarSalesData = [
                            report.teamName,
                            report.sales,
                            `${report.teamName} ${this.formatCurrency(report.sales)}`
                        ];
                        const unitSalesData = [
                            report.teamName,
                            report.unitsSold,
                            `${report.teamName} ${report.unitsSold} Units`
                        ]
                        const slicesLengthDollarSales = Object.keys(product.chartOptionsDollarSales.slices).length;
                        const slicesLengthUnitSales = Object.keys(product.chartOptionsUnitSales.slices).length;
                        if (product.chartDataDollarSales.length < 6) {
                            product.chartDataDollarSales.push(dollarSalesData);
                            product.chartOptionsDollarSales.slices[slicesLengthDollarSales] = {
                                color: report.teamColorCode
                            }
                        } else {
                            if (!cumulativeOtherDollarSales[product.id]) {
                                cumulativeOtherDollarSales[product.id] = report.sales;
                            } else {
                                cumulativeOtherDollarSales[product.id] += report.sales;
                            }
                            // at the end of the loop, push the other slice
                            if (report.rank === numTeamsWithSales) {
                                product.chartDataDollarSales.push([
                                    "Other",
                                    cumulativeOtherDollarSales[product.id],
                                    `Other ${this.formatCurrency(cumulativeOtherDollarSales[product.id])}`
                                ]);
                                product.chartOptionsDollarSales.slices[slicesLengthDollarSales] = {
                                    color: '#f5f2f2'
                                }
                            }
                        }
                        if (product.chartDataUnitSales.length < 6) {
                            product.chartDataUnitSales.push(unitSalesData);
                            product.chartOptionsUnitSales.slices[slicesLengthUnitSales] = {
                                color: report.teamColorCode
                            }
                        } else {
                            if (!cumulativeOtherUnitSales[product.id]) {
                                cumulativeOtherUnitSales[product.id] = report.unitsSold;
                            } else {
                                cumulativeOtherUnitSales[product.id] += report.unitsSold;
                            }
                            // at the end of the loop, push the other slice
                            if (report.rank === numTeamsWithSales) {
                                product.chartDataUnitSales.push([
                                    "Other",
                                    cumulativeOtherUnitSales[product.id],
                                    `Other ${cumulativeOtherUnitSales[product.id]}`
                                ]);
                                product.chartOptionsUnitSales.slices[slicesLengthUnitSales] = {
                                    color: '#f5f2f2'
                                }
                            }
                        }
                    }
                }
                this.getDemandCurveData();
                this.initDemandCurveGraphs();
            }
        },
        getDemandCurveData() {
            for (let i = 0; i < this.productData.length; i++) {
                let maxValX = 0;
                let maxValY = 0;
                const xAxisCross = this.productData[i].productTeamChartData.demandIntercept * -1 / this.productData[i].productTeamChartData.demandSlope;
                maxValX = Math.max(maxValX, xAxisCross + 1);
                maxValY = Math.max(maxValY, this.productData[i].productTeamChartData.demandIntercept);

                const barSortedTeamData = [...this.productData[i].rawTeamData].sort((a, b) => a.offerPrice - b.offerPrice);
                const teamSalesDataUnmerged = barSortedTeamData.map((rawTeamData, index) => {
                    const teamUnitsOffered = rawTeamData.unitsOffered / 1000;
                    const previousNumOffered = barSortedTeamData
                        .slice(0, index)
                        .reduce((sum, teamData) => sum + teamData.unitsOffered / 1000, 0);
                    return {
                        sales: [previousNumOffered, previousNumOffered + teamUnitsOffered],
                        price: rawTeamData.perceivedPrice,
                        team: rawTeamData,
                        teamName: rawTeamData.teamName,
                        unitsOffered: teamUnitsOffered,
                        inventorySourceInfo: [{
                            rawTeamData,
                        }]
                    };
                });

                const teamSalesData = [];

                for (const salesData of teamSalesDataUnmerged) {
                    // Top of the stack (pop)
                    const top = teamSalesData[teamSalesData.length - 1];

                    if (!top || top.team.teamId !== salesData.team.teamId) {
                        teamSalesData.push(salesData);
                        continue;
                    }

                    // data integrity checking
                    if (top.price !== salesData.price) {
                        console.error("Team data price mismatch. Should not happen");
                        console.log(top, salesData, top.price, salesData.price);
                        console.trace();
                        teamSalesData.push(salesData);
                        continue;
                    }
                    if (top.sales[1] !== salesData.sales[0]) {
                        console.error("Team data sales mismatch. Should not happen");
                        console.trace();
                        teamSalesData.push(salesData);
                        continue;
                    }

                    // Should merge
                    top.inventorySourceInfo.push(...salesData.inventorySourceInfo);
                    // Add the units offered
                    top.unitsOffered += salesData.unitsOffered;
                    // Extend the sales range
                    top.sales[1] = salesData.sales[1];
                }

                const teamColors = teamSalesData.map(sales => sales.team.teamColorCode);

                const demandCurveData = [...Array(Math.ceil(xAxisCross)).keys()].reduce((_data, roundIndex) => {
                    if (roundIndex === 0) {
                        return _data;
                    }
                    const roundYValue = (this.productData[i].productTeamChartData.demandSlope * roundIndex) + this.productData[i].productTeamChartData.demandIntercept;
                    _data.push({ y: roundYValue, x: roundIndex });
                    if (roundIndex === Math.ceil(xAxisCross) - 1 && roundIndex !== xAxisCross) {
                        // our index has moved past the x axis cross, so the x axis cross is a fractional number...add the x axis cross as last data point
                        _data.push({ y: 0, x: xAxisCross });
                    }
                    return _data;
                }, [
                    { y: this.productData[i].productTeamChartData.demandIntercept, x: 0 }
                ]);

                // TODO convert theme color hex to RGB here for demand curve line color
                this.demandCurveChartData[i] = {
                    type: "line",
                    data: {
                        datasets: [
                            {
                                label: "Team Sales Bars",
                                type: "bar",
                                legendBackgroundColor: "rgba(100, 100, 100, 0.5)",
                                legendBorderColor: "rgb(100, 100, 100)",
                                backgroundColor: teamColors,
                                borderColor: teamColors,
                                data: teamSalesData,
                                barThickness: 12,
                                maxBarThickness: 12,
                                borderWidth: 1,
                                parsing: {
                                    xAxisKey: 'sales',
                                    yAxisKey: 'price'
                                }
                            },
                            {
                                label: "Demand Curve Line",
                                type: "line",
                                backgroundColor: "rgba(67, 176, 249, 0.5)",
                                borderColor: "rgb(67, 176, 249)",
                                fill: false,
                                tension: 0,
                                data: demandCurveData
                            }
                        ]
                    },
                    options: {
                        plugins: {
                            legend: {
                                labels: {
                                    // color: 'black'
                                }
                            },
                            title: {
                                display: true,
                                text: 'Demand Curve - Mouseover for More Details',
                                // color: 'black'
                            },
                            tooltip: {
                                callbacks: {
                                    afterLabel: (context) => {
                                        if (context.dataset.label === "Demand Curve Line") {
                                            const price = parseFloat(context.label);
                                            const formattedPrice = this.$options.filters.toCurrency(price);
                                            return `Demand Price: ${formattedPrice}`
                                        } else {
                                            let formatted = `Perceived Price: ${this.$options.filters.toCurrency(parseFloat(context.raw.price))}`;

                                            if (context.raw.inventorySourceInfo.length > 1) {
                                                let plus = " "
                                                for (const info of context.raw.inventorySourceInfo) {
                                                    formatted += `\n ${plus} ${info.rawTeamData.unitsOffered} units`;
                                                    plus = "+"
                                                }

                                                formatted += `\n = ${context.raw.unitsOffered}K units`;
                                            }

                                            return formatted;
                                        }
                                    },
                                    title: (context) => {
                                        if (context[0].dataset.label === "Demand Curve") {
                                            return '';
                                        } else {
                                            return context[0].raw.teamName;
                                        }
                                    },
                                    label: (context) => {
                                        let amountValue;
                                        let amountValueLabel;
                                        if (context.dataset.label === "Demand Curve Line") {
                                            amountValue = context.formattedValue;
                                            amountValueLabel = "Sold";
                                        } else {
                                            amountValue = context.raw.unitsOffered;
                                            amountValueLabel = "Offered"
                                        }
                                        amountValue = Math.round(amountValue * 100) / 100;
                                        const amountString = amountValue.toFixed(2);
                                        return `Units ${amountValueLabel}: ${amountString.substr(amountString.length - 1, 1) === '0' ? amountString.substr(0, amountString.length - 1) : amountString}K`;
                                    }
                                }
                            }
                        },
                        indexAxis: "y",
                        scales: {
                            x: {
                                max: maxValX,
                                min: 0,
                                ticks: {
                                    stepSize: 1,
                                    // color: 'black'
                                },
                                type: "linear",
                                title: {
                                    display: true,
                                    text: 'Units Sold (thousands)'
                                }
                            },
                            y: {
                                position: "right",
                                max: maxValY,
                                min: 0,
                                ticks: {
                                    stepSize: 10,
                                    // color: 'black'
                                },
                                type: "linear",
                                title: {
                                    display: true,
                                    text: 'Retail Price',
                                    // color: 'black'
                                },
                            }
                        }
                    }
                }
            }
        },
        selectRound(roundNum) {
            this.selectedRoundNum = roundNum;
        }
    },
    async created() {
        this.semesterId = this.$route.params.semesterId;
        this.teamId = this.$route.params.teamId;
        try {
            this.httpWait = true;
            await this.fetchScoreboardData({
                semesterId: this.semesterId
            });
        } catch (err) {
            this.httpWait = false;
        }

        this.httpWait = false;

        if (this.activeRoundId && this.getScoreboardData) {
            this.selectedRoundNum = this.getScoreboardData.rounds.length;
            const index = this.getScoreboardData.rounds.findIndex((round) => round.roundId === this.activeRoundId);
            if (index >= 0) {
                this.selectedRoundNum = index + 1;
            }
        }
        await this.init();
    }
}
</script>

<style scoped>

h1 {
    color: var(--text-primary);
}

h2 {
    color: var(--text-primary);
}

h3 {
    color: var(--text-primary);
}

span {
    color: var(--text-primary);
}

.title-and-feedback-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 40px;
}
.loading-gif {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.scoreboard-container {
    padding-bottom: 64px;
}

.scoreboard-element {
    background-color: var(--scoreboard-item-bg);
    border-radius: 4px; 
    border: 2px solid var(--text-primary);
    box-shadow: 5px 5px 2px var(--box-shadow-color)
}
.scoreboard-element-product {
    background-color: var(--scoreboard-item-bg);
    border-radius: 4px; 
    border: 2px solid var(--text-primary);
    margin: auto;
    box-shadow: 5px 5px 2px var(--box-shadow-color)
}

.round-nav-container {
    padding-top: 40px;
}

.round-label {
    font-size: 1.17em;
    font-weight: bold;
    color: var(--text-primary);
}

.graph-container {
    padding: 20px;
}

.graph-container-centered {
    margin: auto;
}

.product-graph-row {
    margin-left: 20px;
    margin-right: auto;
    margin-left: auto;
    max-width: 850px;
    margin-bottom: 10px;
}

.no-state-label {
    margin-top: 35px;
}

.scoreboard-ratios { 
    width: 100%;
    min-width: 90%;
    margin: auto;
    color: var(--text-primary);
    padding: 20px;
    border: 2px solid var(--text-primary);
    border-radius: 4px;
    background-color: var(--scoreboard-item-bg);
    box-shadow: 5px 5px 2px var(--box-shadow-color);
}

.sales-and-stock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
}
</style>
