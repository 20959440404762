<template>
    <div class="tooltip">
      <div>
        <slot></slot>
      </div>
      <span class="tooltiptext" ref="text">Loading...</span>
    </div>
</template>

<script>

const splitter = /[\s+\-_/]/g;

function cleanDict(dict) {
    const newDict = {};
    for (const key in dict) {
        newDict[key.toLowerCase().replace(splitter, '')] = dict[key];
    }
    return newDict;
}

const dict = cleanDict({
    "Round-by-Round Scoreboard": "Earnings for the current round",
    "Cumulative": "Cumulative earnings for the entire game",
    "PE": "Price to earnings ratio, used to gauge the value and expectations of a stock",
    "EPS": "Earnings Per Share",
    "Assets": "Eeverything a company owns (cash, property, intellectual property, etc)",
    "Liabilities": "The legal debts a company owes to third-party creditors",
    "Owners' Equity": "The proportion of total value of a company's assets that can be claimed by owners / shareholders",
    "Revenue": "Amount of USD brought in by business operations",
    "Expense": "A cost incurred during course of business",
    "Financing Activity": "Transactions related to borrowing / lending, such as issuing/repurchasing stock and short/long term loans and repayments",
    "Investing Activity": "The purchases and sales of physical assets and securities",
    "Operating Activity": "The functions directly related to providing goods / services to the market",
    "Debit": "A bookkeeping entry that recordeds an increase in assets/expenses or a decrease in liabilities/equity/revenue",
    "Credit": "A bookkeeping entry that records a decrease in assets or an increase in liabilities",
    "Permanent": "An endeavor either does not cease or lasts more than 6mo in a 12mo period",
    "Temporary": "An endeavor that is arranged for a limited period of time based on business needs",
    "Stock Market": "Compare your team's stock price to others",
    "Net Income": "The difference between a company's total revenue and total expenses",
    "D/E": "Debt to Equity ratio, used to gauge the financial health of a company",
    "ROE": "Return on Equity, used to gauge the profitability of a company",
    "ROA": "Return on Assets, used to gauge the profitability of a company",
    "Bond": "Bonds are a type of security you can sell as a way of raising money from investors (borrowing money)",
    "IPO": "Initial Public Offering, the first time a company sells its stock to the public"
})

function dictResolver(str) {
    str = str.toLowerCase();

    // Attempt to use str itself to resolve
    if (dict[str]) {
        return dict[str];
    }

    // Attempt to split into segments and resolve
    const words = str.split(splitter);

    for (let i = words.length; i > 0; i--) {
        const subStr = words.slice(0, i).join('');
        if (dict[subStr]) {
            return dict[subStr];
        }
    }

    return "ERROR No definition found";
}

export default {
  mounted() {
    this.$refs.text.innerText = dictResolver(this.$slots.default[0].text);
  }
}
</script>

<style scoped>
span {
  color: var(--text-primary);
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 10em;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 0;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1.0;
  transition: opacity .55s ease-in-out;
}

.tooltip:not(:hover) .tooltiptext {
  opacity: 0;
  transition: .55s;
}
</style>
