import gql from 'graphql-tag';
import { apolloClient } from '../../main';
import { CourseVuexState } from "@/types/types";
import { Course } from "temporary-api-types";

const state: CourseVuexState = {
  courseList: []
};

const getters = {
  getCourseList: (state: CourseVuexState): Course[] => state.courseList
};

const actions = {
  async fetchCourseList({ commit }: any) {
    const response = await apolloClient.query({
      query: gql`
      query {
        courses {
          id,
          name,
          description,
          minTeams,
          maxTeams,
          minStudentsPerTeam,
          maxStudentsPerTeam,
          gameProducts { id, name }
        }
      }
      `
    });

    commit('setCourseList', response.data.courses);
  },
  async importCourseTemplate({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation ImportCourseTemplate($courseTemplateJSON: CourseTemplateInput!, $professorId: ID, $name: String!, $description: String!) {
            importCourseTemplate(courseTemplateJSON: $courseTemplateJSON, professorId: $professorId, name: $name, description: $description) {
              id
            }
          }
        `,
        variables: {
          courseTemplateJSON: payload.courseTemplateJSON,
          professorId: payload.professorId,
          name: payload.name,
          description: payload.description
        }
      });

      return response.data.importCourseTemplate;
    } catch(err) {
      throw(err);
    }
  },
  async updateCourseTemplate({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation UpdateCourseTemplate($courseTemplateId: ID!, $courseTemplateJSON: CourseTemplateInput, $professorId: ID, $name: String, $description: String, $isLive: Boolean) {
            updateCourseTemplate(courseTemplateId: $courseTemplateId, courseTemplateJSON: $courseTemplateJSON, professorId: $professorId, name: $name, description: $description, isLive: $isLive) {
              id,
              name,
              description,
              minTeams,
              maxTeams,
              minStudentsPerTeam,
              maxStudentsPerTeam,
              gameProducts,
              rounds{ 
                salesReturnsPercentLowerBound,
                salesReturnsPercentUpperBound,
                buildings {
                  typesConfig { buildingType, purchaseCost, equipmentCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod }
                },
                equipments {
                  typesConfig { equipmentType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod }
                },
                storeFurnishings {
                  typesConfig { furnishingType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod }
                },
                lands {
                  purchaseCost,
                  buildingCapacity
                },
                debts {
                  borrowingRate,
                  borrowingTerm
                },
                products {
                  productionCost,
                  demandSlope,
                  demandIntercept
                },
                isStockTradingEnabled,
                bonds {
                  realInterestRateFactor,
                  bondOptions {
                    nominalAmount,
                    nominalInterestRate,
                    paymentDueQuarterEnd, 
                    numPaymentsPerYear, 
                    term
                  }
                } 
              },
              defaultGameConfig {
                preloadAssets {
                  lands {
                    name,
                    totalCapacity,
                    originalPurchasePrice
                  },
                  buildings {
                    name,
                    land,
                    totalCapacity,
                    originalPurchasePrice,
                    depreciationMethod,
                    usefulLife
                  },
                  equipments {
                    name,
                    building,
                    totalCapacity,
                    originalPurchasePrice,
                    depreciationMethod,
                    usefulLife
                  },
                  storeFurnishings {
                    name,
                    building,
                    totalCapacity,
                    originalPurchasePrice,
                    depreciationMethod,
                    usefulLife
                  },
                  debts {
                    name,
                    originalRate,
                    originalTerm,
                    originalPrincipal
                  },
                  products {
                    gameProductName,
                    inventoryAmount,
                    costPerUnit
                  }
                },
                roundFeatures {
                  advertising {
                    cost,
                    benefit
                  },
                  salesReturns,
                  incomeTax,
                  borrowingCaps {
                    debtRoundBorrowCap,
                    bondRoundBorrowCap,
                    totalPrincipalCap
                  },
                  cashFlowConfig {
                    roundSetting,
                    disabledFields
                  },
                  incomeStatementConfig {
                    roundSetting,
                    disabledFields
                  },
                  balanceSheetConfig {
                    roundSetting,
                    disabledFields
                  },
                  journalEntryTAccountConfig,
                  requirePositiveCashBalanceBeforeElecting,
                  stockPriceConfig {
                    netIncomeFactor,
                    totalAssetsFactor,
                    changeInIncomeFactor,
                    niRankFactor
                  },
                  journalEntrySubmissionConfig {
                    enabledJournalEntrySubmissions {
                      journalEntryType,
                      accountOptions
                    }
                  }
                },
                junkLoans {
                  rate,
                  term
                },
                stockConfig {
                  teamStartingStockPrice,
                  initialOfferingNumShares,
                  totalSellableSharesPerTeam
                }
              },
              isLive,
              isSystemTemplate,
              professor { id }
            }
          }
        `,
        variables: {
          courseTemplateId: payload.courseTemplateId,
          courseTemplateJSON: payload.courseTemplateJSON,
          professorId: payload.professorId,
          name: payload.name,
          description: payload.description,
          isLive: payload.isLive
        }
      });

      return response.data.updateCourseTemplate;
    } catch(err) {
      throw(err);
    }
  },
  async fetchCourseTemplates({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query CourseTemplates($professorId: ID) {
            courseTemplates(professorId: $professorId) {
              id,
              name,
              description,
              minTeams,
              maxTeams,
              minStudentsPerTeam,
              maxStudentsPerTeam,
              gameProducts,
              rounds{ 
                salesReturnsPercentLowerBound,
                salesReturnsPercentUpperBound,
                buildings {
                  typesConfig { buildingType, purchaseCost, equipmentCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod }
                },
                equipments {
                  typesConfig { equipmentType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod }
                },
                storeFurnishings {
                  typesConfig { furnishingType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod }
                },
                lands {
                  purchaseCost,
                  buildingCapacity
                },
                debts {
                  borrowingRate,
                  borrowingTerm
                },
                products {
                  productionCost,
                  demandSlope,
                  demandIntercept
                },
                isStockTradingEnabled,
                bonds {
                  realInterestRateFactor,
                  bondOptions {
                    nominalAmount,
                    nominalInterestRate,
                    paymentDueQuarterEnd, 
                    numPaymentsPerYear, 
                    term
                  }
                } 
              },
              defaultGameConfig {
                preloadAssets {
                  lands {
                    name,
                    totalCapacity,
                    originalPurchasePrice
                  },
                  buildings {
                    name,
                    land,
                    totalCapacity,
                    originalPurchasePrice,
                    depreciationMethod,
                    usefulLife
                  },
                  equipments {
                    name,
                    building,
                    totalCapacity,
                    originalPurchasePrice,
                    depreciationMethod,
                    usefulLife
                  },
                  storeFurnishings {
                    name,
                    building,
                    totalCapacity,
                    originalPurchasePrice,
                    depreciationMethod,
                    usefulLife
                  },
                  debts {
                    name,
                    originalRate,
                    originalTerm,
                    originalPrincipal
                  },
                  products {
                    gameProductName,
                    inventoryAmount,
                    costPerUnit
                  }
                },
                roundFeatures {
                  advertising {
                    cost,
                    benefit
                  },
                  salesReturns,
                  incomeTax,
                  borrowingCaps {
                    debtRoundBorrowCap,
                    bondRoundBorrowCap,
                    totalPrincipalCap
                  },
                  cashFlowConfig {
                    roundSetting,
                    disabledFields
                  },
                  incomeStatementConfig {
                    roundSetting,
                    disabledFields
                  },
                  balanceSheetConfig {
                    roundSetting,
                    disabledFields
                  },
                  journalEntryTAccountConfig,
                  requirePositiveCashBalanceBeforeElecting,
                  stockPriceConfig {
                    netIncomeFactor,
                    totalAssetsFactor,
                    changeInIncomeFactor,
                    niRankFactor
                  },
                  accountsReceivable {
                    outstandingPercent,
                    badDebtPercent
                  },
                  journalEntrySubmissionConfig {
                    enabledJournalEntrySubmissions {
                      journalEntryType,
                      accountOptions
                    }
                  }
                },
                junkLoans {
                  rate,
                  term
                },
                stockConfig {
                  teamStartingStockPrice,
                  initialOfferingNumShares,
                  totalSellableSharesPerTeam
                }
              },
              isLive,
              isSystemTemplate,
              professor { id }
            }
          }
        `,
        variables: {
          professorId: payload.professorId
        }
      });

      return response.data.courseTemplates
    } catch(err) {
      throw(err);
    }
  },
  async deleteCourseTemplate({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.mutate({
        fetchPolicy: 'no-cache',
        mutation: gql`
          mutation DeleteCourseTemplate($courseTemplateId: ID!, $professorId: ID) {
            deleteCourseTemplate(courseTemplateId: $courseTemplateId, professorId: $professorId) {
              id,
              name,
              description,
              minTeams,
              maxTeams,
              minStudentsPerTeam,
              maxStudentsPerTeam,
              gameProducts,
              rounds{ 
                salesReturnsPercentLowerBound,
                salesReturnsPercentUpperBound,
                buildings {
                  typesConfig { buildingType, purchaseCost, equipmentCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod }
                },
                equipments {
                  typesConfig { equipmentType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod }
                },
                storeFurnishings {
                  typesConfig { furnishingType, purchaseCost, productionCapacity, usefulLife, depreciationMethods, defaultDepreciationMethod }
                },
                lands {
                  purchaseCost,
                  buildingCapacity
                },
                debts {
                  borrowingRate,
                  borrowingTerm
                },
                products {
                  productionCost,
                  demandSlope,
                  demandIntercept
                },
                isStockTradingEnabled,
                bonds {
                  realInterestRateFactor,
                  bondOptions {
                    nominalAmount,
                    nominalInterestRate,
                    paymentDueQuarterEnd, 
                    numPaymentsPerYear, 
                    term
                  }
                } 
              },
              defaultGameConfig {
                preloadAssets {
                  lands {
                    name,
                    totalCapacity,
                    originalPurchasePrice
                  },
                  buildings {
                    name,
                    land,
                    totalCapacity,
                    originalPurchasePrice,
                    depreciationMethod,
                    usefulLife
                  },
                  equipments {
                    name,
                    building,
                    totalCapacity,
                    originalPurchasePrice,
                    depreciationMethod,
                    usefulLife
                  },
                  storeFurnishings {
                    name,
                    building,
                    totalCapacity,
                    originalPurchasePrice,
                    depreciationMethod,
                    usefulLife
                  },
                  debts {
                    name,
                    originalRate,
                    originalTerm,
                    originalPrincipal
                  },
                  products {
                    gameProductName,
                    inventoryAmount,
                    costPerUnit
                  }
                },
                roundFeatures {
                  advertising {
                    cost,
                    benefit
                  },
                  salesReturns,
                  incomeTax,
                  borrowingCaps {
                    debtRoundBorrowCap,
                    bondRoundBorrowCap,
                    totalPrincipalCap
                  },
                  cashFlowConfig {
                    roundSetting,
                    disabledFields
                  },
                  incomeStatementConfig {
                    roundSetting,
                    disabledFields
                  },
                  balanceSheetConfig {
                    roundSetting,
                    disabledFields
                  },
                  journalEntryTAccountConfig,
                  requirePositiveCashBalanceBeforeElecting,
                  stockPriceConfig {
                    netIncomeFactor,
                    totalAssetsFactor,
                    changeInIncomeFactor,
                    niRankFactor
                  },
                  accountsReceivable {
                    outstandingPercent,
                    badDebtPercent
                  },
                  journalEntrySubmissionConfig {
                    enabledJournalEntrySubmissions {
                      journalEntryType,
                      accountOptions
                    }
                  }
                },
                junkLoans {
                  rate,
                  term
                },
                stockConfig {
                  teamStartingStockPrice,
                  initialOfferingNumShares,
                  totalSellableSharesPerTeam
                }
              },
              isLive,
              isSystemTemplate,
              professor { id }
            }
          }
        `,
        variables: {
          courseTemplateId: payload.courseTemplateId,
          professorId: payload.professorId
        }
      });

      return response.data.deleteCourseTemplate;
    } catch(err) {
      throw(err);
    }
  },
  async fetchCourseTemplateAsInputString({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query CourseTemplateAsInputString($courseTemplateId: ID!) {
            courseTemplateAsInputString(courseTemplateId: $courseTemplateId)
          }
        `,
        variables: {
          courseTemplateId: payload.courseTemplateId
        }
      });

      return response.data.courseTemplateAsInputString;
    } catch(err) {
      throw(err);
    }
  },
  async fetchCourseTemplateFromCourse({ commit }: any, payload: any) {
    try {
      const response = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query CourseAsCourseTemplate($courseId: ID!) {
            courseAsCourseTemplate(courseId: $courseId)
          }
        `,
        variables: {
          courseId: payload.courseId
        }
      });

      return response.data.courseAsCourseTemplate;
    } catch(err) {
      throw(err);
    }
  }
};

const mutations = {
  setCourseList(state: CourseVuexState, courseList: Course[]) {
    state.courseList = courseList;
  },
  resetCourseState(state: CourseVuexState) {
    state.courseList = [];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
