import { render, staticRenderFns } from "./StockTicker.vue?vue&type=template&id=e8967e1e&scoped=true&"
import script from "./StockTicker.vue?vue&type=script&lang=js&"
export * from "./StockTicker.vue?vue&type=script&lang=js&"
import style0 from "./StockTicker.vue?vue&type=style&index=0&id=e8967e1e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8967e1e",
  null
  
)

export default component.exports